.call .call-header {
  display: flex;
  height: 64px;
  min-height: 64px;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  align-items: center;
}
.call .call-header .left {
  margin-left: 16px;
  display: flex;
  align-items: center;
}
.call .call-header .left .title-duration {
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  height: 32px;
}
.call .call-header .left .title-duration .user-title {
  height: 20px;
  font-size: 14px;
}
.call .call-header .left .title-duration .call-duration {
  height: 12px;
  font-size: 10px;
}
.call .call-header .right {
  margin-right: 16px;
  margin-left: auto;
}
.call .call-header button .share svg {
  color: #fff !important;
}
.call .call-header .user-avatar {
  font-size: 12px;
  width: 32px;
  height: 32px;
  border-radius: 4px;
}
.call .call-header .user-avatar img {
  width: 32px;
  height: 32px;
  border-radius: 4px;
}
