.change-temp-password__title {
  font-size: 24px;
  font-family: "Roboto Medium";
}
.change-temp-password__container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.change-temp-password__form {
  width: 90%;
  padding-top: 10px;
}
.change-temp-password__form .captcha-badge {
  text-align: center;
  margin-top: 12px;
  font-size: 12px;
  font-family: Roboto, sans-serif;
  opacity: 0.66;
}
.change-temp-password__button {
  margin-top: 12px;
}
.change-temp-password__button span {
  font-size: 16px;
  text-transform: uppercase;
}
