.media-lightbox {
  background-color: rgba(33, 33, 33, 0.95);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1300;
  left: 0;
  top: 0;
}
.media-lightbox .media .av-preview video {
  max-width: 65vw;
  height: auto;
  max-height: 70vh;
}
.media-lightbox .title {
  z-index: 1300;
  text-align: center;
  font-size: 13px;
  color: #fff;
  padding: 15px 36px 0;
}
.media-lightbox .close {
  position: absolute;
  top: 15px;
  right: 35px;
  font-size: 40px !important;
  cursor: pointer;
}
