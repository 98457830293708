.home-page-legacy {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 56px);
}
.home-page-legacy .title-messages {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.home-page-legacy .title-messages .welcome {
  padding: 50px 0 5px 0;
  color: #00aaed;
  font-size: 32px;
  font-family: "Source Sans Pro";
  line-height: 22px;
  font-weight: 100;
}
.home-page-legacy .title-messages .tagline {
  padding: 5px 0 50px 0;
  font-size: 24px;
  color: #8f9295;
  line-height: 22px;
}
.home-page-legacy .client-app-login {
  width: 85%;
  margin-bottom: 30px;
}
