.auth-tabs .dashboard-tabs > div:nth-child(2) > div {
  top: 0 !important;
}
.auth-tabs .tab-bar-content {
  overflow: auto;
  height: calc(100vh - 104px);
}
.auth-tabs .footer-action-buttons {
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: #fff;
  z-index: 2;
}
.auth-tabs .footer-action-buttons .cancel-action {
  color: #736f77 !important;
}
.auth-tabs input[type=number]::-webkit-inner-spin-button,
.auth-tabs input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
