.show-token-credentials-dialog .show-token-credentials-content {
  flex-direction: column;
  display: flex;
}
.show-token-credentials-dialog .show-token-credentials-content .show-token-credential-description {
  padding: 15px 0 10px 0;
  font-size: 16px;
  color: #898989;
}
.show-token-credentials-dialog .show-token-credentials-content .show-credential-config {
  height: 100%;
  width: 100%;
  min-height: 172px;
  background-color: #f8f8f8;
  overflow: hidden;
}
.show-token-credentials-dialog .show-token-credentials-content .show-credential-config .show-copy-content-button {
  position: absolute !important;
  right: 1rem !important;
}
.show-token-credentials-dialog .show-token-credentials-content .show-credential-config .show-copy-content-button div svg {
  color: #bbbec0 !important;
}
.show-token-credentials-dialog .show-token-credentials-content .show-credential-config .show-copy-content-button div svg:hover {
  background-color: #e5e4e5 !important;
  border-radius: 5px;
}
.show-token-credentials-dialog .show-token-credentials-content .show-credential-config .show-credential-config-text {
  outline: 0;
  border: none;
  cursor: text;
  background-color: #f8f8f8;
  resize: none;
  width: 100%;
  height: 100%;
  margin-left: 15px;
  min-height: 162px;
  font-size: 15px;
}
.show-token-credentials-dialog .token-credentials-dialog-title {
  display: flex;
  height: inherit;
  background-color: #da2d18 !important;
  color: #fff !important;
  align-items: center;
}
.show-token-credentials-dialog .token-credentials-dialog-title .dialog-icon {
  margin-right: 8px;
}
