.assignment-attachments .assignment-attachments-view {
  overflow-y: scroll;
  max-height: calc(100vh - 76px) !important;
  padding: 20px 20px 0 20px;
}
.assignment-attachments .assignment-attachments-view .table-heading {
  border-top: 0 !important;
  height: 28px;
  padding: 5px;
}
.assignment-attachments .assignment-attachments-view .table-heading .select-all-checkbox {
  height: 28px !important;
  display: none !important;
}
.assignment-attachments .assignment-attachments-view .table-heading .select-all-checkbox.checked {
  display: block !important;
}
.assignment-attachments .assignment-attachments-view .table-heading .sort-picker {
  top: -40px !important;
}
.assignment-attachments .assignment-attachments-view .table-heading:hover .select-all-checkbox {
  height: 28px !important;
  width: 24px !important;
  display: block !important;
}
.assignment-attachments .assignment-attachments-view .subheader {
  padding-left: 0 !important;
}
.assignment-attachments .assignment-attachments-view .assignment-attachments-title {
  font-family: "Roboto Bold";
  font-size: 20px;
  color: #212121;
  line-height: 21px;
  padding: 10px;
}
.assignment-attachments .assignment-attachments-view .assignment-attachments-title.capture-attachments-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.assignment-attachments .assignment-attachments-view .assignment-attachments-title.capture-attachments-title > svg {
  color: #212121 !important;
  margin-right: 10px;
}
.assignment-attachments .assignment-attachments-view .assignment-media-attachments .grid-list > div, .assignment-attachments .assignment-attachments-view .assignment-textinput-attachments .grid-list > div, .assignment-attachments .assignment-attachments-view .assignment-decision-attachments .grid-list > div {
  padding: 0 2px !important;
  margin: -1px 0;
}
.assignment-attachments .assignment-attachments-view .assignment-media-attachments, .assignment-attachments .assignment-attachments-view .assignment-media-attachments .assignment-media-attachments, .assignment-attachments .assignment-attachments-view .assignment-textinput-attachments, .assignment-attachments .assignment-attachments-view .assignment-textinput-attachments .assignment-media-attachments, .assignment-attachments .assignment-attachments-view .assignment-decision-attachments, .assignment-attachments .assignment-attachments-view .assignment-decision-attachments .assignment-media-attachments {
  margin-bottom: 20px;
}
.assignment-attachments .assignment-attachments-view .assignment-media-attachments .grid-list, .assignment-attachments .assignment-attachments-view .assignment-media-attachments .assignment-media-attachments .grid-list, .assignment-attachments .assignment-attachments-view .assignment-textinput-attachments .grid-list, .assignment-attachments .assignment-attachments-view .assignment-textinput-attachments .assignment-media-attachments .grid-list, .assignment-attachments .assignment-attachments-view .assignment-decision-attachments .grid-list, .assignment-attachments .assignment-attachments-view .assignment-decision-attachments .assignment-media-attachments .grid-list {
  padding: 0 50px;
}
.assignment-attachments .assignment-attachments-view .assignment-media-attachments .grid-list > div, .assignment-attachments .assignment-attachments-view .assignment-media-attachments .assignment-media-attachments .grid-list > div, .assignment-attachments .assignment-attachments-view .assignment-textinput-attachments .grid-list > div, .assignment-attachments .assignment-attachments-view .assignment-textinput-attachments .assignment-media-attachments .grid-list > div, .assignment-attachments .assignment-attachments-view .assignment-decision-attachments .grid-list > div, .assignment-attachments .assignment-attachments-view .assignment-decision-attachments .assignment-media-attachments .grid-list > div {
  height: initial !important;
}
.assignment-attachments .assignment-attachments-view .list-footer-stats {
  font-weight: 600;
  border-top: solid 1px #ededed;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff;
  margin-left: -20px;
  padding-left: 20px;
  z-index: 5;
}
.assignment-attachments .assignment-attachments-view .media-grid-view > .grid-list > :not(.subheader) {
  width: 150px !important;
  height: 150px !important;
  margin-bottom: 5px;
}
.assignment-attachments .assignment-attachments-view .media-grid-item .media-item-tooltip > div > span:first-child {
  font-size: 11px;
  font-weight: 600;
}
.assignment-attachments .assignment-attachments-view .media-grid-item .media-item-tooltip > div > span:last-child {
  font-family: "Roboto Light";
}
