.assign-application .assign-application-body {
  min-height: 120px;
}
.assign-application .assign-application-body .assign-application-container {
  outline: 0;
}
.assign-application .assign-application-body .assign-application-container .label {
  font-size: 14px;
  color: #212121;
}
.assign-application .assign-application-body .assign-application-container .people-picker {
  margin-top: 8%;
  width: 100% !important;
}
