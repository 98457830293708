@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/Roboto/Roboto-Regular.eot);
  src: url(./assets/fonts/Roboto/Roboto-Regular.woff2) format("woff2"), url(./assets/fonts/Roboto/Roboto-Regular.woff) format("woff"), url(./assets/fonts/Roboto/Roboto-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto Light";
  font-style: normal;
  font-weight: 100;
  src: url(./assets/fonts/Roboto-Light/Roboto-Light.eot);
  src: url(./assets/fonts/Roboto-Light/Roboto-Light.woff2) format("woff2"), url(./assets/fonts/Roboto-Light/Roboto-Light.woff) format("woff"), url(./assets/fonts/Roboto-Light/Roboto-Light.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto Italic";
  font-style: normal;
  font-weight: 100;
  src: url(./assets/fonts/Roboto-Italic/Roboto-Italic.eot);
  src: url(./assets/fonts/Roboto-Italic/Roboto-Italic.woff2) format("woff2"), url(./assets/fonts/Roboto-Italic/Roboto-Italic.woff) format("woff"), url(./assets/fonts/Roboto-Italic/Roboto-Italic.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto Bold";
  font-style: normal;
  font-weight: 100;
  src: url(./assets/fonts/Roboto-Bold/Roboto-Bold.eot);
  src: url(./assets/fonts/Roboto-Bold/Roboto-Bold.woff2) format("woff2"), url(./assets/fonts/Roboto-Bold/Roboto-Bold.woff) format("woff"), url(./assets/fonts/Roboto-Bold/Roboto-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 100;
  src: url(./assets/fonts/Roboto-Medium/Roboto-Medium.ttf);
}
@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/Source-Sans-Pro/SourceSansPro-Regular.eot);
  src: url(./assets/fonts/Source-Sans-Pro/SourceSansPro-Regular.woff2) format("woff2"), url(./assets/fonts/Source-Sans-Pro/SourceSansPro-Regular.woff) format("woff"), url(./assets/fonts/Source-Sans-Pro/SourceSansPro-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  src: url(./assets/fonts/Material-Icons/MaterialIcons-Regular.eot);
  src: url(./assets/fonts/Material-Icons/MaterialIcons-Regular.woff2) format("woff2"), url(./assets/fonts/Material-Icons/MaterialIcons-Regular.woff) format("woff"), url(./assets/fonts/Material-Icons/MaterialIcons-Regular.ttf) format("truetype");
}
@font-face {
  font-family: "skylight-icons";
  src: url(./assets/fonts/skylight-icons/skylight_web_icons.ttf) format("truetype"), url(./assets/fonts/skylight-icons/skylight_web_icons.woff) format("woff"), url(./assets/fonts/skylight-icons/skylight_web_icons.svg#skylight_web_icons) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto Light";
}
body .skylight-icons {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "skylight-icons" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body .material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}

.grecaptcha-badge {
  visibility: hidden;
}
