.ldap-options .ldap-section {
  margin-top: 48px;
  width: 80%;
}
.ldap-options h2 {
  font-size: 16px;
  margin-bottom: 0px;
}
.ldap-options .field-control {
  margin-left: 24px;
}
.ldap-options .field-text > div,
.ldap-options .field-select > div,
.ldap-options .field-number > div,
.ldap-options .field-password > div,
.ldap-options .field-custom-filters > div {
  width: 100% !important;
}
.ldap-options .field-text .outlined-field,
.ldap-options .field-select .outlined-field,
.ldap-options .field-number .outlined-field,
.ldap-options .field-password .outlined-field,
.ldap-options .field-custom-filters .outlined-field {
  width: calc(100% - 30px) !important;
}
.ldap-options .field-text label,
.ldap-options .field-select label,
.ldap-options .field-number label,
.ldap-options .field-password label,
.ldap-options .field-custom-filters label {
  color: rgba(33, 33, 33, 0.33) !important;
}
.ldap-options .field-text input:-webkit-autofill,
.ldap-options .field-password input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 40px white inset;
  box-shadow: 0 0 0 40px white inset;
}
.ldap-options .field-number input[type=number]::-webkit-inner-spin-button,
.ldap-options .field-number input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ldap-last-synced {
  font-size: 13px;
  color: rgba(33, 33, 33, 0.66);
  text-align: right;
  display: flex;
  justify-content: flex-end;
}
.ldap-last-synced > span {
  display: flex;
}
.ldap-last-synced .ldap-force-sync-error > span {
  display: flex;
  color: red;
}
.ldap-last-synced .ldap-force-sync-button {
  height: 16px !important;
  width: 16px !important;
  transform: scale(1.5);
}
.ldap-last-synced .ldap-force-sync {
  padding: 0 !important;
  width: 16px !important;
  height: 16px !important;
  margin-left: 8px !important;
}
.ldap-last-synced .ldap-force-sync div .ldap-force-sync-button {
  color: #736F77 !important;
}
.ldap-last-synced .ldap-force-sync div .ldap-force-sync-button:hover {
  color: #00AAED !important;
  background-color: #ECEBEC;
  border-radius: 4px;
}
