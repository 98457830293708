.call-drop-zone {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  top: 40px;
  right: 40px;
  bottom: 40px;
  left: 40px;
}
.call-drop-zone.hide {
  opacity: 0.1;
  display: none;
}
.call-drop-zone.show {
  z-index: 2;
  opacity: 1;
  transition: opacity 0.5s linear;
}
.call-drop-zone .overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.call-drop-zone .call-drop-zone-content {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  box-shadow: 0 0 5px 0 rgba(33, 33, 33, 0.5);
}
.call-drop-zone .call-drop-zone-content img {
  width: 100%;
}
.call-drop-zone .call-drop-zone-content .upload-header {
  height: 66px;
  background-color: #00aaed;
  color: #fff;
  display: flex;
  align-items: center;
  padding-left: 18px;
  font-size: 20px;
  color: #FFFFFF;
  line-height: 22px;
}
.call-drop-zone .call-drop-zone-content .call-drop-zone-body {
  position: relative;
  margin: 18px;
  background-color: #EDEDED;
  padding: 54px 24px;
  box-sizing: border-box;
}
.call-drop-zone .call-drop-zone-content .call-drop-zone-body.file-over {
  border: dashed 4px #00aaed;
  padding: 50px 20px;
  background-color: rgba(0, 170, 237, 0.3);
}
.call-drop-zone .call-drop-zone-content .upload-icon {
  margin-bottom: 8px;
}
.call-drop-zone .call-drop-zone-content .upload-icon svg {
  color: #736F77 !important;
  width: 34px !important;
  height: 34px !important;
}
.call-drop-zone .call-drop-zone-content .upload-title {
  font-family: "Roboto Bold";
  font-size: 32px;
  color: rgba(33, 33, 33, 0.66);
  margin-bottom: 12px;
}
.call-drop-zone .call-drop-zone-content .upload-description {
  font-family: "Roboto";
  font-size: 18px;
  color: rgba(33, 33, 33, 0.33);
  text-align: center;
}
.call-drop-zone .call-drop-zone-content .upload-title, .call-drop-zone .call-drop-zone-content .upload-description, .call-drop-zone .call-drop-zone-content .upload-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}
