.call .call-zoom {
  display: flex;
  flex-direction: column;
  z-index: 1;
}
.call .call-zoom .zoom-btn {
  background-color: #fff !important;
  width: 24px !important;
  height: 24px !important;
  cursor: pointer;
}
.call .call-zoom .zoom-btn svg {
  color: #000 !important;
}
.call .call-zoom .zoom-btn.disabled svg {
  color: #BBBEC0 !important;
}
