.reset-password-dialog .reset-password-content div:first-child {
  border-radius: 4px !important;
}
.reset-password-dialog .reset-password-content .dialog-title {
  border-bottom: 1px solid #ededed;
  padding: 15px 24px 5px;
  font-size: 20px;
}
.reset-password-dialog .reset-password-content .reset-password-body {
  padding: 0 24px 12px 24px !important;
  display: flex;
  flex-direction: column;
}
.reset-password-dialog .reset-password-content .reset-password-body .hidden-text-field {
  width: 100% !important;
}
.reset-password-dialog .reset-password-content .reset-password-actions {
  display: flex;
  justify-content: flex-end;
  padding: 10px 0px;
}
.reset-password-dialog .reset-password-content .captcha-badge-container {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
}
.reset-password-dialog .reset-password-content .captcha-badge-container a {
  color: rgba(0, 0, 0, 0.66);
}
.reset-password-dialog .reset-password-content .captcha-badge {
  font-size: 12px;
}
.reset-password-dialog .update-button {
  margin-top: 10px;
}
.reset-password-dialog .update-button span {
  text-transform: uppercase;
  font-size: 14px;
}

.reset-password-overlay {
  background-color: rgba(65, 64, 66, 0.33) !important;
}
