.group-action-menu {
  width: fit-content !important;
  position: absolute;
  right: 0;
  bottom: 0;
}
.group-action-menu .common-tooltip > div:nth-child(2) {
  top: 65px !important;
  right: 0 !important;
  width: auto;
}
.group-action-menu .common-tooltip > div:nth-child(2) > div {
  transform: translate(-55%, -50%) !important;
}
.group-action-menu .common-tooltip > div:nth-child(2) span {
  white-space: nowrap !important;
}
