.app-upgrade-checkboxes {
  margin-top: 16px;
}

.app-upgrade-checkbox {
  margin-top: 8px;
  margin-bottom: 8px;
  padding-left: 16px;
}
.app-upgrade-checkbox svg:first-child path {
  fill: rgba(65, 64, 66, 0.33);
}

.app-upgrade-comment {
  margin-top: 21px;
  margin-bottom: 12px;
}

.file-picking {
  background-color: rgba(65, 64, 66, 0.05) !important;
}

.app-upgrade-file-upload-text {
  position: absolute;
  width: 261px;
  top: 76px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: rgba(65, 64, 66, 0.66);
}
.app-upgrade-file-upload-text a {
  color: #00aaed;
}

.app-upgrade-dialog .import-from-file-container .image-drop-zone .message {
  font-family: "Roboto Bold";
  color: #414042;
  opacity: 1;
}
.app-upgrade-dialog .import-from-file-container .image-drop-zone .or-message {
  opacity: 1;
  color: #414042;
}
.app-upgrade-dialog .import-from-file-container .button-wrapper > div {
  box-shadow: none !important;
  border-radius: 4px !important;
  border: 1px solid rgba(65, 64, 66, 0.1);
}
