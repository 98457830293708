.call-event-details {
  display: flex;
  flex-direction: column;
  padding: 24px;
  overflow-y: auto;
}
.call-event-details .detail-item {
  margin-bottom: 24px;
}
.call-event-details .detail-item > span {
  font-size: 13px;
  color: rgba(33, 33, 33, 0.33);
}
.call-event-details .detail-item .detail-item-value {
  margin-top: 4px;
  font-size: 16px !important;
  color: #212121 !important;
}
.call-event-details .detail-item .detail-item-value .copy-area {
  position: relative;
  padding: 0 !important;
}
.call-event-details .detail-item .detail-item-value .copy-area .copy-btn {
  position: absolute !important;
  right: 0px;
  top: 0px;
  opacity: 0;
}
.call-event-details .detail-item .detail-item-value .copy-area .copy-btn svg {
  color: #736F77 !important;
}
.call-event-details .detail-item .detail-item-value .copy-area:hover .copy-btn {
  opacity: 1;
}
.call-event-details .detail-item .detail-item-value .copy-area:hover .event-payload {
  opacity: 0.7;
}
.call-event-details .detail-item .detail-item-value .copy-area .copy-text {
  font-size: 13px;
  color: #414042;
  text-align: left;
  border: 1px #efefef solid;
  background-color: #f7f7f7;
  padding: 7px 35px 7px 7px;
  word-wrap: break-word;
  max-height: 400px;
  overflow-y: auto;
}
