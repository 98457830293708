.call .media-editor {
  height: 100%;
  position: relative;
}
.call .media-editor .cross {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  color: red !important;
  opacity: 0.3;
  margin: auto;
}
.call .media-editor .drawing-layer {
  background-color: #F2F2F2;
  width: 100%;
  height: 100%;
}
.call .media-editor .call-zoom {
  position: absolute;
  right: 16px;
  top: 50%;
}
.call .media-editor .media-editor-toolbar {
  z-index: 1;
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 500ms ease;
}
.call .media-editor .media-editor-toolbar .color-picker {
  padding: 8px;
  position: absolute;
  bottom: 49px;
  display: flex;
  width: 208px;
  flex-wrap: wrap;
  background-color: rgba(0, 0, 0, 0.5);
  margin-left: auto;
  margin-right: auto;
  border-radius: 4px;
}
.call .media-editor .media-editor-toolbar .color-picker .color {
  cursor: pointer;
  box-sizing: border-box;
  width: 26px;
  height: 26px;
  margin: 5px 13px;
  border-radius: 4px;
}
.call .media-editor .media-editor-toolbar .color-picker .color.selected {
  border: 1px solid #fff;
}
.call .media-editor .media-editor-toolbar .toolbar {
  height: 48px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0px 16px;
}
.call .media-editor .media-editor-toolbar .toolbar .toolbar-button.selected {
  border-bottom: 3px solid #fff;
}
.call .media-editor .media-editor-toolbar .toolbar .toolbar-button.selected.disabled {
  border-bottom: none;
}
.call .media-editor .media-editor-toolbar .toolbar .toolbar-button button svg {
  color: #fff !important;
}
.call .media-editor .media-editor-toolbar .toolbar .toolbar-button button[disabled] svg {
  fill: rgba(255, 255, 255, 0.5) !important;
}
.call .media-editor.drawing .media-editor-toolbar {
  bottom: -48px;
  pointer-events: none;
}
