.forgot-password-link {
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 24px;
  width: 100%;
}
.forgot-password-link a {
  text-decoration: none;
  color: #00aaed;
  margin-left: 12px;
}
.forgot-password-link:hover {
  text-decoration: underline;
  cursor: pointer;
}
