.media-download-confirmation .dialog-title {
  background-color: #00aaed;
  font-size: 20px;
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  align-items: center;
  color: #FFF;
}
.media-download-confirmation .dialog-title span {
  margin-left: 15px;
}
.media-download-confirmation .cancel-action {
  color: #00aaed !important;
}

.media-download-confirmation-body {
  padding: 24px 36px !important;
}
.media-download-confirmation-body span {
  color: rgba(115, 111, 119, 0.66) !important;
  font-size: 14px;
}
