.pdf-editor .pdf-page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.pdf-editor .pdf-page .loading-indicator {
  display: none;
}
.pdf-editor .pdf-page.loading .loading-indicator {
  position: absolute;
  display: block;
}
.pdf-editor .pdf-page.loading canvas {
  display: none;
  opacity: 0.5;
}
