.participant-roles-picker {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  height: 100%;
  max-width: 100%;
  position: relative;
  line-height: 48px;
  display: flex;
  align-items: center;
  flex-direction: row;
}

tr.selected .participant-roles .group-select-field div {
  color: #ffffff !important;
}
tr.selected .participant-roles-picker.menu-picker {
  border-color: transparent;
  background-color: #26a7ef;
}
tr.selected .participant-roles-picker .selected-roles .selected-role {
  border: 1px solid #ededed;
  color: #ededed;
}
tr.selected .participant-roles-picker .selected-roles .selected-role svg {
  fill: #ededed !important;
}

.menu-picker-items-container .menu-picker-items .menu-item.only-owner {
  cursor: not-allowed !important;
}
.menu-picker-items-container .menu-picker-footer {
  display: flex;
  height: 50px;
}
.menu-picker-items-container .menu-picker-footer .manage-roles-button-container {
  display: flex;
  flex: 1;
  align-items: center;
  margin: 0 20px;
  border-top: 1px solid lightgrey;
}
.menu-picker-items-container .menu-picker-footer .manage-roles-button-container a {
  text-decoration: none;
  color: #00afee;
}
