.call-details {
  display: flex;
  flex-direction: column;
  padding: 24px 24px;
  overflow-y: auto;
}
.call-details .detail-item {
  margin-bottom: 24px;
}
.call-details .detail-item .detail-item-title span {
  font-size: 13px;
  color: rgba(33, 33, 33, 0.33);
}
.call-details .detail-item .detail-item-value {
  margin-top: 4px;
  font-size: 16px !important;
  color: #212121 !important;
}
