.saml-options h2 {
  font-size: 16px;
  margin-bottom: 0px;
}
.saml-options .field-control {
  margin-left: 16px;
}
.saml-options .field-text > div {
  width: 80% !important;
}
.saml-options .field-text label {
  color: rgba(33, 33, 33, 0.33) !important;
}
.saml-options .field-control-cert {
  font-size: 14px !important;
  background-color: #f8f8f8;
  box-shadow: inset 0 -1px 0 0 #BBBEC0;
}
.saml-options .field-control-cert label {
  padding-left: 16px;
}
.saml-options .field-control-cert textarea {
  color: rgba(33, 33, 33, 0.33);
  font-size: 13px !important;
  color: #212121 !important;
  padding-left: 12px !important;
}
