.pin-lock .token-section > span:first-of-type, .pin-lock .pin-section > span:first-of-type {
  opacity: 0.66;
  font-size: 20px;
  color: rgba(33, 33, 33, 0.66);
  line-height: 22px;
}
.pin-lock .token-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 70px;
}
.pin-lock .description {
  padding-bottom: 12px;
}
.pin-lock .config-wrapper {
  display: flex;
  flex-direction: column;
}
.pin-lock .config-wrapper .control-wrapper {
  display: flex;
}
.pin-lock .config-wrapper .control-wrapper .pin-length {
  width: 150px !important;
  margin-right: 18px;
}
.pin-lock .config-wrapper .description {
  padding-left: 18px;
}
.pin-lock .pin-toggle, .pin-lock .token-textfield, .pin-lock .pin-length, .pin-lock .pin-lock-message {
  margin-left: 18px;
}
@media (min-width: 768px) {
  .pin-lock .pin-lock-message {
    width: auto !important;
    min-width: 256px;
  }
}
