.tokens-and-timers {
  padding: 20px 20px 68px 20px;
}
.tokens-and-timers .admin-token-settings {
  box-shadow: none;
  font-family: Roboto;
}
.tokens-and-timers .admin-token-settings .title {
  font-size: 20px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.8) !important;
}
.tokens-and-timers .admin-token-settings .description {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.66);
}
.tokens-and-timers .admin-token-settings .token-settings-field-title {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.66);
  opacity: 1;
}
.tokens-and-timers .admin-token-settings .token-settings-field-description {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.66);
}
.tokens-and-timers .admin-token-settings .token-settings-input-field {
  padding-left: 0;
  margin-top: 10px;
  max-width: 384px;
}
