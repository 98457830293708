.user-list .action-button {
  padding: 2px !important;
}
.user-list .action-button.icon {
  margin-left: 0 4px !important;
  padding-bottom: 0px !important;
  border-radius: 4px !important;
}
.user-list .action-button.icon:hover {
  background-color: #ecebec !important;
}
.user-list .action-button.icon.skylight-icon {
  margin: 0 2px !important;
}
