.event-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.event-details .event-details-base {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px 24px;
}
.event-details .event-details-base .details-link a {
  color: #24c6fc;
}
.event-details .event-details-base .details-link {
  overflow-wrap: break-word;
}
.event-details .event-details-base .item-prop-next-gen {
  margin-bottom: 19px;
}
.event-details .event-details-base .item-prop-next-gen .outlined-field {
  cursor: auto;
}
.event-details .event-details-base .item-prop-next-gen .item-prop-body {
  height: 45px;
  display: flex;
  align-items: center;
  font-size: 16px;
}
.event-details .buttons-container {
  display: flex;
}
.event-details .buttons-container .view-json {
  padding: 12px 24px;
  display: flex;
  flex: 1;
}
.event-details .buttons-container .view-json .view-json-button-tooltip {
  display: flex;
  flex: 1;
}
.event-details .buttons-container .view-json .view-json-button {
  flex: 1;
  border-radius: 4px !important;
}
.event-details .buttons-container .view-json .view-json-button button {
  border-radius: 4px !important;
}

.event-details-media .media-preview {
  background-color: #ededed;
  display: flex;
  align-items: center;
  justify-content: center;
}
.event-details-media .media-preview .media-preview-loader {
  height: 200px;
  width: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}
.event-details-media .media-preview .image-preview img {
  height: 200px;
}
.event-details-media .media-preview .annotations {
  width: 267px;
  margin: 0 auto;
}
.event-details-media .media-preview .av-preview {
  width: 100%;
}
.event-details-media .media-preview .av-preview video {
  max-height: 200px;
  max-width: 100%;
}

.details.next-gen .details-drawer.sessionEvent-drawer.with-preview .details-container {
  top: 0 !important;
  height: 100% !important;
}
