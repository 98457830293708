.home-page-legacy .tile-list {
  display: flex;
  margin: 0px 24px;
}
@media (max-width: 767px) {
  .home-page-legacy .tile-list {
    width: calc(100% - 64px) !important;
    flex-direction: column;
  }
  .home-page-legacy .tile-list .tile:nth-child(2) {
    order: -1;
  }
}
.home-page-legacy .tile-list .tile {
  box-sizing: content-box;
  flex: 1;
  height: 308px;
  border: 2px solid #EDEDED;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border: 2px solid #ededed;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  margin: 0px 5px;
}
@media (max-width: 767px) {
  .home-page-legacy .tile-list .tile {
    height: 96px;
    margin-bottom: 12px;
    flex-direction: row;
    justify-content: flex-start;
    border-radius: 2px;
  }
}
.home-page-legacy .tile-list .tile .icon {
  color: #00aaed !important;
  width: 100px !important;
  height: 100px !important;
}
@media (max-width: 767px) {
  .home-page-legacy .tile-list .tile .icon {
    margin-left: 20px;
    margin-right: 36px;
    width: 64px !important;
    height: 64px !important;
  }
}
.home-page-legacy .tile-list .tile .message {
  width: 208px;
  text-align: center;
  font-size: 16px;
  color: #00aaed;
  line-height: 18px;
}
@media (max-width: 767px) {
  .home-page-legacy .tile-list .tile .message {
    text-align: left;
    padding-right: 36px;
  }
}
.home-page-legacy .tile-list .tile:hover {
  box-shadow: 0 0 9px 0 rgba(33, 33, 33, 0.5);
  border-color: transparent;
}
