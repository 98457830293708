.query-filter-picker-button {
  width: 124px;
}
.query-filter-picker-button.filters-error button {
  background-color: #dd2c00 !important;
}
.query-filter-picker-button:hover {
  box-shadow: 0px 0px 8px 0px rgba(33, 33, 33, 0.5) !important;
}
.query-filter-picker-button button div > div {
  color: #ffffff;
  background-color: transparent !important;
  display: flex;
  align-items: center;
}
.query-filter-picker-button button div > div svg {
  margin: 9px;
}
.query-filter-picker-button button div > div span {
  text-transform: uppercase;
  font-size: 14px;
}
.query-filter-picker__error {
  color: #dd2c00;
  font-size: 14px;
  font-weight: 600;
  margin-top: 5px;
}
