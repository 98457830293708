.group-member-list .table tr:not(.selected):hover .actions .material-icon {
  color: #ededed !important;
}
.group-member-list .table .actions .material-icon {
  color: #ededed !important;
}
.group-member-list .member-icon {
  width: 24px !important;
  padding-right: 18px !important;
  color: #bbbec0;
  text-overflow: unset;
}
.group-member-list .member-icon .material-icon {
  cursor: default;
}
.group-member-list .actions button:hover .material-icon {
  color: #00aaed !important;
}
