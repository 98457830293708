.table-next-gen {
  background-color: #ffffff;
}
.table-next-gen > div:not(.table-footer) {
  padding: 8px 18px;
}
.table-next-gen .hover-checkbox-column {
  text-overflow: inherit;
}
.table-next-gen table {
  border: initial;
}
.table-next-gen thead {
  border-top: 1px solid #ededed;
  border-left: 1px solid #ededed;
  border-right: 1px solid #ededed;
  border-radius: 4px 4px 0 0;
}
.table-next-gen thead tr {
  border: none;
}
.table-next-gen tbody {
  border-left: 1px solid #ededed;
  border-right: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  border-radius: 0 0 4px 4px;
}
.table-next-gen tr {
  border-bottom: 1px solid #ededed;
}
.table-next-gen tr:last-child {
  border-bottom: none;
}
.table-next-gen tr th.actions {
  overflow: visible;
}
.table-next-gen tr th .table-column-container .table-column svg.sort-icon {
  fill: #bbbec0 !important;
}
.table-next-gen tr th .table-column-container .table-column .column-title {
  color: #000;
  font-weight: 700;
}
.table-next-gen tr td.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.table-next-gen tr td.actions > button {
  width: 32px !important;
  height: 32px !important;
  padding: 4px !important;
}
.table-next-gen tr th .cursor-tooltip, .table-next-gen tr td .cursor-tooltip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.table-next-gen tr.error .chk, .table-next-gen tr.loading .chk {
  display: none !important;
}
.table-next-gen tr.error .hover-checkbox-column, .table-next-gen tr.loading .hover-checkbox-column {
  overflow: unset;
}
.table-next-gen tr .error > div:nth-child(2) {
  top: 90px !important;
  right: -120px !important;
}
.table-next-gen tr .loading > div:nth-child(2) {
  top: 50px !important;
  right: -50px !important;
}
.table-next-gen .table-wrapper {
  padding: 8px 18px;
}
.table-next-gen .table {
  background-color: #ffffff;
}
.table-next-gen .table table {
  border: initial;
}
.table-next-gen .empty-label-content {
  height: 400px;
}
.table-next-gen .empty-label-content .empty-label-container {
  margin: 0;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.table-next-gen .empty-label-content .empty-label-container span {
  white-space: break-spaces;
}
@media (max-width: 767px) {
  .table-next-gen .table-footer .list-footer-stats {
    margin-left: 0;
  }
  .table-next-gen .table-footer .list-footer-stats .unselect-button {
    margin-right: 0 !important;
  }
  .table-next-gen tr th.actions, .table-next-gen tr td.actions {
    display: none;
  }
}
.table-next-gen .table-footer {
  z-index: 1;
}
.table-next-gen .footer-actions {
  display: flex;
}
.table-next-gen .unselect-button > div > div {
  pointer-events: none;
}

.home:not(.dashboard-tabs) .table-next-gen.table tbody {
  padding-bottom: 0px;
  max-height: calc(100vh - 210px) !important;
}
.home:not(.dashboard-tabs) .table-next-gen.table.with-footer tbody {
  max-height: calc(100vh - 250px) !important;
}
