.pdf-editor .pdf-preview {
  height: 100%;
}
.pdf-editor .pdf-preview canvas {
  display: none;
}
.pdf-editor .pdf-preview img {
  outline: none;
}
.pdf-editor .pdf-preview .loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 400px;
}
