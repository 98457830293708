.group-list-next-gen .action-button {
  padding: 2px !important;
}
.group-list-next-gen .action-button.icon {
  margin-left: 0 4px !important;
  padding-bottom: 0px !important;
  border-radius: 4px !important;
}
.group-list-next-gen .action-button.icon:hover {
  background-color: #ecebec !important;
}
.group-list-next-gen .action-button.icon.skylight-icon {
  margin: 0 2px !important;
}
@media (max-width: 767px) {
  .group-list-next-gen .table-heading {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 10px;
  }
}
.group-list-next-gen .group-list-title {
  text-transform: lowercase;
  color: #bbbec0;
}
.group-list-next-gen .group-list-title .group-list-items-number {
  color: #000;
  margin: 0 8px;
}
.group-list-next-gen.details-open .footer-actions {
  margin-right: 20px;
}
.group-list-next-gen .list-action-button {
  transition: bottom 300ms ease-in-out;
}
.group-list-next-gen .list-action-button.item-selected {
  bottom: 70px !important;
}
