.token-action-buttons {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
.token-action-buttons.has-selected {
  box-shadow: 0 0 5px 0 rgba(33, 33, 33, 0.5);
}
.token-action-buttons.has-selected .token-action-menu {
  bottom: 35px;
}
