.call-user-sessions .table-heading {
  background: transparent;
  height: 30px;
  margin-top: 20px;
  padding-left: 23px;
}
.call-user-sessions .table-heading .title {
  font-size: 16px;
  color: #b4b4b4;
}
.call-user-sessions table tbody {
  overflow: visible;
}
.call-user-sessions table tr:not(.selected):hover td.actions .material-icon {
  color: #b4b4b4 !important;
}
.call-user-sessions table td {
  overflow: visible;
  color: #8a8787;
}
.call-user-sessions table td.session-device {
  width: 60%;
}
.call-user-sessions table td.session-presence, .call-user-sessions table td.actions {
  width: 20%;
  padding-left: 10px;
  padding-right: 10px;
}
.call-user-sessions table td .common-tooltip {
  justify-content: end !important;
}
.call-user-sessions table td .common-tooltip span {
  overflow: hidden;
  text-overflow: ellipsis;
}
