.job-status {
  position: fixed;
  background-color: black;
  bottom: 0px;
  left: 50%;
  z-index: 2901;
  min-width: 300px;
  height: 48px;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0 24px;
}
.job-status.invisible {
  transform: translate(-50%, 48px);
  opacity: 0;
  transition: transform 500ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 500ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.job-status.visible {
  opacity: 1;
  transform: translate(-50%, 0px);
  transition: transform 500ms cubic-bezier(0.23, 1, 0.32, 1) 0ms, opacity 500ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.job-status.tasks.queued, .job-status.tasks.inProgress {
  background-color: #736F77;
}
.job-status.tasks.error {
  background-color: #D61414;
}
.job-status.tasks.complete {
  background-color: #736F77;
}
