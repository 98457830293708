.login-form-button {
  border-radius: 4px !important;
}
.login-form-button button {
  height: 56px !important;
  border-radius: 4px !important;
}
.login-form-button button > div > div {
  height: 56px !important;
  line-height: 56px;
  border-radius: 4px !important;
}
.login-form-button:hover button > div > div {
  background-color: #00C8FF !important;
}
