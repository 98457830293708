@media (max-width: 767px) {
  .details.show {
    min-width: unset !important;
  }
}
.details.next-gen .details-drawer .details-container {
  top: 56px !important;
  height: calc(100% - 56px) !important;
  box-shadow: inset 6px 6px 5px -5px rgba(0, 0, 0, 0.1) !important;
  z-index: 1400 !important;
}
.details.next-gen .details-drawer .details-container .drawer-toolbar {
  box-shadow: inset 6px 6px 5px -5px rgba(0, 0, 0, 0.1) !important;
}
@media (max-width: 767px) {
  .details.next-gen .details-drawer .details-container {
    top: 0px !important;
    height: 100% !important;
  }
}

.details:not(.next-gen) .drawer-toolbar {
  background-color: #19b3ef !important;
  justify-content: flex-end !important;
  flex-direction: row-reverse !important;
}
.details:not(.next-gen) .details-drawer .content-wrapper .drawer-toolbar .title {
  color: rgba(255, 255, 255, 0.66);
  left: 70px !important;
}
.details:not(.next-gen) .details-drawer .content-wrapper .drawer-toolbar .close-icon svg {
  color: #ffffff !important;
}
.details:not(.next-gen) .close-icon {
  margin-left: 6px !important;
}

.next-gen-details {
  display: flex;
  flex-direction: column;
  padding: 6px 12px 12px;
}
.next-gen-details .outlined-field.disabled fieldset {
  height: auto;
  min-height: 50px;
  background-color: rgba(8, 8, 8, 0.05);
  color: rgba(8, 8, 8, 0.5);
}
.next-gen-details .item-prop-next-gen {
  margin-bottom: -5px;
}
.next-gen-details .item-prop-next-gen .outlined-field {
  cursor: auto;
}
.next-gen-details .item-prop-next-gen .details-link {
  overflow-wrap: break-word;
}
.next-gen-details .item-prop-next-gen .details-link a {
  color: #24c6fc;
}
.next-gen-details .item-prop-next-gen .item-prop-body {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  font-size: 16px;
  min-height: 34px;
  padding-block-end: 5px;
  font-size: 14px;
}
.next-gen-details .item-prop-next-gen .error-message-field fieldset .item-prop-body {
  line-height: 20px;
  line-break: anywhere;
}
