.home .home-body {
  padding-left: 72px;
}
.home .home-body .dashboard-tabs > div:first-child {
  position: sticky;
  top: 56px;
  z-index: 2;
  background-color: #FFF !important;
  box-shadow: 0 5px 5px -6px #333;
}
.home .home-body .dashboard-tabs > div:first-child > button {
  max-width: 160px !important;
}
.home .home-body .dashboard-tabs > div:nth-child(2) {
  max-width: 320px !important;
  position: sticky;
  top: 104px;
  z-index: 2;
}
.home .home-body .dashboard-tabs > div:nth-child(2) > div {
  top: -2px;
  background-color: #00aaed !important;
}
.home .home-body .dashboard-tabs .table table tbody {
  max-height: calc(100vh - 227px) !important;
}
.home .home-body .admin-tabs .dashboard-tabs > div:nth-child(2) {
  max-width: 800px !important;
}
.home .home-body .app-tabs .dashboard-tabs > div:nth-child(2), .home .home-body .auth-tabs .dashboard-tabs > div:nth-child(2) {
  max-width: 480px !important;
}
.home:not(.dashboard-tabs) .table tbody {
  max-height: calc(100vh - 179px) !important;
}
.home .details-drawer .details-content {
  flex: 1;
}
.home .details-drawer .details-content .details-tabs > div:first-child {
  background-color: #19b3ef !important;
}
.home.docked .list-footer-stats .actions {
  margin-left: auto;
  margin-right: 296px;
}
.home.docked .home-body {
  padding-left: 256px;
}
.home.docked .home-body .dashboard-tabs .dashboard-list {
  background-color: #fff;
}
.home.docked .home-body .dashboard-tabs .dashboard-list .list-actions {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.home.docked .home-body .dashboard-tabs .dashboard-list .list-actions svg {
  color: #acb1b3 !important;
}
.home.docked .home-body .dashboard-tabs .dashboard-list .list-actions svg:hover {
  color: #1496eb !important;
}
.home tr.loading-row td > div {
  margin: 0px 10px;
}

.checkbox-control > div > div {
  margin-right: 6px !important;
}
.checkbox-control > div > div svg:last-child {
  fill: #24c6fc !important;
}
.checkbox-control:not(.checked) svg {
  fill: #bbbec0 !important;
}

.error.snackbar-common-error div:first-child {
  width: max-content !important;
}
.error.snackbar-common-error span {
  font-family: "Roboto";
}

#root {
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
}

.Root {
  height: 100%;
}
