.preferences .preferences-fields {
  margin-left: 80px;
}
.preferences .checkbox-field {
  display: flex !important;
  align-items: center;
  color: #b1afaf;
}
.preferences .checkbox-field .checkbox-control {
  width: unset !important;
}
@media (max-width: 767px) {
  .preferences .preferences-fields {
    margin-left: unset;
  }
}
.preferences .warning {
  color: #b1afaf;
  font-size: 12px;
  font-weight: bold;
  margin-left: 29px;
  margin-top: 2px;
}
