@media (max-width: 767px) {
  .user-list-next-gen .table-heading {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 10px;
  }
}
.user-list-next-gen .user-list-title {
  text-transform: lowercase;
  color: #bbbec0;
}
.user-list-next-gen .user-list-title .user-list-items-number {
  color: #000;
  margin: 0 8px;
}
.user-list-next-gen .role-column {
  text-transform: capitalize;
}
.user-list-next-gen .new-group {
  height: 24px;
}
.user-list-next-gen .actions .footer-actions {
  display: flex;
  margin-left: 15px;
}
.user-list-next-gen .actions .footer-actions .action-button.icon {
  margin: 0 5px;
}
.user-list-next-gen.details-open .footer-actions {
  margin-right: 20px;
}
.user-list-next-gen .list-action-button {
  transition: bottom 300ms ease-in-out;
}
.user-list-next-gen .list-action-button.item-selected {
  bottom: 70px !important;
}
