.login-domain-link-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  padding: 15px;
  width: 250px;
  display: flex;
  white-space: nowrap;
}
.login-domain-link-wrapper:hover {
  cursor: pointer;
}
.login-domain-link-wrapper:hover .login-domain-link-icon {
  color: #00aaed !important;
  margin-right: 10px;
  display: inherit;
}
.login-domain-link-wrapper:hover .login-domain-link {
  color: #00aaed !important;
  text-decoration: underline !important;
}
.login-domain-link-wrapper .login-domain-link-icon {
  display: none;
}
@media (max-width: 767px) {
  .login-domain-link-wrapper .login-domain-link-icon {
    color: #00aaed !important;
    margin-right: 10px;
    display: inherit;
  }
  .login-domain-link-wrapper .login-domain-link-icon {
    display: inherit !important;
  }
  .login-domain-link-wrapper .login-domain-link {
    color: #00aaed !important;
    text-decoration: underline !important;
  }
}
.login-domain-link-wrapper .login-domain-link {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.33);
  text-transform: capitalize;
  font-size: 16px;
}
.login-domain-link-wrapper .login-domain-link:visited {
  color: #736f77;
}
.login-domain-link-wrapper .login-domain-link:hover {
  color: #00aaed !important;
  text-decoration: underline !important;
}
.login-domain-link-wrapper .login-domain-link-tooltip {
  display: flex;
  align-items: center;
}
