.pending-assignments-indicator {
  display: flex;
  margin-left: 40px;
  color: #00aaed;
}
.pending-assignments-indicator .tooltip-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.pending-assignments-indicator .tooltip-container:hover .visibility-icon {
  display: inline-block !important;
}
.pending-assignments-indicator .tooltip-container:hover .spinner-icon {
  display: none !important;
}
.pending-assignments-indicator .tooltip-container:hover .tooltip-children-container {
  text-decoration: underline;
}
.pending-assignments-indicator .tooltip-container .icon {
  margin-right: 12px;
  color: #00aaed !important;
}
.pending-assignments-indicator .tooltip-container .visibility-icon {
  display: none !important;
}
.pending-assignments-indicator .tooltip-container .pending-assignments-label {
  display: flex;
  font-size: 16px;
}
.pending-assignments-indicator .tooltip-container .pending-assignments-label .tooltip-children-container {
  cursor: pointer;
}
.pending-assignments-indicator .tooltip-container .pending-assignments-label .pending-size {
  margin-left: 7px;
}
