.snackbars .snackbar {
  display: flex;
  align-items: center;
  right: 10px;
  left: auto;
  background-color: #323232;
  color: white;
  font-family: Roboto, sans-serif;
  font-size: 14px;
}
