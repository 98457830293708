.signin {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.signin .forgot-password-hint {
  font-family: "Roboto", sans-serif;
  line-height: 18px;
  font-size: 13px;
  color: rgba(33, 33, 33, 0.3);
  margin-bottom: 30px;
}
.signin .forgot-password-hint > span {
  display: block;
}
.signin > .username, .signin > .password, .signin > .realm {
  width: 100% !important;
}
.signin > .username.error label span, .signin > .password.error label span, .signin > .realm.error label span {
  color: #f44336 !important;
}
.signin > .username.error hr, .signin > .password.error hr, .signin > .realm.error hr {
  border-top: none #f44336 !important;
  border-left: none #f44336 !important;
  border-right: none #f44336 !important;
  border-bottom: 2px solid #f44336 !important;
}
.signin > .error-text {
  z-index: 1;
  font-size: 12px;
  line-height: 18px;
  width: 100%;
  color: #dd2c00 !important;
  margin-bottom: 30px;
  font-family: Roboto, sans-serif;
}
.signin > .error-text span {
  margin-left: 12px;
}
.signin > .username {
  margin-bottom: 28px;
}
.signin > .password {
  margin-bottom: 5px;
}
.signin > .password .underline-text {
  display: none;
}
.signin > .realm {
  margin-bottom: 32px;
}
.signin > .stay-signed {
  margin-bottom: 32px;
  margin-top: 20px;
}
.signin > .signin-btn span {
  font-size: 14px;
  text-transform: uppercase;
}
.signin > .signin-btn.idp {
  margin-top: 10px;
}
.signin > .signin-frontline-btn {
  margin-top: 10px;
}
.signin > .signin-frontline-btn button {
  background-color: red !important;
}
.signin input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 40px white inset;
  box-shadow: 0 0 0 40px white inset;
}
.signin .sign-in-picker {
  width: 100%;
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.signin .sign-in-picker .sign-in-using {
  width: 100%;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.33);
  text-align: center;
  margin-bottom: 12px;
  position: relative;
}
.signin .sign-in-picker .sign-in-using:before, .signin .sign-in-picker .sign-in-using:after {
  content: "";
  position: absolute;
  left: 0;
  top: 10px;
  width: 46%;
  background: rgba(65, 64, 66, 0.05);
  height: 1px;
}
.signin .sign-in-picker .sign-in-using:after {
  left: initial;
  right: 0;
}
.signin .sign-in-picker .btn {
  color: #00aaed !important;
  text-decoration: none;
}
