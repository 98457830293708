.assignment-textinput-tile .textinput-tile-label {
  font-size: 13px;
  color: #bbbec0;
}
.assignment-textinput-tile .textinput-tile-input {
  border-radius: 4px;
  box-shadow: 0 0 0 2px #bbbec0 inset;
  background-color: #f6f6f6;
  color: #bbbec0;
  font-size: 16px;
  padding: 10px;
  word-wrap: break-word;
}
.assignment-textinput-tile .textinput-tile-input.textinput-empty {
  box-shadow: 0 0 0 2px #ededed inset;
}
