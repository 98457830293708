.token-item-details .show-credentials-link {
  position: absolute !important;
  right: 5px;
  top: 30px;
}
.token-item-details .show-credentials-link svg {
  color: #736f77 !important;
}
@media (max-width: 767px) {
  .token-item-details .item-prop:first-child {
    max-width: calc(100% - 30px);
  }
}
