.activity-details {
  display: flex;
  flex-direction: column;
  padding: 24px 24px;
  overflow-y: auto;
}
.activity-details .detail-item {
  margin-bottom: 24px;
}
.activity-details .detail-item > span {
  font-size: 13px;
  color: rgba(33, 33, 33, 0.33);
}
.activity-details .detail-item .detail-item-value {
  margin-top: 4px;
  font-size: 16px !important;
}
