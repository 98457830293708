.group-body .select-with-search-wrapper {
  margin-right: 34px;
}
.group-body .select-select-with-search {
  border-bottom: 1px solid #e0e0e0;
  width: 256px;
}
.group-body .select-content {
  border: none;
  height: 30px;
  width: 256px;
}
.group-body .select-item {
  margin: 0;
  padding: 0;
}
