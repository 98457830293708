.next-gen-application-tile {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff;
  border-radius: 3px;
  cursor: pointer;
  box-shadow: 0 0 1px 2px #ededed;
}
.next-gen-application-tile:hover {
  box-shadow: 0 0 2px 2px #bbbec0;
}
.next-gen-application-tile.selected, .next-gen-application-tile.multi-selected {
  box-shadow: 0 0 0 2px #00aaed, 0 0 4px 1px #bbbec0;
}
.next-gen-application-tile.archived.selected, .next-gen-application-tile.archived.multi-selected {
  box-shadow: 0 0 0 2px #bbbec0, 0 0 4px 1px #bbbec0;
}
.next-gen-application-tile.archived.selected .archived-icon, .next-gen-application-tile.archived.multi-selected .archived-icon {
  opacity: 1;
}
.next-gen-application-tile.importing {
  cursor: default;
}
.next-gen-application-tile.importing .actions {
  align-items: center;
  box-shadow: none !important;
  text-transform: uppercase;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.33);
}
.next-gen-application-tile.importing:hover {
  box-shadow: 0 0 1px 2px #ededed;
}
.next-gen-application-tile .content {
  flex: 3;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 5px;
  user-select: none;
}
.next-gen-application-tile .content .skylight-icons {
  color: #736f77;
  font-size: 48px;
}
.next-gen-application-tile .content .icon {
  fill: #736f77 !important;
  width: 48px !important;
  height: 48px !important;
}
.next-gen-application-tile .content .icon.failed-import-icon {
  fill: #CF0234 !important;
}
.next-gen-application-tile .content .top-left-icon {
  position: absolute;
  top: 5px;
  left: 10px;
}
.next-gen-application-tile .content .icon-wrapper > div:last-child[style] {
  right: initial !important;
  left: 0 !important;
}
.next-gen-application-tile .content .archived-icon {
  color: #736f77 !important;
  opacity: 0.5;
}
.next-gen-application-tile .content .common-tooltip {
  justify-content: center;
  z-index: 4;
}
.next-gen-application-tile .content .common-tooltip > div:nth-child(2) {
  top: 0 !important;
  right: 25px !important;
  border-radius: 4px !important;
}
.next-gen-application-tile .content .common-tooltip .name {
  font-family: "Roboto Bold";
  font-size: 14px;
  color: #212121;
  max-width: -webkit-fill-available;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  text-align: center;
}
.next-gen-application-tile .content .common-tooltip .import-failed {
  color: #CF0234;
  font-size: 13px;
  font-weight: normal;
  font-family: Roboto;
  position: absolute;
  left: 0;
  width: 100%;
}
.next-gen-application-tile .content .details {
  font-size: 13px;
  text-align: center;
  width: 100%;
}
.next-gen-application-tile .content .details .creator {
  color: #414042;
}
.next-gen-application-tile .content .details .time {
  color: #736f77;
  font-family: "Roboto";
}
.next-gen-application-tile .actions {
  flex: 1;
  display: flex;
  color: #736f77;
  justify-content: center;
}
.next-gen-application-tile .actions > .item-action-button {
  margin: 8px;
  display: none !important;
  flex: 1;
  font-weight: 600;
  font-size: 15px;
  text-transform: uppercase;
}
.next-gen-application-tile .actions > .item-action-button:hover {
  background-color: #ededed;
}
.next-gen-application-tile .actions > .item-action-button .cursor-tooltip {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
.next-gen-application-tile .actions > .item-action-button .cursor-tooltip-body {
  text-transform: capitalize;
}
.next-gen-application-tile .actions > .item-action-button .material-icon {
  color: #736f77 !important;
}
.next-gen-application-tile .actions .common-tooltip {
  justify-content: center;
}
.next-gen-application-tile:hover .actions, .next-gen-application-tile.selected .actions {
  box-shadow: 0px -1px 0px 0px #ededed;
}
.next-gen-application-tile:hover .actions > .item-action-button, .next-gen-application-tile.selected .actions > .item-action-button {
  display: flex !important;
}
.next-gen-application-tile.multi-selected .actions {
  box-shadow: unset;
}
.next-gen-application-tile.multi-selected .actions > .item-action-button {
  display: none !important;
}
