.group-details {
  padding: 12px 24px;
}
.group-details > .form {
  display: flex;
  flex-direction: column;
}
.group-details > .form > div {
  width: 100% !important;
}
.group-details .group-loading {
  margin-top: -12px;
}
.group-details input:disabled {
  -webkit-text-fill-color: #212121 !important;
}
.group-details .type-select div > div:nth-child(1) > div:nth-child(2) {
  -webkit-text-fill-color: #212121 !important;
}
.group-details .type-select button[disabled] {
  display: none !important;
}
.group-details .details-item {
  padding-top: 15px;
}
@media (max-width: 750px) {
  .group-details .details-item {
    width: 100% !important;
  }
}
.group-details .details-item .title {
  padding-bottom: 5px;
  display: block;
  font-size: 14px;
  color: rgba(33, 33, 33, 0.33);
}
.group-details .details-item .value {
  display: block;
  font-size: 16px;
  color: #212121;
  line-height: 18px;
}
.group-details .set-as-call-group-wrapper {
  width: 100%;
  margin-top: 25px;
}
.group-details .set-as-call-group-wrapper label {
  top: 29px !important;
}
