.ldap-section .field-control-wrapper {
  display: flex;
  justify-content: flex-end;
}
.ldap-section .field-control-wrapper:hover .field-control-tooltip {
  display: block;
}
.ldap-section .field-control-wrapper .field-control {
  flex: 1;
}
.ldap-section .field-control-wrapper .field-control-tooltip {
  position: relative;
  display: none;
}
.ldap-section .field-control-wrapper .field-control-tooltip-icon {
  position: absolute;
  right: 0;
  top: 10px;
  fill: #ededed !important;
  z-index: 10;
}
.ldap-section .field-control-wrapper .field-control-tooltip-text {
  position: absolute;
  right: -5px;
  top: 5px;
  background-color: white;
  box-shadow: 0 0 10px 0 #736f77;
  border-radius: 4px;
  padding: 40px 10px 10px 10px;
  width: 250px;
  z-index: 9;
  font-family: "Roboto";
  font-size: 15px;
}
