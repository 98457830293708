.home-page-next-gen .tile-list {
  display: flex;
}
@media (max-width: 1024px) {
  .home-page-next-gen .tile-list {
    flex-direction: column;
  }
}
.home-page-next-gen .tile-list .tile {
  box-sizing: content-box;
  flex: 1;
  height: 308px;
  border: 2px solid #EDEDED;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border: 2px solid #ededed;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  margin: 5px;
}
@media (max-width: 1024px) {
  .home-page-next-gen .tile-list .tile {
    height: 96px;
    margin-bottom: 12px;
    flex-direction: row;
    justify-content: flex-start;
    border-radius: 2px;
    max-width: 100%;
  }
}
.home-page-next-gen .tile-list .tile .icon {
  color: #00aaed !important;
  width: 100px !important;
  height: 100px !important;
}
@media (max-width: 1024px) {
  .home-page-next-gen .tile-list .tile .icon {
    margin-left: 20px;
    margin-right: 36px;
    width: 64px !important;
    height: 64px !important;
  }
}
.home-page-next-gen .tile-list .tile .message {
  text-align: center;
  font-size: 15px;
  color: #00aaed;
  line-height: 18px;
  margin: 30px 0;
}
@media (max-width: 1024px) {
  .home-page-next-gen .tile-list .tile .message {
    width: 208px;
    text-align: left;
    padding-right: 36px;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .home-page-next-gen .tile-list .tile .message {
    width: unset;
    font-size: 17px;
  }
}
.home-page-next-gen .tile-list .tile:hover {
  box-shadow: 0 0 9px 0 rgba(33, 33, 33, 0.5);
  border-color: transparent;
}

.home-tile-actions-tooltip {
  display: flex;
  flex: 1;
}
