.saml-auth .saml-auth-content {
  padding: 20px 20px 68px 20px;
}
.saml-auth .saml-auth-content .auth-picker {
  margin: 15px 0 0 15px;
  font-family: Roboto;
}
.saml-auth .saml-auth-content .auth-picker .title {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.8) !important;
  font-weight: 700;
}
.saml-auth .saml-auth-content .auth-picker .description {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.66);
}
.saml-auth .saml-auth-content .saml-options .field-control {
  margin-top: 10px;
}
.saml-auth .saml-auth-content .saml-options .field-control-cert {
  background-color: #fff;
  box-shadow: none;
}
