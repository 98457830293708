.import-from-file-container .image-drop-zone {
  border: 2px dashed rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: initial !important;
  min-height: 300px;
}
.import-from-file-container .image-drop-zone .message {
  font-size: 20px;
  opacity: 0.6;
  color: #000;
  font-weight: 400;
}
.import-from-file-container .image-drop-zone .or-message {
  font-size: 14px;
  color: #000;
  opacity: 0.33;
}
.import-from-file-container .button-wrapper {
  margin-top: 5px !important;
}
.import-from-file-container .button-wrapper span {
  text-transform: none !important;
  color: rgba(0, 0, 0, 0.66);
}
.import-from-file-container .dragging-file {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 5;
  background-color: rgba(255, 255, 255, 0.9);
  pointer-events: none;
}
.import-from-file-container .dragging-file .upload-icon-wrapper svg {
  color: #00aaed !important;
  width: 96px !important;
  height: 96px !important;
}
.import-from-file-container .dragging-file .drop-file-to-upload {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.8);
}
