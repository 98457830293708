.field-custom-filters {
  display: flex;
  flex-direction: column;
}
.field-custom-filters .label-wrapper {
  display: flex;
  padding: 10px 0;
}
.field-custom-filters .label-wrapper .label-title {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: rgba(33, 33, 33, 0.33);
  text-align: left;
  display: flex;
  align-items: center;
  margin-left: 8px;
}
.field-custom-filters .label-wrapper .label-title.disabled {
  margin-left: 0px;
}
.field-custom-filters .label-wrapper .label-button .add-filter-button {
  margin-left: 0px;
  display: flex;
  border: none;
  border-radius: 4px;
  background-color: #24c6fc;
}
.field-custom-filters .label-wrapper .label-button .add-filter-button:hover {
  border: none;
  font-weight: bold;
  background-color: #66c6f2 !important;
}
.field-custom-filters .label-wrapper .label-button .add-filter-button i.material-icon.material-icons {
  color: #fff;
  font-weight: bold;
  font-size: 18px;
}
.field-custom-filters .label-wrapper .label-button .add-filter-button.disabled {
  display: none;
}
.field-custom-filters .add-additional-filter-form-wrapper {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
  margin-left: 32px;
}
.field-custom-filters .add-additional-filter-form-wrapper .form-wrapper {
  display: flex;
  width: 100%;
}
.field-custom-filters .add-additional-filter-form-wrapper .form-wrapper .add-additional-filter-form {
  width: 100%;
  border: 1px solid #bbbec0;
  border-radius: 4px;
  margin-bottom: 5px;
  background-color: #fbfbfb;
}
.field-custom-filters .add-additional-filter-form-wrapper .form-wrapper .add-additional-filter-form .form-content {
  margin: 0 15px 10px 15px;
}
.field-custom-filters .add-additional-filter-form-wrapper .form-wrapper .add-additional-filter-form input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 40px white inset;
  box-shadow: 0 0 0 40px white inset;
}
.field-custom-filters .add-additional-filter-form-wrapper .form-wrapper .add-additional-filter-form input[type=text] {
  font-family: "Roboto", sans-serif !important;
  font-size: 14px;
  color: rgba(33, 33, 33, 0.66) !important;
  text-align: left;
}
.field-custom-filters .add-additional-filter-form-wrapper .form-wrapper .add-additional-filter-form .additional-custom-filter-item:first-child div hr {
  border-bottom: 1px solid #e0e0e0 !important;
}
.field-custom-filters .add-additional-filter-form-wrapper .form-wrapper .delete-button-wrapper {
  display: flex;
}
.field-custom-filters .add-additional-filter-form-wrapper .form-wrapper .delete-button-wrapper .delete-filter-button {
  display: flex;
  align-items: center;
  opacity: 0;
}
.field-custom-filters .add-additional-filter-form-wrapper .form-wrapper .delete-button-wrapper .delete-filter-button i.material-icon.material-icons {
  color: #dd2c00 !important;
}
.field-custom-filters .add-additional-filter-form-wrapper .form-wrapper .delete-button-wrapper .delete-filter-button i.material-icon.material-icons:hover {
  color: #c02319 !important;
}
.field-custom-filters .add-additional-filter-form-wrapper:hover .delete-button-wrapper .delete-filter-button {
  opacity: 1;
}
.field-custom-filters .custom-filters-wrapper {
  display: flex;
  flex-direction: row;
  padding: 2px 0;
}
.field-custom-filters .custom-filters-wrapper .custom-filter {
  border: 1px solid #bbbec0;
  border-radius: 4px;
  background-color: #ededed;
  padding: 10px;
  width: 100%;
}
.field-custom-filters .custom-filters-wrapper .filter-name {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: rgba(33, 33, 33, 0.66);
  text-align: left;
}
.field-custom-filters .custom-filters-wrapper .filter-text {
  font-family: "Roboto", sans-serif;
  font-size: 13px;
  color: #414042;
  text-align: left;
  margin-left: 10px;
}
.field-custom-filters .custom-filters-wrapper .trash-button .delete-filter-button {
  opacity: 0;
}
.field-custom-filters .custom-filters-wrapper:hover .trash-button {
  display: flex;
}
.field-custom-filters .custom-filters-wrapper:hover .trash-button .delete-filter-button {
  display: flex;
  align-items: center;
  opacity: 1;
}
.field-custom-filters .custom-filters-wrapper:hover .trash-button .delete-filter-button i.material-icon.material-icons {
  color: #dd2c00 !important;
}
.field-custom-filters .custom-filters-wrapper:hover .trash-button .delete-filter-button i.material-icon.material-icons:hover {
  color: #c02319 !important;
}

.progress-indicator-wrapper {
  width: 80%;
  margin-left: 24px;
}
.progress-indicator-wrapper .progress-indicator {
  display: flex;
  justify-content: center;
}

.field.field-custom-filters.field-disabled {
  pointer-events: none;
}
