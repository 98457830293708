.change-password-content {
  max-width: 650px !important;
}
.change-password-content div:first-child {
  border-radius: 4px !important;
}
.change-password-content .dialog-title {
  border-bottom: 1px solid #ededed;
  padding: 15px 24px 5px !important;
  font-size: 20px;
}
.change-password-content .dialog-title .steps {
  margin-left: auto;
  color: #a1dff7;
  font-size: 14px !important;
}
.change-password-content .change-password-body {
  padding: 5px 24px 12px 24px !important;
}
.change-password-content .change-password-body form > span {
  font-size: 14px;
}
.change-password-content .change-password-body .account-password .section-title {
  display: none;
}
.change-password-content .change-password-body .account-password .info-fields {
  display: flex;
  flex-wrap: wrap;
}
.change-password-content .change-password-body .account-password .info-fields > div {
  width: 100% !important;
}
.change-password-content .change-password-body .step-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
}
.change-password-content .update-button span {
  text-transform: uppercase;
  font-size: 14px;
}
.change-password-content .captcha-badge-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 5px;
}
.change-password-content .captcha-badge-container a {
  color: rgba(0, 0, 0, 0.66);
}
.change-password-content .captcha-badge {
  font-size: 12px;
}

.change-password-overlay {
  background-color: rgba(65, 64, 66, 0.33) !important;
}
