.item-props .item-prop {
  padding: 12px 0;
}
.item-props .item-prop .title,
.item-props .item-prop .value {
  display: block;
}
.item-props .item-prop .title {
  font-size: 14px;
  color: rgba(33, 33, 33, 0.33);
  padding-bottom: 5px;
}
.item-props .item-prop .value {
  font-size: 16px;
  color: #212121;
  line-height: 18px;
  max-height: 36px;
  overflow: hidden;
}
