.home .home-body .dashboard-tabs .ssrc-video-send .table table tbody {
  max-height: calc(100vh - 262px) !important;
}

.ssrc-video-send {
  display: flex;
}
.ssrc-video-send .fixed-table {
  width: 10%;
  min-width: 170px;
}
.ssrc-video-send .fixed-table .table th {
  text-align: right;
  padding-right: 10px;
}
.ssrc-video-send .fixed-table .table table tbody {
  height: 100vh;
  border-right: 1px solid #e0e0e0;
}
.ssrc-video-send .fixed-table .table table tbody::-webkit-scrollbar {
  display: none;
}
.ssrc-video-send .scrollable-table {
  width: 90%;
  overflow: auto;
}
.ssrc-video-send .scrollable-table .table table {
  width: auto !important;
}
.ssrc-video-send .scrollable-table .table table th, .ssrc-video-send .scrollable-table .table table td {
  width: 20%;
  min-width: 120px !important;
}
.ssrc-video-send .scrollable-table .table table tr {
  width: auto !important;
}
.ssrc-video-send .table .table-column-container {
  width: 100px;
}
@media (max-width: 767px) {
  .ssrc-video-send th, .ssrc-video-send td {
    display: table-cell !important;
  }
  .ssrc-video-send .scrollable-table .table table th, .ssrc-video-send .scrollable-table .table table td {
    min-width: 100px !important;
  }
}
@media (min-width: 768px) and (max-width: 1024px) {
  .ssrc-video-send th, .ssrc-video-send td {
    display: table-cell !important;
  }
}
