.application-list .table-heading {
  box-shadow: 15px 0 0 0 #fff, -15px 0 0 0 #fff;
  top: 0;
}
.application-list .item-action-button {
  margin: 0 2px !important;
}
.application-list .item-action-button > div > div {
  pointer-events: none;
}
.application-list .item-action-button:hover .material-icon {
  color: #00aaed !important;
}
.application-list .grid-footer {
  z-index: 5;
}
.application-list .action-button {
  padding: 2px !important;
}
.application-list .action-button.icon {
  margin-left: 0 4px !important;
  padding-bottom: 0px !important;
  border-radius: 4px !important;
}
.application-list .action-button.icon:hover {
  background-color: #ecebec !important;
}
.application-list .action-button.icon.skylight-icon {
  margin: 0 2px !important;
}
.application-list .select-all-checkbox {
  width: 24px !important;
  height: 24px !important;
  display: none !important;
}
.application-list .select-all-checkbox div div div svg:first-child {
  fill: #9e9e9e !important;
}
.application-list .select-all-checkbox.checked {
  display: block !important;
}

.confirmation-dialog.lock-application-modal .lock-application-modal-title {
  background-color: #dd2c00;
  color: #fff !important;
  font-size: 20px;
  display: flex;
  align-items: center;
}
.confirmation-dialog.lock-application-modal .lock-application-modal-title > svg {
  margin-right: 10px;
}
.confirmation-dialog.lock-application-modal div > button:last-child span {
  color: #dd2c00;
}
