.empty-list-view {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.empty-list-view .participant-icon {
  width: 240px !important;
  height: 240px !important;
  margin-bottom: 20px;
  color: #bbbec0 !important;
}
