.call .call-button, .call-notification .call-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 24px;
}
.call .call-button > div, .call-notification .call-button > div {
  border-radius: 8px !important;
  box-shadow: none !important;
}
.call .call-button > div button, .call-notification .call-button > div button {
  border-radius: 8px !important;
}
.call .call-button > div button svg, .call-notification .call-button > div button svg {
  color: #fff !important;
}
