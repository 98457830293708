.collapsible-section {
  display: flex;
  flex-direction: column;
  margin: 15px 0;
}
.collapsible-section.open .collapsible-section-icon svg {
  transform: rotate(180deg);
}
.collapsible-section.open .collapsible-section-body {
  display: flex;
  flex-direction: column;
}
.collapsible-section .collapsible-section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #bbbec0;
  font-size: 13px;
  text-transform: uppercase;
  color: #736f77;
  cursor: pointer;
  padding: 7px 5px;
  margin-bottom: 20px;
}
.collapsible-section .collapsible-section-header .collapsible-section-icon {
  margin: 0 15px;
}
.collapsible-section .collapsible-section-header .collapsible-section-icon svg {
  fill: #736f77 !important;
}
.collapsible-section .collapsible-section-body {
  display: none;
}
