.roles-list .role-list-title {
  color: #000;
  font-weight: 700;
}
.roles-list .app-roles-table .security-icon {
  color: #00aaed !important;
}
.roles-list .app-roles-table .app-role:hover .delete-icon {
  fill: #dd2c00 !important;
}
.roles-list .app-roles-table .app-role .delete-icon {
  fill: transparent !important;
}
.roles-list .list-action-button {
  transition: bottom 300ms ease-in-out;
}
.roles-list .list-action-button.item-selected {
  bottom: 70px !important;
}
.roles-list .table-footer .footer-actions {
  display: flex;
}
.roles-list .actions {
  text-align: right;
  display: flex;
  justify-content: center;
  align-items: center;
}
.roles-list .actions .item-action-button {
  padding: 7px !important;
  width: 34px !important;
  height: 34px !important;
}

.confirmation-dialog.remove-application-role-modal .remove-application-role-modal-title {
  background-color: #dd2c00;
  color: #ffffff !important;
  font-size: 20px;
  display: flex;
  align-items: center;
}
.confirmation-dialog.remove-application-role-modal .remove-application-role-modal-title > svg {
  margin-right: 10px;
  color: #ffffff !important;
  fill: #ffffff !important;
  height: 30px !important;
  width: 30px !important;
}
.confirmation-dialog.remove-application-role-modal div > button:last-child span {
  color: #dd2c00;
}
.confirmation-dialog.remove-application-role-modal .body {
  padding: 10px 24px 24px !important;
}
