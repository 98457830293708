.workflow-picker .table tr {
  position: relative;
}
.workflow-picker .table tr [data-tooltip] {
  display: inline-flex;
  padding: 4px;
}
.workflow-picker .table tr [data-tooltip]:before {
  content: attr(data-tooltip);
  display: none;
  position: absolute;
  background: #616161;
  opacity: 0.9;
  color: #fff;
  padding: 4px 8px;
  max-width: 220px;
  line-height: 1.4;
  text-align: center;
  border-radius: 4px;
  font-size: 10px;
  transform: translateY(-50%);
  left: 20%;
  top: -12px;
  white-space: pre-wrap;
}
.workflow-picker .table tr:hover [data-tooltip]:before {
  display: flex;
  z-index: 50;
}
.workflow-picker .table tr:first-of-type [data-tooltip]:before {
  transform: translateY(100%);
  top: 10px;
}
.workflow-picker .table .empty-label-container {
  margin-top: 100px;
}
