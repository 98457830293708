.top-bar-popover {
  max-width: 300px;
  border-radius: 4px !important;
}
.top-bar-popover > div > div > div {
  max-width: 300px;
}
.top-bar-popover .top-bar-popover-menu {
  width: 300px !important;
  max-width: 300px !important;
}
.top-bar-popover .top-bar-popover-menu span {
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
}
