.archive-app-confirmation-dialog__title svg {
  margin-right: 9px;
}
.archive-app-confirmation-dialog__text {
  font-size: 14px;
  line-height: 19px;
  color: rgba(33, 33, 33, 0.66);
}
.archive-app-confirmation-dialog > div > div > div {
  border-radius: 4px !important;
}
