.account-settings-content {
  width: 90% !important;
}
.account-settings-content > div > div {
  padding: 0 !important;
}
.account-settings-content .dialog-title {
  border-bottom: 1px solid #ededed;
  padding: 15px 24px 5px;
  font-size: 21px;
}
.account-settings-content .account-settings-container {
  padding: 15px;
  max-height: 436px;
  overflow-y: auto;
}
.account-settings-content .actions-container {
  border-top: 1px solid #ededed;
  padding: 5px !important;
}
