.application-permission-selector .top-label {
  position: absolute;
  top: 5px;
  font-size: 13px;
}
.application-permission-selector .application-permission-checkbox svg:first-of-type {
  fill: #bbbec0 !important;
}
.application-permission-selector .application-permission-checkbox.disabled svg:first-of-type {
  fill: rgba(187, 190, 192, 0.25) !important;
}
.application-permission-selector .application-permission-checkbox.disabled svg:last-of-type {
  fill: rgba(0, 170, 237, 0.25) !important;
}
.application-permission-selector .application-permission-checkbox div:nth-child(2) div:first-child {
  margin-left: 0 !important;
}
