.group-members .details-item {
  padding-top: 15px;
  margin: 0 20px;
}
.group-members .details-item .title {
  padding-bottom: 5px;
  display: block;
  font-size: 14px;
  color: rgba(33, 33, 33, 0.33);
}
.group-members .details-item .value {
  display: block;
  font-size: 16px;
  color: #212121;
  line-height: 18px;
}
.group-members .details-item .action-add {
  border: 1px solid #ededed !important;
  border-radius: 4px !important;
}
.group-members .details-item .action-add > div {
  display: flex !important;
  align-items: center;
  justify-content: flex-start;
}
.group-members .details-item .action-add > div svg {
  margin: 0 20px !important;
}
.group-members .details-item .action-add > div span {
  font-size: 16px;
  text-transform: lowercase;
}
.group-members .details-item .action-add > div span:first-letter {
  text-transform: uppercase;
}
.group-members .group-member-list .list-actions {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
