.data-table-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #E5E5E5;
  border-bottom: none;
  border-radius: 4px;
}
.data-table-container .data-table-row {
  display: flex;
  font-size: 14px;
  font-weight: 500;
  border-bottom: 1px solid #E5E5E5;
  opacity: 0.7;
}
.data-table-container .data-table-row .data-table-field-name {
  font-weight: 700;
  width: 30%;
  padding: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.data-table-container .data-table-row .data-table-field-value {
  width: 70%;
  padding: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
