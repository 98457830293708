.add-token-dialog .add-token-content {
  flex-direction: column;
  display: flex;
}
.add-token-dialog .add-token-content .name, .add-token-dialog .add-token-content .description {
  width: 100% !important;
}
.add-token-dialog h3.dialog-title {
  background-color: #00aaed;
  color: #ffffff;
}
