.attachment-icon-loading {
  margin-left: 16px;
}

.assignment-attachment-icon:hover > div {
  background-color: #ededed;
}
.assignment-attachment-icon:hover .material-icon {
  color: #00aaed;
}
