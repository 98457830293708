.user-atributes-mapping {
  display: flex;
  align-items: center;
  margin-top: 25px;
}
.user-atributes-mapping.disabled {
  opacity: 0.25;
}
.user-atributes-mapping__user-prop-picker {
  display: flex;
  align-items: center;
  flex: 1;
  padding-left: 25px;
}
.user-atributes-mapping__ldap-prop-picker {
  display: flex;
  align-items: center;
  flex: 2;
  position: relative;
}
.user-atributes-mapping__person-icon {
  color: inherit !important;
  margin-right: 8px;
}
.user-atributes-mapping__delete-icon-container {
  position: absolute !important;
  right: 0;
}
.user-atributes-mapping__delete-icon-tooltip {
  position: absolute;
  right: 14px;
  display: flex;
}
.user-atributes-mapping:hover .user-atributes-mapping__delete-icon {
  fill: #736f77 !important;
}
.user-atributes-mapping .user-atributes-mapping__delete-icon {
  fill: #bbbec0 !important;
  cursor: pointer;
}
.user-atributes-mapping .user-atributes-mapping__delete-icon:hover {
  background-color: #ededed !important;
  fill: #dd2d03 !important;
  border-radius: 4px;
}
.user-atributes-mapping .field-control-skylightAttribute {
  margin-right: 34px;
}
.user-atributes-mapping .field-control-ldapAttribute {
  flex-grow: 1;
  margin-right: 50px;
}
.user-atributes-mapping .field-control-ldapAttribute .field > div {
  width: 100% !important;
}
