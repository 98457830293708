.add-application-participants .dialog-title {
  background-color: #00afee;
  color: #ffffff;
}
.add-application-participants .add-application-participants-body {
  min-height: 120px;
}
.add-application-participants .add-application-participants-body .add-application-participants-container {
  outline: 0;
}
.add-application-participants .add-application-participants-body .add-application-participants-container .label {
  font-size: 14px;
  color: #212121;
}
.add-application-participants .add-application-participants-body .people-picker-container .people-picker {
  margin-top: 10px;
  width: 100% !important;
}
.add-application-participants .add-application-participants-body .people-picker-container .people-picker .floating-label.float {
  display: none;
}
.add-application-participants .add-application-participants-body .people-picker-container .people-picker .chip-container .chip.user, .add-application-participants .add-application-participants-body .people-picker-container .people-picker .chip-container .chip.group {
  background-color: #e8e9e8 !important;
}
.add-application-participants .add-application-participants-body .people-picker-container .people-picker .chip-container .chip.user span, .add-application-participants .add-application-participants-body .people-picker-container .people-picker .chip-container .chip.group span {
  color: #414042;
}
.add-application-participants .add-application-participants-body .people-picker-container .people-picker .chip-container .chip.user span:hover, .add-application-participants .add-application-participants-body .people-picker-container .people-picker .chip-container .chip.group span:hover {
  text-decoration: none;
}
.add-application-participants .add-application-participants-body .role-picker-container .select {
  width: initial !important;
}
.add-application-participants .add-application-participants-body .role-picker-container .roles-picker {
  border-radius: 0;
  margin: 0;
  border-bottom: 1px solid #e0e0e0;
}
.add-application-participants .add-application-participants-body .people-picker-container, .add-application-participants .add-application-participants-body .role-picker-container {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
}
.add-application-participants .add-application-participants-body .people-picker-container .label, .add-application-participants .add-application-participants-body .role-picker-container .label {
  color: #736f77;
}

.application-participants-picker-popover .checkbox.group {
  font-weight: normal !important;
}
