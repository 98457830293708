.top-bar {
  position: sticky;
  top: 0;
  display: flex;
  background-color: #00aaed;
  z-index: 1201;
}
.top-bar.next-gen {
  background-color: #ffffff;
  border-bottom: 2px solid #ededed;
  position: absolute;
  z-index: 1300;
  width: 100%;
}
.top-bar.next-gen .main-toolbar {
  background-color: #ffffff !important;
}
.top-bar.next-gen .main-toolbar .toolbar-title-text span {
  color: #000 !important;
}
.top-bar .main-toolbar {
  background-color: #00aaed !important;
  width: 100%;
  padding: 0 6px !important;
  display: flex;
  justify-content: space-between;
}
.top-bar .main-toolbar .toolbar-title {
  overflow: initial !important;
  display: flex;
}
@media (max-width: 767px) {
  .top-bar .main-toolbar {
    display: inline-flex !important;
  }
}
.top-bar .main-toolbar .toolbar-title-section {
  display: flex;
  align-items: center;
}
.top-bar .main-toolbar .toolbar-title-section .skylight-logo-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.top-bar .main-toolbar .toolbar-title-section .toolbar-title-logo {
  height: 40px;
  margin-right: 6px;
}
.top-bar .main-toolbar .toolbar-component {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;
}
.top-bar .main-toolbar .toolbar-component .not-connected-button {
  background-color: #dd2c00;
  border-radius: 4px;
}
.top-bar .main-toolbar .toolbar-component .not-connected-button:hover {
  background-color: #C02319;
}
.top-bar .main-toolbar .toolbar-component .not-connected-button > button {
  height: 32px !important;
  width: 32px !important;
}
.top-bar .main-toolbar .toolbar-component .not-connected-button svg, .top-bar .main-toolbar .toolbar-component .not-connected-button path {
  color: #ffffff !important;
}
.top-bar span {
  color: #fff !important;
}
.top-bar .error-text {
  color: #fff !important;
  font-size: 16px !important;
}
