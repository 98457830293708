.recover-password__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-family: "Roboto", sans-serif;
}
.recover-password__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 310px;
}
.recover-password__content .skylight-logo {
  max-width: 100%;
  max-height: 100%;
  width: 140px;
}
.recover-password__content .divider {
  width: 85%;
  margin: 12px 0 12px 0 !important;
  background-color: rgba(65, 64, 66, 0.05) !important;
}

.recover-password-text {
  margin-top: 24px;
  font-size: 20px;
  font-family: Roboto Bold;
}
