.login {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  width: 310px;
}
@media (max-width: 767px) {
  .login {
    max-width: 80%;
  }
}
.login > .login-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 310px;
  margin-top: 21px;
}
.login > .loading-wrapper {
  width: 100%;
  height: 250px;
  max-width: 310px;
}
.login > .loading-wrapper .loading {
  width: 100%;
}
.login > .logo {
  max-width: 100%;
  max-height: 100%;
  width: 270px;
}

.login-container {
  box-sizing: border-box;
  height: 100%;
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}
@media (max-width: 767px) {
  .login-container {
    padding-top: 80px;
  }
}

.login-brand {
  color: rgba(8, 8, 8, 0.33);
  width: 100%;
  margin-top: auto;
  left: 0;
  text-align: center;
  font-size: 14px;
  font-family: "Roboto";
  padding-top: 16px;
  padding-bottom: 36px;
}
@media (max-width: 767px) {
  .login-brand {
    max-width: 80%;
    margin-left: auto;
    margin-right: auto;
  }
}
