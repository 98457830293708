.image-preview {
  width: 100%;
  position: relative;
}
.image-preview .preview-bar {
  position: absolute;
  z-index: 1;
  bottom: 0px;
  width: 100%;
  height: 40px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.image-preview .preview-bar svg {
  cursor: pointer;
}
.image-preview .preview-bar .name {
  color: #fff;
  padding-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.image-preview .media-details img {
  height: 200px;
}

.media .image-preview img {
  min-width: 150px;
  max-width: 80%;
  max-height: 80vh;
}

.media-details .image-preview img {
  height: 200px;
}
