.query-filter-dialog .dialog-title {
  background-color: #00aaed;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  font-family: "Roboto Bold", sans-serif;
  font-size: 20px;
}
.query-filter-dialog .dialog-subtitle {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #ffffff;
  line-height: 16px;
  margin-top: 12px;
}
.query-filter-dialog-body {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
}
.query-filter-dialog-body-tabs {
  background: #00aaed;
}
.query-filter-dialog-body-tabs > div {
  background: #00aaed !important;
  width: 354px !important;
}
.query-filter-dialog-body .footer-action-buttons {
  padding: 18px 24px;
}
.query-filter-dialog-body .footer-action-buttons .cancel-action {
  background-color: #ededed !important;
  margin-right: 8px !important;
}
.query-filter-dialog-body .footer-action-buttons .save-action {
  color: #ffffff !important;
  background-color: #00aaed !important;
}
.query-filter-dialog-body .footer-action-buttons .save-action:disabled {
  cursor: not-allowed !important;
  background-color: #ededed !important;
}
.query-filter-dialog-mapping-body {
  flex-grow: 1;
  overflow: hidden auto;
}
