.client-app-login .client-login-button {
  width: 100%;
  height: 15vh !important;
}
.client-app-login .client-login-button > button {
  height: inherit !important;
}
.client-app-login .client-login-button > button:enabled {
  background-color: #00aaed !important;
}
.client-app-login .client-login-button > button:enabled > div {
  height: inherit !important;
}
.client-app-login .client-login-button > button:enabled > div > div {
  height: inherit !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.client-app-login .client-login-button > button:enabled > div > div > svg {
  width: 48px !important;
  height: 48px !important;
  fill: #fff !important;
  overflow: visible !important;
  margin-left: 24px !important;
}
.client-app-login .client-login-button > button:enabled > div > div > span {
  padding: 0 !important;
}
.client-app-login .client-login-button > button:enabled > div > div > span > .app-login-text-area {
  line-height: normal !important;
}
.client-app-login .client-login-button > button:enabled > div > div > span > .app-login-text-area span {
  color: #fff;
  text-align: center;
  display: inline-block;
  width: 85%;
  font-weight: 600;
  text-transform: none !important;
}
.client-app-login .client-login-button > button:enabled > div > div > span > .app-login-text-area span:last-of-type {
  font-size: 0.8rem;
}
.client-app-login .client-login-button > button:enabled > div > div > span > .app-login-text-area span:first-of-type {
  font-size: 1.1rem;
  margin-bottom: 5px;
}
.client-app-login .client-login-button > button:disabled > div {
  height: inherit !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.client-app-login .client-login-button > button:disabled > div > svg {
  width: 48px !important;
  height: 48px !important;
  fill: #fff !important;
  overflow: visible !important;
  margin-left: 24px !important;
}
.client-app-login .client-login-button > button:disabled > div > span {
  padding: 0 !important;
}
.client-app-login .client-login-button > button:disabled > div > span > .app-login-text-area {
  line-height: normal !important;
}
.client-app-login .client-login-button > button:disabled > div > span > .app-login-text-area span {
  color: #fff;
  text-align: center;
  display: inline-block;
  width: 85%;
  font-weight: 600;
  text-transform: none !important;
}
.client-app-login .client-login-button > button:disabled > div > span > .app-login-text-area span:last-of-type {
  font-size: 0.8rem;
}
.client-app-login .client-login-button > button:disabled > div > span > .app-login-text-area span:first-of-type {
  font-size: 1.1rem;
  margin-bottom: 5px;
}
