.av-preview .av-preview-container {
  display: flex;
  flex-direction: column;
}
.av-preview .av-preview-container .audio-wrapper {
  width: stretch;
  min-width: 25vw;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000000;
}
.av-preview .av-preview-container .audio-wrapper video {
  height: 0;
}
.av-preview .av-preview-container .av-preview-error-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #cecece;
  border: 1px red solid;
  height: 170px;
}
.av-preview .av-preview-container .audio-wrapper svg, .av-preview .av-preview-container .av-preview-error-icon svg {
  width: 80% !important;
  height: 80% !important;
  color: #fcffff !important;
}
.av-preview .av-preview-container .playback-toolbar {
  position: relative;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.av-preview .av-preview-container .playback-toolbar progress {
  -webkit-appearance: initial;
  width: 100%;
  height: 8px;
  background-color: #716d75;
  cursor: pointer;
}
.av-preview .av-preview-container .playback-toolbar progress::-webkit-progress-value {
  background-color: #b7babb;
}
.av-preview .av-preview-container .playback-toolbar .playback {
  width: 100%;
  background-color: #00aaed;
  color: #fff;
  display: flex;
  justify-content: center;
}
.av-preview .av-preview-container .playback-toolbar .playback .preview-bar {
  position: absolute !important;
  right: 0;
}
