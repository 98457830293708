.group-membership {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  height: 100%;
  max-width: 350px;
  position: relative;
  line-height: 48px;
  display: flex;
  flex-direction: row;
}
.group-membership div.icon {
  padding: 0 28px 0 4px;
  display: flex;
  margin-left: auto;
  align-self: center;
}
.group-membership div.icon svg {
  color: #e0e0e0 !important;
}
.group-membership .menu-picker {
  margin-top: -2px;
}
.group-membership .menu-picker .groups-links {
  overflow-x: hidden;
}
.group-membership .menu-picker > div > button {
  top: 2px !important;
}
.group-membership .menu-picker > div > div:nth-of-type(2) {
  top: -2px !important;
}
.group-membership .menu-picker > div:hover > button svg {
  fill: #00aaed !important;
}

tr.selected .group-membership .menu-picker .groups-links span, tr.selected .group-membership .menu-picker .groups-links a {
  color: #fff !important;
}
