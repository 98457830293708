.session-event-data-dialog > div > div {
  max-width: none !important;
}
.session-event-data-dialog .session-event-data-content {
  flex-direction: column;
  display: flex;
}
.session-event-data-dialog .session-event-data-content .event-data {
  height: 100%;
  width: 100%;
  margin-top: 30px;
  overflow: hidden;
}
.session-event-data-dialog .session-event-data-content .event-data .show-copy-content-button {
  position: absolute !important;
  right: 20px !important;
}
.session-event-data-dialog .session-event-data-content .event-data .show-copy-content-button div svg {
  color: #bbbec0 !important;
}
.session-event-data-dialog .session-event-data-content .event-data .show-copy-content-button div svg:hover {
  background-color: #e5e4e5 !important;
  border-radius: 5px;
}
.session-event-data-dialog .session-event-data-content .event-data .event-data-container {
  display: flex;
  height: 100%;
  height: 350px;
  overflow-y: auto;
  margin: 0px 8px 0 21px;
}
.session-event-data-dialog .session-event-data-content .event-data .event-data-container::-webkit-scrollbar {
  width: 7px;
}
.session-event-data-dialog .session-event-data-content .event-data .event-data-container::-webkit-scrollbar-track {
  background: transparent;
}
.session-event-data-dialog .session-event-data-content .event-data .event-data-container::-webkit-scrollbar-thumb {
  background: #bbbec0;
  border-radius: 2px;
}
.session-event-data-dialog .session-event-data-content .event-data .event-data-container::-webkit-scrollbar-thumb:hover {
  background: #736f77;
}
.session-event-data-dialog .session-event-data-content .event-data .event-data-container .event-data-json {
  outline: 0;
  border: none;
  cursor: text;
  resize: none;
  overflow: visible;
  flex: 1;
  margin-left: 15px;
  font-size: 15px;
  line-height: 2;
  background-color: #f8f8f8;
  height: fit-content;
  margin: 0 8px 0 0;
  padding: 10px;
}
.session-event-data-dialog .session-event-data-content .event-data .event-data-container .event-data-line-numbers {
  margin: 0;
  padding: 10px 0 10px 16px;
  outline: 0;
  font-size: 15px;
  line-height: 2;
  height: fit-content;
  background-color: #f8f8f8;
  color: #bbbec0;
}
.session-event-data-dialog .session-event-dialog-title {
  display: flex;
  height: inherit;
  background-color: #00afee !important;
  color: #ffffff !important;
  align-items: center;
}
.session-event-data-dialog .session-event-dialog-title .dialog-icon {
  margin-right: 8px;
}
.session-event-data-dialog .dialog-modal-body {
  padding: 0 0 24px 0 !important;
}
