.application-table tr th.actions {
  overflow: visible;
}
.application-table tr td.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.application-table tr td.actions > button {
  width: 32px !important;
  height: 32px !important;
  padding: 4px !important;
}
.application-table tr.error .chk, .application-table tr.loading .chk {
  display: none !important;
}
.application-table tr.error .hover-checkbox-column, .application-table tr.loading .hover-checkbox-column {
  overflow: unset;
}
.application-table tr .error > div:nth-child(2) {
  top: 90px !important;
  right: -120px !important;
}
.application-table tr .loading > div:nth-child(2) {
  top: 50px !important;
  right: -50px !important;
}
