.netscan-dialog > div > div {
  transform: translate(0, 0) !important;
}
.netscan-dialog .dialog-title {
  padding: 14px 15px 14px !important;
}
.netscan-dialog .dialog-title + div {
  padding: 0px 16px 16px !important;
}
.netscan-dialog .netscan > .title {
  font-size: 18px;
  color: #212121;
  text-align: left;
  line-height: 20px;
}
.netscan-dialog .netscan > .description {
  font-size: 14px;
  color: rgba(33, 33, 33, 0.66);
  text-align: left;
}
.netscan-dialog .netscan .items {
  box-shadow: 0 0 1px 0 rgba(33, 33, 33, 0.5);
  border-radius: 4px;
  margin-top: 10px;
}
.netscan-dialog .netscan .items .metric {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 7px;
}
.netscan-dialog .netscan .items .metric .status {
  width: 24px;
  margin-left: 19px;
  margin-right: 16px;
}
.netscan-dialog .netscan .items .metric .status svg {
  color: #00B355 !important;
}
.netscan-dialog .netscan .items .metric .status.error svg {
  color: #DD2C00 !important;
}
.netscan-dialog .netscan .items .metric .title {
  flex: 1;
  line-height: 24px;
}
.netscan-dialog .netscan .items .metric .title.error {
  color: #DD2C00;
  font-weight: bold;
}
.netscan-dialog .netscan .items .metric .value {
  line-height: 24px;
  width: 111px;
  text-align: right;
  margin-right: 15px;
}
.netscan-dialog .netscan .mqtt-status {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  font-size: 14px;
  align-items: center;
  height: 40px;
}
.netscan-dialog .netscan .mqtt-status .progress {
  margin-right: 12px;
}
.netscan-dialog .netscan .mqtt-status.error {
  color: #CE2400 !important;
}
