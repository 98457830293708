.collapse-container {
  position: relative;
}
.collapse-container .collapse-header {
  display: flex;
  cursor: pointer;
}
.collapse-container .collapse-header .collapse-icon {
  display: flex;
  align-items: center;
}
.collapse-container .collapse-header .collapse-header-text {
  display: flex;
  align-items: center;
  user-select: none;
  margin-left: 15px;
}
.collapse-container .collapse-content {
  overflow: hidden;
  max-height: 0;
}
.collapse-container .collapse-content.open {
  max-height: 1000px;
}
