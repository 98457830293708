.media-grid {
  overflow-y: scroll;
}
.media-grid .grid-view {
  overflow-x: hidden;
}
.media-grid .grid-view .table-heading {
  top: 0px;
  box-shadow: -5px -5px 0px 0px #fff;
}
@media (max-width: 767px) {
  .media-grid .grid-view .table-heading {
    position: fixed;
    width: 90%;
    top: 61px;
    background-color: #fff;
    z-index: 10;
  }
}
.media-grid .grid-view .table-heading .media-grid-filter svg {
  fill: #bbbec0 !important;
}
.media-grid .grid-view .grid-list {
  position: relative;
}
@media (max-width: 767px) {
  .media-grid .grid-view .grid-list {
    top: 61px;
    display: grid !important;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    justify-items: center;
  }
  .media-grid .grid-view .grid-list .subheader {
    grid-column: 1/-1;
  }
}
.media-grid .grid-view .grid-list > div:not(.subheader) {
  width: 150px !important;
  height: 150px !important;
  margin: 2px 0;
}
.media-grid .grid-view .grid-list .subheader {
  padding-left: 0px !important;
}
.media-grid .grid-view .media-grid-item.has-tooltip > div:last-child {
  right: 10% !important;
  top: 5% !important;
}
