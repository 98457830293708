.home-page-next-gen {
  display: flex;
  flex-direction: column;
  padding: 25px;
  overflow: auto;
  height: calc(100vh - 81px);
}
.home-page-next-gen .title-messages {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  margin-left: 5px;
}
.home-page-next-gen .title-messages .title {
  padding: 0 0 5px 0;
  color: #00aaed;
  font-size: 32px;
  font-family: "Source Sans Pro";
  line-height: 22px;
  font-weight: 100;
}
.home-page-next-gen .title-messages .tagline {
  padding: 5px 0 0 0;
  font-size: 20px;
  color: #8f9295;
  line-height: 22px;
}
.home-page-next-gen .client-app-login {
  width: 85%;
  margin-bottom: 30px;
}
