.form-section {
  display: flex;
  flex-direction: column;
}
.form-section .form-section-body {
  display: flex;
}
.form-section .form-section-title {
  margin: 10px 0 5px 0;
}
.form-section .form-section-actions {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.form-section .form-section-actions .user-image {
  width: 67px;
  height: 67px;
  margin: 8px 0;
}
.form-section .form-section-actions .user-image img {
  border-radius: 4px;
}
.form-section .form-fields {
  display: flex;
  flex-direction: column;
  flex: 5;
}
.form-section .form-fields .form-fields-row {
  display: flex;
}
.form-section .form-fields .form-field {
  display: flex;
  flex: 1;
  margin: 4px 2px;
}
.form-section .form-fields .form-field .outlined-text-field-container, .form-section .form-fields .form-field .outlined-select-field-container {
  flex: 1;
}
@media (max-width: 767px) {
  .form-section .form-fields-row {
    flex-direction: column;
  }
  .form-section .form-section-actions {
    margin-right: 10px;
  }
}
