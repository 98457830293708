.call .call-video video {
  width: 100%;
  height: 100%;
}
.call .call-video .no-video {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.call .call-video.full .no-video {
  width: 128px !important;
  height: 128px !important;
}
.call .call-video.preview {
  z-index: 2;
}
