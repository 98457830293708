.attributes-picker {
  align-items: center;
  display: flex;
}
.attributes-picker > span {
  color: rgba(33, 33, 33, 0.33);
  margin-right: 7px;
}
.attributes-picker-icon {
  color: #736f77 !important;
}
.attributes-picker-icon:hover {
  color: #00aaed !important;
  background-color: #ededed;
  border-radius: 4px;
}
