.user-attributes-dialog .dialog-title {
  background-color: #00aaed;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  font-family: "Roboto Bold", sans-serif;
  font-size: 20px;
}
.user-attributes-dialog .dialog-subtitle {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: #ffffff;
}
.user-attributes-dialog-body {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
}
.user-attributes-dialog-body .footer-action-buttons {
  padding: 18px 24px;
}
.user-attributes-dialog-body .footer-action-buttons .cancel-action {
  background-color: #ededed !important;
  margin-right: 8px !important;
}
.user-attributes-dialog-body .footer-action-buttons .save-action {
  color: #ffffff !important;
  background-color: #00aaed !important;
}
.user-attributes-dialog-body .footer-action-buttons .save-action:disabled {
  cursor: not-allowed !important;
  background-color: #ededed !important;
}

.user-attributes-body {
  flex-grow: 1;
  overflow: hidden auto;
}
