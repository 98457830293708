.new-user-content {
  width: 90% !important;
}
.new-user-content > div > div {
  padding: 0 !important;
}
.new-user-content .dialog-title {
  border-bottom: 1px solid #ededed;
  padding: 15px 24px 5px;
  font-size: 21px;
}
.new-user-content .new-user-container {
  padding: 15px;
  max-height: 436px;
  overflow-y: auto;
}
.new-user-content .actions-container {
  border-top: 1px solid #ededed;
  padding: 5px !important;
}
