.snackbar-incompatible-version {
  background-color: #CF0234 !important;
  width: auto;
  border-radius: 4px 4px 0 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  left: 0 !important;
  max-width: 740px;
  margin: 0 auto !important;
  justify-content: space-around;
  box-sizing: border-box;
  padding-right: 5px;
  padding-left: 5px;
}
