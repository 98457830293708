.call .media-gallery {
  width: 100%;
  height: 100%;
  display: flex;
  align-content: flex-start;
  background: #fff;
  flex-wrap: wrap;
  overflow-y: scroll;
  padding: 2px;
}
.call .media-gallery .media-gallery-item {
  padding-top: 50%;
  padding: 0px;
}
.call .media-gallery .media-gallery-item .content {
  display: flex;
  cursor: pointer;
  padding: 2px;
}
.call .media-gallery .media-gallery-item .content .media-editor {
  width: 200px;
  height: 200px;
}
.call .media-gallery .media-gallery-item .content img {
  width: 200px;
  height: 200px;
  object-fit: cover;
}
.call .media-gallery .no-items {
  margin: auto;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #bbbec0;
}
.call .media-gallery .no-items svg {
  color: #bbbec0 !important;
  margin-bottom: 5px;
}
.call .media-gallery input.upload-button {
  display: none;
}
.call .media-gallery .upload.fab {
  position: absolute;
  bottom: 32px;
  right: 32px;
}
