.call-user-list .user-list-icon-column {
  width: 24px;
}
.call-user-list tr td {
  color: rgba(33, 33, 33, 0.66);
}
.call-user-list tr td.status-icon {
  text-transform: lowercase;
}
.call-user-list tr td.actions .info {
  cursor: pointer;
}
@media (max-width: 1024px) {
  .call-user-list tr td.actions .info {
    color: rgba(33, 33, 33, 0.66) !important;
  }
}
.call-user-list tr.online-user {
  cursor: pointer;
  font-weight: 900;
}
.call-user-list tr.online-user td {
  color: #212121;
}
.call-user-list tr.external-user {
  opacity: 0.5;
}
.call-user-list tr svg.call {
  fill: #00AAED !important;
}
.call-user-list .action-button {
  padding: 2px !important;
}
.call-user-list .action-button.icon {
  margin-left: 0 4px !important;
  padding-bottom: 0px !important;
  border-radius: 4px !important;
}
.call-user-list .action-button.icon:hover {
  background-color: #ecebec !important;
}
.call-user-list .action-button.icon.skylight-icon {
  margin: 0 2px !important;
  padding: 5px;
  padding-bottom: 3px;
}
