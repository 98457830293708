.outlined-menu-picker {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  height: 100%;
  max-width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.outlined-menu-picker fieldset {
  height: 56px;
  padding-bottom: 4px;
}
.outlined-menu-picker .underline-text {
  height: 0;
}
.outlined-menu-picker.disabled {
  pointer-events: none;
}
.outlined-menu-picker .menu-picker {
  background-color: #fff;
  height: 100% !important;
  border-radius: 5px;
  margin-right: 5px;
  min-width: 95%;
}
.outlined-menu-picker .menu-picker > div > button {
  top: 2px !important;
}
.outlined-menu-picker .menu-picker > div > div:nth-of-type(2) {
  padding: 0 !important;
  height: 100% !important;
  display: flex;
  align-items: center;
}
.outlined-menu-picker .menu-picker button {
  right: 0 !important;
  width: 30px !important;
  height: 35px !important;
  border-radius: 0 3px 3px 0;
  padding: 0px 25px 0 5px !important;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.8) 20%, white 50%) !important;
  fill: #736f77 !important;
  transition: none !important;
}
.outlined-menu-picker .menu-picker .items-picker-arrow {
  height: 20px !important;
  width: 20px !important;
}
.outlined-menu-picker .selected-items {
  display: flex;
  height: 42px;
}
.outlined-menu-picker .selected-items .selected-item {
  margin: 3px 0px 3px 3px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 0 0 6px;
}
.outlined-menu-picker .selected-items .selected-item svg {
  fill: #bbbec0 !important;
}
.outlined-menu-picker .selected-items .selected-item .details-link a {
  color: #bbbec0 !important;
}
.outlined-menu-picker div.icon {
  padding: 0 28px 0 4px;
  display: flex;
  margin-left: auto;
  align-self: center;
}
.outlined-menu-picker div.icon svg {
  color: #414042 !important;
}
.outlined-menu-picker .selection-hint {
  position: absolute;
  top: 24px;
  font-size: 16px;
  opacity: 0.33;
  color: #000;
}
