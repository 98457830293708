.members-filter {
  position: relative;
  cursor: pointer;
}
.members-filter .filter-icon {
  color: #333 !important;
}
.members-filter .filter-icon:hover {
  color: #00aaed !important;
}
