.snackbar-error-archiving-application {
  width: 310px;
}
.snackbar-error-archiving-application .warning-icon {
  margin-top: 5px;
  margin-left: 15px;
}
.snackbar-error-archiving-application .warning-icon svg {
  color: #FFD000 !important;
}
.snackbar-error-archiving-application .snackbar-message {
  margin-left: 10px;
}
.snackbar-error-archiving-application .action-button {
  position: absolute;
  right: 5px;
}
