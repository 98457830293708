.participant-list .table-heading .actions {
  height: 64px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  right: 20px;
}
.participant-list .table-heading .actions .manage-roles-button {
  width: max-content;
}
.participant-list .participant-list-title {
  color: #000;
  font-weight: 700;
}
.participant-list .participant-table {
  overflow: hidden;
}
.participant-list .participant-table .table-footer {
  z-index: 1;
}
.participant-list .nav-link {
  color: #414042;
  text-decoration: none;
}
.participant-list .nav-link:hover {
  text-decoration: underline;
}
.participant-list .list-action-button {
  transition: bottom 300ms ease-in-out;
}
.participant-list .list-action-button.item-selected {
  bottom: 70px !important;
}
@media (max-width: 767px) {
  .participant-list .table-heading {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    height: unset;
  }
  .participant-list .table-heading .actions {
    justify-content: flex-start;
    width: 100%;
  }
  .participant-list .participant-list-title {
    margin-bottom: 10px;
  }
}

.confirmation-dialog.remove-application-participants-modal .remove-application-participants-modal-title {
  background-color: #dd2c00;
  color: #ffffff !important;
  font-size: 20px;
  display: flex;
  align-items: center;
}
.confirmation-dialog.remove-application-participants-modal .remove-application-participants-modal-title > svg {
  margin-right: 10px;
  color: #ffffff !important;
  fill: #ffffff !important;
  height: 30px !important;
  width: 30px !important;
}
.confirmation-dialog.remove-application-participants-modal div > button:last-child span {
  color: #dd2c00;
}
.confirmation-dialog.remove-application-participants-modal .body {
  padding: 10px 24px 24px !important;
}
