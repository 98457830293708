.login-large-button {
  height: 56px;
  box-shadow: none !important;
  border-radius: 4px !important;
}
.login-large-button button {
  height: 56px !important;
  border-radius: 4px !important;
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.login-large-button button div > div {
  height: 56px !important;
}
.login-large-button.primary button {
  border: 10px !important;
}
.login-large-button.primary .login-large-button-content span {
  color: #fff;
}

.login-large-button-content {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
}
.login-large-button-content span {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.66);
}
.login-large-button-content svg {
  position: absolute;
  left: 18px;
}

.login-large-button-label {
  display: flex;
  width: 65%;
  align-items: center;
  justify-content: center;
  line-height: 16px;
}
