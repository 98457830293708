.dashboard-action-button {
  border: 1px solid #736f77;
  border-radius: 5px;
  display: flex;
  color: #736f77;
  font-family: Roboto, sans-serif;
  background-color: unset;
  font-size: unset;
  padding: 10px 20px;
  text-transform: uppercase;
}
.dashboard-action-button:not(:disabled) {
  cursor: pointer;
}
.dashboard-action-button:not(:disabled):hover {
  border: 1px solid #00afee;
  color: #00afee;
}
.dashboard-action-button:disabled {
  background-color: #fcfcfc;
  border: 1px solid #bbbec0;
  color: #bbbec0;
  cursor: not-allowed;
}
