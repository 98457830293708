.call .call-footer .footer-buttons {
  flex-direction: row;
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  margin-bottom: 20px;
  border-radius: 8px;
}
.call .call-footer .footer-buttons .call-button {
  margin: 4px 2px;
}
.call .call-footer .footer-buttons .call-button:first-child {
  margin-left: 4px;
}
.call .call-footer .footer-buttons .call-button:last-child {
  margin-right: 4px;
}
.call .call-footer .footer-buttons .call-button.call-fab-tooltip button {
  background-color: rgba(0, 0, 0, 0.9) !important;
  border-radius: 8px !important;
  width: 56px !important;
  height: 60px !important;
}
.call .call-footer .footer-buttons .call-button.call-fab-tooltip button:hover:after {
  content: "";
  background-color: rgba(255, 255, 255, 0.1) !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.call .call-footer .footer-buttons .call-button.call-fab-tooltip button:disabled > svg {
  fill: rgba(33, 33, 33, 0.5) !important;
}
.call .call-footer .footer-buttons .call-button.call-fab-tooltip.unmute > button {
  background-color: #fff !important;
}
.call .call-footer .footer-buttons .call-button.call-fab-tooltip.unmute > button:hover:after {
  content: "";
  background-color: rgba(255, 255, 255, 0.1) !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.call .call-footer .footer-buttons .call-button.call-fab-tooltip.unmute > button svg {
  fill: #000 !important;
}
.call .call-footer .footer-buttons .call-button.call-fab-tooltip.snapshot button {
  width: 64px !important;
}
.call .call-footer .footer-buttons .call-button.call-fab-tooltip.message button > div > div {
  left: 0 !important;
}
.call .call-footer .footer-buttons .call-button.call-fab-tooltip.mute button > div > div {
  left: 8px !important;
}
.call .call-footer .footer-buttons .call-button.call-fab-tooltip.unmute button > div > div, .call .call-footer .footer-buttons .call-button.call-fab-tooltip.snapshot button > div > div {
  left: 2px !important;
}
.call .call-footer .footer-buttons .call-button.call-fab-tooltip.media button > div > div {
  left: -4px !important;
}
.call .call-footer .footer-buttons .call-button.call-fab-tooltip.reject button > div > div {
  left: 2px !important;
}
.call .call-footer .footer-buttons .call-button.icon .label {
  margin-top: 0px;
  font-size: 12px;
}
.call .call-footer .footer-buttons .call-button.reject button {
  background-color: #f32d26 !important;
}
.call .call-footer .footer-buttons .call-button.reject button:hover:after {
  content: "";
  background-color: rgba(255, 255, 255, 0.1) !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.call .call-footer .footer-buttons .call-button .label {
  color: #fff;
  text-shadow: 0px 1px rgba(33, 33, 33, 0.5) !important;
}
