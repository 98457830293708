.redirect {
  display: flex;
  flex-direction: column;
  width: fit-content;
  margin: 100px auto;
  align-items: center;
}
.redirect span {
  font-family: "Roboto";
  color: #736f77;
  margin: 20px;
}
.redirect.error > span {
  color: #f44336 !important;
}
.redirect .redirect-loading {
  width: 250px !important;
  align-self: center;
}
.redirect a {
  text-decoration: none;
  margin-top: 24px;
}
