.top-bar-action-button {
  height: 40px;
  width: 40px;
  margin: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
}
.top-bar-action-button:hover {
  background-color: rgba(115, 111, 119, 0.2);
}
.top-bar-action-button.active {
  background-color: rgba(115, 111, 119, 0.4);
}
.top-bar-action-button .cursor-tooltip {
  display: flex;
}
.top-bar-action-button .cursor-tooltip button {
  padding: 4px !important;
}

.next-gen .top-bar-action-button svg {
  fill: #736f77 !important;
}
