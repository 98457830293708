.local-service-form {
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
}
.local-service-form .delete-ice-server-button {
  display: none;
}
.local-service-form:hover {
  background-color: #fafafa;
}
.local-service-form:hover .delete-ice-server-button {
  display: block;
}
.local-service-form .ice-server-row {
  display: flex;
  flex-direction: row;
}
.local-service-form .ice-server-row .remove-button-column {
  display: flex;
  flex-basis: 60px;
  align-self: center;
}
.local-service-form .ice-server-row .server-fields-column {
  display: flex;
  flex-direction: column;
  flex: auto;
  padding-right: 60px;
}
.local-service-form .autocomplete {
  width: 0px;
  height: 0px;
  position: absolute;
  z-index: -1;
  left: -1000px;
}
.local-service-form .uppercase-label .required-label span {
  text-transform: uppercase;
}
