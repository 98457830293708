.call .call-media-panel {
  position: relative;
  display: flex;
  flex-direction: column;
}
.call .call-media-panel .header {
  z-index: 1;
  width: 100%;
  background-color: #00aaed;
  height: 64px;
  top: 0;
  display: flex;
  align-items: center;
}
.call .call-media-panel .header.has-item {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
}
.call .call-media-panel .header button svg {
  color: #fff !important;
}
.call .call-media-panel .media-gallery {
  height: inherit;
  flex-grow: 1;
}
.call .call-media-panel > img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
