.call-reconnecting {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
}
.call-reconnecting > span {
  font-size: 24px;
  margin-bottom: 10px;
}
