.navigation-controller-wrapper {
  position: fixed;
  height: 100%;
  width: 72px;
  left: 0;
  display: flex;
  justify-content: center;
  border-right: 1px solid rgba(65, 64, 66, 0.1);
  z-index: 100;
}
.navigation-controller-wrapper .hamburger-button {
  border-radius: 4px;
  margin-top: 10px !important;
  width: 40px !important;
  height: 40px !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.navigation-controller-wrapper .hamburger-button svg {
  color: rgba(65, 65, 66, 0.6) !important;
}
.navigation-controller-wrapper .hamburger-button > div > div {
  pointer-events: none;
}
.navigation-controller-wrapper .hamburger-button:hover {
  background-color: rgba(65, 64, 66, 0.1) !important;
}
