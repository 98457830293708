.snackbars .snackbar.snackbar-message {
  display: block;
  height: auto;
  right: 0;
  left: 0;
  width: auto;
  background-color: transparent;
  box-shadow: none;
  text-align: center;
}

.snackbar-message__container {
  display: inline-block;
  box-shadow: 0 0 9px 0 rgba(33, 33, 33, 0.5);
  border-radius: 5px;
  padding: 16px;
  max-width: calc(100vw - 48px);
}
.snackbar-message--success .snackbar-message__container {
  background-color: #3c7811;
}
.snackbar-message--error .snackbar-message__container {
  background-color: #f44336;
}
