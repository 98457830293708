.assignment-decision-tile {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.assignment-decision-tile .decision-tile-label {
  font-family: "Roboto Italic";
  padding: 10px 0;
  font-size: 13px;
  color: #212121;
}
.assignment-decision-tile .decision-tile-choices {
  display: flex;
  flex-direction: column;
}
.assignment-decision-tile .decision-tile-choices .decision-tile-choice {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.assignment-decision-tile .decision-tile-choices .decision-tile-choice > .decision-tile-icon {
  margin-right: 10px;
}
.assignment-decision-tile .decision-tile-choices .decision-tile-choice > .decision-tile-icon.decision-tile-icon-check {
  color: #00b355 !important;
}
.assignment-decision-tile .decision-tile-choices .decision-tile-choice > .decision-tile-icon.decision-tile-icon-uncheck {
  color: rgba(0, 0, 0, 0.2) !important;
}
.assignment-decision-tile .decision-tile-choices .decision-tile-choice .decision-tile-answer {
  font-size: 13px;
  color: #212121;
}
.assignment-decision-tile .decision-tile-choices .decision-tile-choice.uncheck .decision-tile-answer {
  opacity: 0.4;
}
.assignment-decision-tile .decision-tile-choices .decision-tile-choice.decision-tile-choice-empty > svg, .assignment-decision-tile .decision-tile-choices .decision-tile-choice.decision-tile-choice-empty > span {
  color: rgba(33, 33, 33, 0.33) !important;
}
.assignment-decision-tile .decision-tile-choices .decision-tile-choice.decision-tile-choice-empty > span {
  font-size: 13px;
  margin-left: 10px;
}
