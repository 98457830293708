.app-dashboard-breadcrumbs {
  position: sticky;
  top: 0;
  display: flex;
  line-height: 15px;
}
.app-dashboard-breadcrumbs > .breadcrumbs > .common-tooltip > .breadcrumbs-item {
  font-size: 14px;
  opacity: 0.8;
  color: #000;
}
.app-dashboard-breadcrumbs > .breadcrumbs > .common-tooltip > .breadcrumbs-item:hover {
  opacity: 1 !important;
}
.app-dashboard-breadcrumbs > .breadcrumbs > .common-tooltip > .breadcrumbs-item a {
  color: #414042;
  padding: 1px;
}
.app-dashboard-breadcrumbs > .breadcrumbs > .common-tooltip > .breadcrumbs-item a span {
  color: #414042;
}
