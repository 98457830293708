.next-gen-application-table .application-row.archived {
  opacity: 0.5;
}
.next-gen-application-table .application-row .hover-checkbox-column {
  text-align: center;
  font-size: 23px;
}
.next-gen-application-table .empty-label-container {
  margin-top: 0;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.next-gen-application-table .hover-checkbox-column .failed-import-icon {
  fill: #dd2c00 !important;
}
.next-gen-application-table tr:not(.selected):hover td.actions .application-import-delete svg {
  color: #dd2c00 !important;
}
.next-gen-application-table tr:not(.selected):hover td.actions .application-import-delete svg:hover {
  color: #dd2c00 !important;
}
