.call-user-action-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-bottom: 15px;
}
.call-user-action-buttons .button-wrapper {
  width: 85%;
}
