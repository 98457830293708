.login .missing-account {
  display: flex;
  flex-direction: column;
}
.login .missing-account h3 {
  margin-bottom: 48px;
}
.login .missing-account .no-matching-account {
  margin-bottom: 32px;
}
