.mapping-row {
  display: flex;
  align-items: center;
  margin-top: 25px;
}
.mapping-row .row-icon {
  color: inherit !important;
  margin-right: 8px;
}
.mapping-row.disabled {
  opacity: 0.25;
}
.mapping-row__role-picker, .mapping-row__group-picker {
  display: flex;
  align-items: center;
  flex: 1;
  padding-left: 25px;
}
.mapping-row__filter-picker {
  display: flex;
  align-items: center;
  flex: 2;
  position: relative;
}
.mapping-row__delete-button {
  padding: 0 !important;
}
.mapping-row__delete-icon-tooltip {
  position: absolute;
  right: 14px;
  display: flex;
}
.mapping-row:hover .mapping-row__delete-icon {
  fill: #736f77 !important;
}
.mapping-row .mapping-row__delete-icon {
  fill: #bbbec0 !important;
  cursor: pointer;
}
.mapping-row .mapping-row__delete-icon:hover {
  fill: #dd2d03 !important;
  background-color: #ededed !important;
  border-radius: 4px;
}
.mapping-row .field-control-filter {
  margin-right: 50px;
  flex-grow: 1;
}
.mapping-row .field-control-filter .field > div {
  width: 100% !important;
}
