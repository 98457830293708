.user-action-buttons.updated {
  box-shadow: 0 0 5px 0 rgba(33, 33, 33, 0.5);
}
.user-action-buttons.mobile {
  margin-top: 45px;
}
.user-action-buttons.mobile.updated {
  box-shadow: 0 0 5px 0 rgba(33, 33, 33, 0.5);
}
.user-action-buttons.mobile.updated .user-action-menu {
  bottom: 30px;
}
