.application-tile {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #fff;
  border-radius: 3px;
  cursor: pointer;
}
.application-tile:hover {
  box-shadow: 0 0 9px 1px rgba(33, 33, 33, 0.5);
}
.application-tile.selected, .application-tile.multi-selected {
  box-shadow: 0 0 0 2px #00aaed, 0 0 11px 1px rgba(33, 33, 33, 0.5);
}
.application-tile.selected.locked, .application-tile.multi-selected.locked {
  box-shadow: 0 0 0 2px #FF9800, 0 0 11px 1px rgba(33, 33, 33, 0.5);
}
.application-tile.selected.locked .lock-icon, .application-tile.multi-selected.locked .lock-icon {
  color: #FF9800 !important;
}
.application-tile .content {
  flex: 3;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 5px;
  user-select: none;
}
.application-tile .content .icon {
  fill: #736F77 !important;
  width: 48px !important;
  height: 48px !important;
}
.application-tile .content .lock-icon-wrapper > div:last-child[style] {
  right: initial !important;
  left: 0 !important;
}
.application-tile .content .lock-icon {
  color: rgba(33, 33, 33, 0.35) !important;
  position: absolute;
  top: 15px;
  left: 15px;
}
.application-tile .content .common-tooltip {
  justify-content: center;
  z-index: 4;
}
.application-tile .content .common-tooltip > div:nth-child(2) {
  top: 0 !important;
  right: 25px !important;
  border-radius: 4px !important;
}
.application-tile .content .common-tooltip .name {
  font-family: "Roboto Bold";
  font-size: 14px;
  color: #212121;
  max-width: -webkit-fill-available;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  /* autoprefixer: off */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  text-align: center;
}
.application-tile .content .details {
  font-size: 13px;
  text-align: center;
  width: 100%;
}
.application-tile .content .details .creator {
  color: #414042;
}
.application-tile .content .details .time {
  color: rgba(33, 33, 33, 0.66);
  font-family: "Roboto";
}
.application-tile .actions {
  flex: 1;
  display: flex;
  justify-content: center;
}
.application-tile .actions > button {
  display: none !important;
}
.application-tile .actions > button .material-icon {
  color: #736F77 !important;
}
.application-tile:hover .actions, .application-tile.selected .actions {
  box-shadow: 0px -1px 0px 0px #ededed;
}
.application-tile:hover .actions > button, .application-tile.selected .actions > button {
  display: initial !important;
}
.application-tile.multi-selected .actions {
  box-shadow: unset;
}
.application-tile.multi-selected .actions > button {
  display: none !important;
}
