.setup-new-app .dialog-title {
  background-color: #00aaed;
  color: #fff !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.setup-new-app .dialog-title .tabs-counter {
  font-size: 13px;
  opacity: 0.6;
}
.setup-new-app .steps {
  margin-left: auto;
  color: #a1dff7;
  font-size: 14px !important;
}
.setup-new-app .dialog-body.importing {
  background-color: rgba(65, 64, 66, 0.05) !important;
}
