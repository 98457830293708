.verify-credentials-dialog .dialog-content-wrapper {
  width: 420px !important;
}
.verify-credentials-dialog .loader-wrapper {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 48px);
  height: calc(100% - 135px) !important;
}
.verify-credentials-dialog .content {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.verify-credentials-dialog .content div {
  width: 100% !important;
  text-align: center;
}
.verify-credentials-dialog .content .dialog-form-fields {
  width: 100% !important;
}
.verify-credentials-dialog .content .verify-dialog.input {
  width: 100% !important;
}
.verify-credentials-dialog .content .verify-dialog.error-message {
  text-align: left;
  display: flex;
  justify-content: flex-start;
  padding: 3px 0px;
  font-size: 14px;
}
.verify-credentials-dialog .content .verify-dialog.input.username.disabled {
  opacity: 0.8;
  pointer-events: none;
}
.verify-credentials-dialog .content .verify-dialog.input.username.disabled input {
  -webkit-text-fill-color: rgba(0, 0, 0, 0.3) !important;
}
.verify-credentials-dialog .content .dialog-actions-wrapper {
  margin: 40px 0 20px;
  width: 100%;
}
.verify-credentials-dialog .content .dialog-actions-wrapper .verify-button {
  width: 100% !important;
}
.verify-credentials-dialog .content .dialog-footer-wrapper .footer-text {
  font-size: 14px;
  color: #b5b5b5;
}
.verify-credentials-dialog .content .dialog-footer-wrapper a.nav-link {
  color: #00aaed !important;
  text-decoration: none;
  font-weight: bold;
}
.verify-credentials-dialog .content .dialog-footer-wrapper a.nav-link:hover {
  cursor: pointer;
  text-decoration: underline;
}
.verify-credentials-dialog .close-icon-wrapper {
  position: absolute;
  top: 15px;
  right: 15px;
}
.verify-credentials-dialog .close-icon-wrapper .close-icon {
  fill: #79777a !important;
}
.verify-credentials-dialog .close-icon-wrapper .close-icon:hover {
  fill: #736f77 !important;
  cursor: pointer;
}

.dialog-dimmed {
  pointer-events: none;
}
.dialog-dimmed .dialog-content-wrapper .dialog-body-wrapper {
  opacity: 0.5;
}
.dialog-dimmed .dialog-title {
  opacity: 0.3;
}

@media (max-width: 767px) {
  .verify-credentials-dialog .dialog-content-wrapper {
    transform: unset !important;
    position: fixed !important;
    max-width: unset !important;
    width: 100% !important;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }
  .verify-credentials-dialog .dialog-content-wrapper div:first-child {
    height: 100%;
  }
  .verify-credentials-dialog .dialog-content-wrapper div:first-child .dialog-title {
    font-size: 20px !important;
  }
}
