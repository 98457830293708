.admin-tabs {
  font-family: "Roboto";
}
.admin-tabs .dashboard-tabs > div:first-child {
  position: sticky;
  top: 56px;
  z-index: 2;
  box-shadow: 0 5px 5px -6px #333;
}
.admin-tabs .dashboard-tabs > div:nth-child(2) {
  position: sticky;
  top: 104px;
  z-index: 2;
}
.admin-tabs .title {
  font-size: 20px;
  color: rgba(33, 33, 33, 0.66);
  line-height: 22px;
  opacity: 0.66;
  padding: 10px 0;
}
.admin-tabs .section-title {
  font-size: 20px;
  color: rgba(33, 33, 33, 0.66);
  line-height: 22px;
  opacity: 0.66;
  padding: 10px 0;
  font-size: 18px;
  padding: 10px 15px;
  border-bottom: 1px solid #ededed;
}
.admin-tabs .description {
  font-size: 14px;
  color: rgba(33, 33, 33, 0.33);
}
.admin-tabs .token-settings-input-field input::-webkit-outer-spin-button,
.admin-tabs .token-settings-input-field input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.admin-tabs .admin-radio-group > div {
  box-shadow: inset 0 -1px 0 0 #ededed;
}
.admin-tabs .admin-radio-group > div > div {
  padding-top: 33px;
}
.admin-tabs .admin-radio-group > div > div > div svg:first-child {
  fill: rgba(33, 33, 33, 0.33) !important;
}
.admin-tabs .admin-radio-group > div > div label {
  padding-bottom: 22px;
}
.admin-tabs .admin-radio-group > div > div label span {
  font-size: 14px;
  color: rgba(33, 33, 33, 0.33);
}
.admin-tabs .admin-tab-content {
  height: calc(100vh - 144px);
  overflow: auto;
}
.admin-tabs .footer-action-buttons {
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: #fff;
  z-index: 2;
}
.admin-tabs .footer-action-buttons .cancel-action {
  color: #736f77 !important;
}
