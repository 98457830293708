.add-connect-app-dialog .add-connect-app-dialog-content.filter-disabled .application-text-filter {
  opacity: 0.7;
  pointer-events: none;
}
.add-connect-app-dialog .dialog-title {
  background-color: #00aaed;
  color: #fff !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.add-connect-app-dialog .add-connect-app-dialog-body {
  display: flex;
}
.add-connect-app-dialog .add-connect-app-dialog-body .assign-workflow {
  display: flex;
  flex: 1;
  justify-content: space-between;
}
.add-connect-app-dialog .add-connect-app-dialog-body .assign-workflow > div {
  flex: 1;
}
.add-connect-app-dialog .add-connect-app-dialog-body .workflow-pick-step {
  display: flex;
  justify-content: space-between;
  flex: 1;
}
.add-connect-app-dialog .add-connect-app-dialog-body .workflow-pick-step > div {
  flex: 1;
}
.add-connect-app-dialog .add-connect-app-dialog-body .workflow-list .loading-wrapper {
  height: 350px;
}
.add-connect-app-dialog .add-connect-app-dialog-body .workflow-picker {
  border: 1px solid rgba(33, 33, 33, 0.33);
}
.add-connect-app-dialog .add-connect-app-dialog-body .people-picker {
  width: 100% !important;
}
.add-connect-app-dialog .add-connect-app-dialog-body tbody {
  height: 250px;
  overflow: scroll;
}
.add-connect-app-dialog .add-connect-app-dialog-body td > span {
  display: inline !important;
  max-width: 100%;
  text-overflow: ellipsis;
}
