.call .user-avatar, .call-notification .user-avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.call .user-avatar.initials, .call-notification .user-avatar.initials {
  background-color: #00A3DE;
}
.call .user-avatar, .call .user-avatar img, .call-notification .user-avatar, .call-notification .user-avatar img {
  font-size: 24px;
  width: 64px;
  max-width: 64px;
  min-width: 64px;
  height: 64px;
  max-height: 64px;
  min-height: 64px;
  border-radius: 4px;
}
.call .user-avatar.full, .call .user-avatar.full img, .call-notification .user-avatar.full, .call-notification .user-avatar.full img {
  font-size: 48px;
  width: 120px;
  max-width: 64px;
  min-width: 64px;
  height: 120px;
  max-height: 64px;
  min-height: 64px;
  border-radius: 8px;
}
.call .user-avatar.small, .call .user-avatar.small img, .call-notification .user-avatar.small, .call-notification .user-avatar.small img {
  font-size: 12px;
  width: 32px;
  height: 32px;
  border-radius: 4px;
}
