.ldap-settings .ldap-settings-content {
  padding: 20px 20px 68px 20px;
}
.ldap-settings .ldap-toggle {
  margin: 15px 0 0 15px;
  font-family: Roboto;
}
.ldap-settings .ldap-toggle .title {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.8) !important;
  font-weight: 700;
}
.ldap-settings .ldap-toggle .description {
  margin-bottom: 12px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.66);
}
.ldap-settings .ldap-toggle .cursor-tooltip {
  width: fit-content;
}
.ldap-settings .ldap-toggle .toggle-control.disabled {
  width: fit-content !important;
}
.ldap-settings .ldap-toggle .toggle-control.disabled input {
  width: initial !important;
  height: initial !important;
}
.ldap-settings .field-control {
  margin: 7px 0 7px 24px;
}
.ldap-settings .ldap-form-wrapper .field-control {
  margin-top: 10px;
}
.ldap-settings .ldap-last-synced {
  margin-top: 15px;
}
.ldap-settings .add-additional-filter-form-wrapper .outlined-field {
  margin-top: 15px;
}
