.client-settings {
  padding: 10px 10px 68px 10px;
  background-color: #f5f5f5;
}
.client-settings .settings-section {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  background-color: #fff;
  border-radius: 4px;
}
.client-settings .settings-section > .section-content {
  padding: 0 20px;
}
.client-settings .settings-section > .section-content .title {
  font-size: 16px;
}
.client-settings .settings-section > .section-content > .control-wrapper {
  display: flex;
  flex-direction: row;
  padding: 20px;
}
.client-settings .settings-section > .section-content > .control-wrapper .control-section {
  display: flex;
  flex-direction: column;
  width: 200px;
}
.client-settings .settings-section > .section-content > .control-wrapper .control-section > span {
  font-size: 14px;
  padding-bottom: 10px;
  color: rgba(33, 33, 33, 0.33);
}
.client-settings .settings-section > .section-content > .control-wrapper .checkbox-control {
  padding: 5px 0;
}
.client-settings .settings-section > .section-content > .control-wrapper .checkbox-control > input {
  width: 10% !important;
}
.client-settings .settings-section > .section-content > .control-wrapper label {
  font-size: 14px !important;
  color: rgba(33, 33, 33, 0.33) !important;
}
