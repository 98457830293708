@media (max-width: 767px) {
  .session-list .table-heading {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    height: unset;
  }
  .session-list .session-list-title {
    margin-bottom: 10px;
  }
}
.session-list .session-list-title {
  color: #000;
  font-weight: 700;
}
.session-list .nav-link {
  color: inherit;
  text-decoration: none;
}
.session-list .nav-link:hover {
  text-decoration: underline;
}
.session-list .list-action-button {
  transition: bottom 300ms ease-in-out;
}
.session-list .list-action-button.item-selected {
  bottom: 70px !important;
}
.session-list .item-action-button {
  width: initial !important;
  height: initial !important;
  padding: 6px !important;
}
.session-list .footer-actions .item-action-button {
  margin: 6px !important;
}

.confirmation-dialog.close-application-sessions-modal .close-application-sessions-modal-title {
  background-color: #dd2c00;
  color: #ffffff !important;
  font-size: 20px;
  display: flex;
  align-items: center;
}
.confirmation-dialog.close-application-sessions-modal .close-application-sessions-modal-title > svg {
  margin-right: 10px;
  color: #ffffff !important;
  fill: #ffffff !important;
  height: 30px !important;
  width: 30px !important;
}
.confirmation-dialog.close-application-sessions-modal div > button:last-child span {
  color: #dd2c00;
}
.confirmation-dialog.close-application-sessions-modal .body {
  padding: 10px 24px 24px !important;
}
