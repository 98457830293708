.group-detail-tabs .dashboard-tabs > div:first-child {
  box-shadow: inset 12px 0px 10px -5px rgba(0, 0, 0, 0.1) !important;
  position: sticky;
  top: 1px;
  z-index: 2;
  background-color: #FFF !important;
}
.group-detail-tabs .dashboard-tabs > div:nth-child(2) {
  position: sticky;
  top: 50px;
  z-index: 2;
}
.group-detail-tabs .dashboard-tabs > div:nth-child(2) > div {
  top: -2px;
  background-color: #00aaed !important;
}
