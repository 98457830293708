.recover-password-submit-container .login-large-button {
  margin-top: 34px;
}

.submit-success-text-wrapper {
  font-size: 16px;
  color: #212121;
  text-align: center;
  opacity: 0.66;
  line-height: 21px;
}

.if-account-exists {
  white-space: nowrap;
}

.click-the-link-wrapper {
  display: block;
}

.click-the-link {
  font-family: Roboto Bold;
}

.need-additional-help, .contact-your-admin {
  display: block;
  margin-top: 24px;
  color: rgba(0, 0, 0, 0.66);
  font-size: 12px;
  text-align: center;
}

.contact-your-admin {
  margin-top: 8px;
  font-family: Roboto Bold;
}
