.next-gen-application-grid {
  height: stretch;
  min-height: fit-content;
}
.next-gen-application-grid .grid-view .copy-icon {
  transform: rotate(90deg);
}
.next-gen-application-grid .grid-view .grid-list {
  padding: 10px 15px;
}
.next-gen-application-grid .grid-view .grid-list > div:not(.subheader) {
  width: 230px !important;
  height: 230px !important;
  padding: 10px !important;
}
.next-gen-application-grid .grid-view .empty-label-container {
  margin-top: 0;
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.next-gen-application-grid .grid-view .empty-label-container .empty-list-view {
  white-space: break-spaces;
}
.next-gen-application-grid .grid-footer {
  margin-left: 0;
}

.table-heading .title .sort-picker {
  position: initial;
  width: max-content;
  margin-right: 20px;
}
.table-heading .title .sort-picker .sort-picker-arrow .icon svg {
  color: #bbbec0 !important;
}
