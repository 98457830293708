.token-details {
  position: relative;
  padding: 15px;
}
.token-details .token-item-details {
  margin-bottom: 15px;
}
.token-details .title > span {
  display: block;
}
.token-details .title > span:first-child {
  font-size: 18px;
  color: rgba(33, 33, 33, 0.66);
  margin-bottom: 12px;
}
.token-details .title > span:last-child {
  font-size: 14px;
  color: rgba(33, 33, 33, 0.33);
  margin-bottom: 24px;
}
.token-details .workflow-picker {
  max-height: 600px;
  overflow-y: scroll;
  margin-left: 10px;
  border: 1px solid #f6f6f6;
}
.token-details .actions {
  text-align: right;
  margin-top: 15px;
}
