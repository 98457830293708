.menu .menu-item {
  text-align: left;
}
.menu .menu-item.with-left-icon > div > div {
  padding: 0px 16px 0px 60px !important;
}
.menu .menu-item.active {
  color: #00aaed !important;
}
.menu .menu-item.active svg {
  fill: #00aaed !important;
}
