.pdf-editor-dialog {
  z-index: 10000 !important;
}
.pdf-editor-dialog > div > div {
  width: 90% !important;
  max-width: unset !important;
}
.pdf-editor-dialog .pdf-editor-dialog-body {
  height: 90vh;
}
.pdf-editor-dialog .next-btn, .pdf-editor-dialog .prev-btn {
  float: left;
}
