.assign-workflow {
  outline: 0;
}
.assign-workflow .step-buttons {
  text-align: right;
  padding-top: 12px;
}
.assign-workflow .step-buttons .submit-button button {
  z-index: initial !important;
}
.assign-workflow .assignment-list {
  max-height: 350px;
  overflow-y: hidden;
  border: 1px solid #ededed;
}
.assign-workflow .options-step {
  margin-left: 15px !important;
}
