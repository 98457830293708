.assignment-attachment-grid-item-wrapper > div:last-child {
  left: 30% !important;
  right: initial !important;
}
.assignment-attachment-grid-item-wrapper .attachment-grid-item-tooltip > div > span:first-child {
  font-size: 11px;
  font-weight: 600;
}
.assignment-attachment-grid-item-wrapper .attachment-grid-item-tooltip > div > span:last-child {
  font-family: "Roboto Light";
  margin-left: 5px;
}
.assignment-attachment-grid-item-wrapper .assignment-attachment-grid-item {
  width: 100%;
  cursor: pointer;
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-top: 2px solid transparent;
  border-bottom: 2px solid transparent;
}
.assignment-attachment-grid-item-wrapper .assignment-attachment-grid-item:hover .assignment-attachment-selected-icon {
  color: #736f77 !important;
}
.assignment-attachment-grid-item-wrapper .assignment-attachment-grid-item .assignment-attachment-selected-icon {
  color: #ededed !important;
  margin-right: 20px;
}
.assignment-attachment-grid-item-wrapper .assignment-attachment-grid-item .assignment-attachment-selected-icon:hover {
  color: #24c6fc !important;
  background-color: rgba(153, 153, 153, 0.2);
  border-radius: 4px;
}
.assignment-attachment-grid-item-wrapper .assignment-attachment-grid-item.active:before {
  content: "";
  position: absolute;
  width: 12px;
  background-color: #00C8FF;
  height: 100%;
  left: -70px;
}
.assignment-attachment-grid-item-wrapper .assignment-attachment-grid-item.active .assignment-attachment-selected-icon {
  color: #24c6fc !important;
}
.assignment-attachment-grid-item-wrapper .assignment-attachment-grid-item.active, .assignment-attachment-grid-item-wrapper .assignment-attachment-grid-item:hover {
  border-image: linear-gradient(90deg, rgba(250, 250, 250, 0) 10%, #ededed);
  border-image-slice: 1;
  background: linear-gradient(90deg, rgba(250, 250, 250, 0) 10%, #fafafa);
}
