.details-menu {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.details-menu .details-menu-items {
  width: 100%;
}
.details-menu .details-menu-items .multiple-items-details-menu {
  padding: 0 !important;
}
.details-menu.open {
  box-shadow: 0px 6px 19px #000000;
}
.details-menu.open .details-menu-items {
  display: flex;
}
.details-menu.open .details-menu-items > div {
  width: 100% !important;
}
.details-menu.open .details-menu-items .multiple-items-details-menu {
  width: 100% !important;
  display: block !important;
}
.details-menu.open .icon-menu {
  margin: 0;
  height: 70px !important;
  width: 100%;
}
.details-menu.open .icon-menu > button {
  height: 70px !important;
  width: 100% !important;
  border-radius: 0 !important;
}
.details-menu.open .icon-menu > button > div > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.details-menu.open .icon-menu > button > div > div svg {
  margin-left: 16px;
}
.details-menu.open .icon-menu > button > div > div span {
  display: initial !important;
  margin-left: 32px;
  color: #fff;
}
.details-menu .icon-menu {
  margin-right: 16px;
  margin-bottom: 16px;
  width: 56px;
  height: 56px;
  background-color: #1498e8;
  border-radius: 28px;
  box-shadow: none !important;
}
.details-menu .icon-menu > button {
  box-shadow: 0 0 5px 0 rgba(33, 33, 33, 0.5) !important;
}
.details-menu .icon-menu > button > div > div span {
  display: none !important;
}
.details-menu .common-tooltip > div:nth-child(2) {
  top: 35px !important;
  right: 65px !important;
}
