.media .media-preview .preview-bar {
  display: none !important;
}

.media-details .media-preview {
  width: 100%;
}
.media-details .media-preview .media-preview-loader {
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}
