.home-body-next-gen {
  margin-top: 56px;
}
@media (max-width: 1024px) {
  .home-body-next-gen {
    margin-top: 0;
  }
}
.home-body-next-gen .media-gallery.show-details .media-lightbox {
  width: calc(100% - 350px) !important;
}
