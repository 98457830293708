.current-user-menu .current-user-info-container {
  display: flex;
  padding: 10px;
  border-bottom: 1px solid #ededed;
}
.current-user-menu .current-user-info-container .current-user-avatar {
  display: flex;
  width: 40px;
  height: 40px;
  margin: 5px;
}
.current-user-menu .current-user-info-container .current-user-avatar .user-avatar-placeholder {
  background-color: #00aaed;
  color: #ffffff;
}
.current-user-menu .current-user-info-container .current-user-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 5px;
}
.current-user-menu .current-user-info-container .current-user-info .current-user-name {
  font-size: 15px;
}
.current-user-menu .current-user-info-container .current-user-info .current-user-domain {
  font-size: 14px;
  color: #bbbec0;
}
