.left-nav > div > div {
  overflow-x: hidden !important;
}
.left-nav .nav-section-container {
  margin-top: 20px;
}
.left-nav .nav-section-container.open .nav-section-content {
  overflow: visible !important;
}
.left-nav .nav-section-header {
  align-items: center;
  height: 13px;
  padding: 0 14px 10px 14px;
}
.left-nav .nav-section-header .nav-section-label {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  user-select: none;
}
.left-nav .navigation-drawer {
  top: 57px !important;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 4px, rgba(0, 0, 0, 0.23) 0px 3px 4px !important;
}
.left-nav .navigation-drawer .drawer-content {
  height: calc(100% - 57px);
}
.left-nav .nav-item-with-divider {
  height: initial !important;
}
.left-nav .divider {
  margin: -5px auto !important;
  background-color: rgba(65, 64, 66, 0.05) !important;
}
.left-nav .title-manage {
  height: 40px !important;
  text-transform: uppercase;
  font-size: 13px;
}
.left-nav .title-manage span {
  margin-left: 20px !important;
  color: rgba(0, 0, 0, 0.5);
}
.left-nav .title-manage .divider {
  width: 85% !important;
}
.left-nav .nav-item-tooltip {
  height: 32px !important;
  margin: 8px auto !important;
  border-radius: 4px;
  justify-content: flex-start !important;
}
.left-nav .nav-item-tooltip div:first-child {
  width: 100%;
}
.left-nav .nav-item-tooltip.has-tooltip.active > div:last-child {
  transform: translate(0, 45px) !important;
}
.left-nav .nav-item-tooltip.has-tooltip.active > div:last-child div:first-child {
  transform: translate(-50%, -50%) !important;
}
.left-nav .nav-item-tooltip.has-tooltip.active.top > div:last-child {
  transform: translate(0, -10px) !important;
}
.left-nav .nav-item {
  height: 32px !important;
  margin: 8px auto !important;
  width: 95%;
  border-radius: 4px;
  line-height: initial !important;
}
.left-nav .nav-item svg {
  width: 22px !important;
  height: 22px !important;
}
.left-nav .nav-item div span:first-child {
  display: none;
}
.left-nav .nav-item div div {
  padding: 0 6px !important;
  position: static !important;
}
.left-nav .nav-item .menu-container a span {
  font-weight: 400 !important;
}
.left-nav .nav-item .menu-container a.active {
  color: #00aaed !important;
}
.left-nav .nav-item .menu-container a.active:before {
  content: "";
  width: 100%;
  height: 32px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: rgba(0, 170, 237, 0.1);
  border-radius: 4px;
  box-shadow: none !important;
}
.left-nav .nav-item .menu-container a.active span {
  font-weight: bold !important;
}
.left-nav .nav-item .menu-container a.active svg {
  fill: #00aaed !important;
}
