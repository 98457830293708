.home {
  display: flex;
}
@media (max-width: 1024px) {
  .home.next-gen {
    margin-top: 56px;
  }
}
.home .main {
  width: 100%;
}
