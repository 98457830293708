.assignment-list {
  position: relative;
}
.assignment-list .attachment-loading {
  position: absolute;
  top: -46px;
  left: 0;
  width: 100%;
  z-index: 6;
  box-shadow: 0 0 2px 1px #fff;
}
.assignment-list th.actions, .assignment-list td.actions {
  width: 48px !important;
}
.assignment-list .chk-icon svg {
  fill: #BBBEC0 !important;
}
.assignment-list tr.archived {
  opacity: 0.5;
}
.assignment-list .hover-checkbox-column .archived {
  color: #CE2400 !important;
}
.assignment-list .wf-task .hover-checkbox-column .error-icon {
  color: #CE2400 !important;
}
.assignment-list .wf-task.error td, .assignment-list .wf-task.error td i {
  color: #CE2400 !important;
}
.assignment-list .wf-task.error.selected td, .assignment-list .wf-task.error.selected td i {
  color: #fff !important;
}
.assignment-list .wf-task.in-progress .hover-checkbox-column .chk {
  display: none !important;
}
.assignment-list .wf-task.in-progress td {
  color: #c3c3c3 !important;
}
.assignment-list .item-action-button {
  margin: 0 2px !important;
}
.assignment-list .item-action-button > div > div {
  pointer-events: none;
}
.assignment-list .item-action-button:hover .material-icon {
  color: #00aaed !important;
}
.assignment-list .table-footer {
  z-index: 1;
}
.assignment-list .action-button {
  padding: 2px !important;
}
.assignment-list .action-button.icon {
  margin-left: 0 4px !important;
  padding-bottom: 0px !important;
  border-radius: 4px !important;
}
.assignment-list .action-button.icon:hover {
  background-color: #ecebec !important;
}
.assignment-list .action-button.icon.skylight-icon {
  margin: 0 2px !important;
}
.assignment-list .select-all-checkbox {
  width: 24px !important;
  height: 24px !important;
  display: none !important;
}
.assignment-list .select-all-checkbox div div div svg:first-child {
  fill: #9e9e9e !important;
}
.assignment-list .select-all-checkbox.checked {
  display: block !important;
}
