.attachment-details .media-grid-view > .grid-list > :not(.subheader) {
  width: 100px !important;
  height: 100px !important;
  margin-bottom: 5px;
}
.attachment-details .attachment-tag-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.attachment-details .attachment-tag-wrapper .attachment-tag-chip {
  margin: 5px 3px !important;
  max-width: 100% !important;
}
.attachment-details .attachment-tag-wrapper .attachment-tag-chip > span {
  overflow-x: hidden;
  text-overflow: ellipsis;
}
