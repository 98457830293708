.application-grid {
  background-color: #F6F5F5;
  height: stretch;
  min-height: fit-content;
}
.application-grid .grid-view .copy-icon {
  transform: rotate(90deg);
}
.application-grid .grid-view .grid-list > div:not(.subheader) {
  width: 216px !important;
  height: 216px !important;
  padding: 3px !important;
}
