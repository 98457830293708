.by-name-of-choice > .btn {
  width: 100%;
}
.by-name-of-choice > .btn > div {
  display: flex !important;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.by-name-of-choice > .btn.has-value > button > div > div {
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  padding: 0px 5px;
}

.multiple-choice-picker.by-list-picker .select-content {
  width: inherit !important;
}
