.call .call-chat {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.75);
}
.call .call-chat .call-chat-header {
  display: flex;
  align-items: center;
  background-color: #00AAED;
  height: 52px;
}
.call .call-chat .messages {
  overflow-y: scroll;
  height: 218px;
}
.call .call-chat .messages .chat-message {
  display: flex;
  padding-top: 8px;
}
.call .call-chat .messages .chat-message .avatar {
  padding: 0px 14px;
}
.call .call-chat .messages .chat-message .title-body {
  flex-grow: 1;
  padding-right: 14px;
  color: #1B1B1B;
}
.call .call-chat .messages .chat-message .title-body .title {
  display: flex;
}
.call .call-chat .messages .chat-message .title-body .title .date {
  opacity: 0.33;
  margin-left: auto;
}
.call .call-chat .messages .chat-message .title-body .title, .call .call-chat .messages .chat-message .title-body .title .user-title {
  text-align: left;
  opacity: 0.66;
  font-family: Helvetica;
  font-size: 12px;
  color: #1B1B1B;
  line-height: 12px;
}
.call .call-chat .messages .chat-message .title-body .body {
  font-family: Helvetica;
  font-size: 14px;
}
.call .call-chat .no-items {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  color: #1B1B1B;
}
.call .call-chat .new-message {
  border-top: 1px solid #eaeaea;
  height: 52px;
  display: flex;
}
.call .call-chat .new-message .message-input {
  flex-grow: 1;
  padding: 0px 8px;
  height: 100%;
  outline: 0;
  border: none;
  font-size: 16px;
  resize: none;
}
.call .call-chat .new-message .send {
  margin-right: 16px;
}
.call .call-chat .new-message .send svg {
  color: #00AAED !important;
}
