.outlined-select-field .select-field {
  width: 100% !important;
  top: -2px !important;
}
.outlined-select-field .select-field hr {
  display: none;
}
.outlined-select-field .select-field button {
  right: -7px !important;
  pointer-events: none;
}
.outlined-select-field .select-field button svg {
  width: 24px !important;
  height: 24px !important;
  fill: #736f77 !important;
}
