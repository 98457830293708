.call .chat-unread {
  display: flex;
  flex-direction: column;
  position: relative;
}
.call .chat-unread .close {
  display: none;
}
.call .chat-unread:hover .close {
  opacity: 0.66;
  display: block;
  position: absolute;
  bottom: -6px;
  right: 0px;
}
.call .chat-unread:hover .close svg {
  color: #1B1B1B !important;
}
.call .chat-unread .unread-message {
  margin: 2px 0;
  width: 100%;
  height: 52px;
  display: flex;
  background: #fff;
  color: #1B1B1B;
  border-radius: 4px;
  font-family: Helvetica;
  cursor: pointer;
}
.call .chat-unread .unread-message .avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 52px;
}
.call .chat-unread .unread-message .title-body {
  display: flex;
  flex-direction: column;
  padding: 7px 11px 0px 0px;
  width: 315px;
}
.call .chat-unread .unread-message .title-body .title {
  display: flex;
  font-size: 12px;
  opacity: 0.66;
}
.call .chat-unread .unread-message .title-body .title .user-title {
  color: #1b1b1b;
  font-size: 12px;
  line-height: 12px;
  flex-grow: 1;
  text-align: left;
}
.call .chat-unread .unread-message .title-body .title-date .date {
  width: 50px;
  align-self: flex-end;
}
.call .chat-unread .unread-message .title-body .body {
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  white-space: nowrap;
}
