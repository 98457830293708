.query-filter-section-body {
  width: 80%;
  margin-left: 24px;
}
.query-filter-section-description {
  color: rgba(15, 15, 15, 0.33);
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 30px;
}
.query-filter-section-description .required {
  margin: 3px;
  color: #dd2c00;
  font-size: 16px;
  font-weight: 600;
}
