.language-container {
  font-family: Roboto;
}
.language-container .title {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.8);
}
.language-container .description {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 10px;
}
.language-container .picker {
  max-width: 384px;
}
.language-container .outlined-select-field {
  margin-top: 15px;
}
.language-container .outlined-select-field .select-field div:nth-child(2) {
  margin-top: 3px;
}
.language-container .footer-action-buttons {
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: #fff;
  z-index: 2;
}
.language-container .footer-action-buttons .cancel-action {
  color: #736f77 !important;
}
