.outlined-text-field fieldset {
  height: 100%;
}
.outlined-text-field .outlined-field-container .text-field {
  display: flex;
  align-items: center;
  width: 100% !important;
  min-height: 48px;
  height: fit-content;
  line-height: 24px;
}
.outlined-text-field .outlined-field-container .text-field hr {
  display: none !important;
}
.outlined-text-field .outlined-field-container .text-field input, .outlined-text-field .outlined-field-container .text-field span {
  flex: 1 1 0%;
  padding: 12px 10px 15px 5px;
  border: 0;
  outline: 0;
  font-size: 16px;
  background-color: transparent;
  width: 97%;
  height: 95% !important;
}
.outlined-text-field .outlined-field-container .text-field span {
  padding: 0;
  color: #000;
  word-break: break-all;
}
