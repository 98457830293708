.admin-live {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
}
.admin-live .toggle-section {
  padding-bottom: 18px;
}
.admin-live .toggle-section .description {
  padding-top: 10px;
}
.admin-live .toggle-section .toggle-control {
  padding: 18px 0 0 10px;
  width: fit-content !important;
}
