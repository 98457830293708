.permissions-dialog-header {
  position: relative;
  width: 100%;
  height: 66px;
}

.permissions-dialog-header-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: -26px;
  left: -24px;
  width: calc(100% + 48px);
  height: 100%;
}
.permissions-dialog-header-inner span:nth-child(1) {
  padding-left: 50px;
  color: #fff;
}
.permissions-dialog-header-inner span:nth-child(2) {
  padding-right: 50px;
  color: #a1dff7;
  font-size: 14px;
}

.permissions-dialog-content .content-main .top-description {
  position: relative;
}
.permissions-dialog-content .content-main .top-description span {
  font-size: 15px;
}

.first-tab.first-tab {
  padding-left: 40px;
}

.popup-blocked-icon-top {
  position: absolute;
  left: -40px;
  top: 10px;
}

.media-permissions-list li {
  position: relative;
  padding: 0 0 25px 30px;
  font-size: 15px;
  list-style: none;
}
.media-permissions-list li svg {
  position: relative;
  top: 9px;
  left: -20px;
  width: 27px !important;
  height: 27px !important;
  color: #333 !important;
}

.allowing-popups-list {
  padding-top: 10px;
}
.allowing-popups-list li {
  padding: 0 0 25px 15px;
  font-size: 15px;
}
.allowing-popups-list li:last-child span:last-child {
  color: black;
  font-weight: bold;
}

.popup-blocked-icon-middle {
  position: absolute;
}

.dialog-actions-buttons {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
