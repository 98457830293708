.signin {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.signin > .error-text {
  z-index: 1;
  font-size: 12px;
  line-height: 18px;
  width: 100%;
  color: #dd2c00 !important;
  margin-bottom: 30px;
  font-family: Roboto, sans-serif;
}
.signin > .error-text span {
  margin-left: 12px;
}
.signin > .signin-frontline-btn {
  margin-top: 10px;
}
.signin > .signin-frontline-btn button {
  background-color: red !important;
}
.signin input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 40px white inset;
  box-shadow: 0 0 0 40px white inset;
}
