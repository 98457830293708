.blank-app-tab-container {
  margin-top: 20px;
}
.blank-app-tab-container .loading-container {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #000;
  opacity: 0.5;
  display: flex;
  z-index: 5;
  align-items: center;
  justify-content: center;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.blank-app-tab-container .form .blank-app-properties span {
  font-weight: bold;
  font-size: 16px;
  line-height: 1.25;
}
.blank-app-tab-container .form .blank-app-info {
  margin-top: 5px;
}
.blank-app-tab-container .form .blank-app-info span {
  font-size: 14px;
  opacity: 0.8;
}
.blank-app-tab-container .form .blank-app-form-fields {
  margin-top: 5px;
}
.blank-app-tab-container .form .blank-app-form-fields .text-field {
  width: 100% !important;
}
.blank-app-tab-container .form .dialog-actions-buttons {
  margin-top: 25px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  position: relative;
  bottom: -15px;
}
.blank-app-tab-container .outlined-menu-picker, .blank-app-tab-container .description-field {
  margin-top: 15px;
}
.blank-app-tab-container .name-field {
  margin-top: 25px;
}
.blank-app-tab-container .name-field .underline-text {
  margin-left: 7px;
  margin-top: 3px;
}

.solutions-hint {
  font-size: 12px;
  margin-left: 12px;
  margin-top: 5px;
}
.solutions-hint span {
  opacity: 0.66;
  color: rgba(0, 0, 0, 0.66);
}
