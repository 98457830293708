.call-group-details-heading {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.call-group-details-heading .heading-title-wrapper {
  padding: 15px 0 0 24px;
  text-overflow: ellipsis;
}
.call-group-details-heading .heading-title-wrapper span:first-child {
  padding-bottom: 2px;
  font-size: 13px;
  font-weight: 300;
  color: #b6b6b6;
}
.call-group-details-heading .heading-content {
  width: 100%;
}

.heading-content-dd-menu {
  margin-top: -10px !important;
  width: 100% !important;
}
