.activity-list .activity-icon-column {
  width: 24px;
}
.activity-list .action-button {
  padding: 2px !important;
}
.activity-list .action-button.icon {
  margin-left: 0 4px !important;
  padding-bottom: 0px !important;
  border-radius: 4px !important;
}
.activity-list .action-button.icon:hover {
  background-color: #ecebec !important;
}
.activity-list .action-button.icon.skylight-icon {
  margin: 0 2px !important;
}
