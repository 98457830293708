.security-section .field-control-rootCAsPem {
  margin-top: 24px;
}
.security-section .field-control-skipVerifyCert .field-cert {
  width: 80%;
  display: flex;
  align-items: center;
}
.security-section .field-control-skipVerifyCert .field-cert .field-check-box {
  padding: 0;
  margin: 12px 0;
}
.security-section .field-control-skipVerifyCert .field-cert .field-check-box.disabled {
  opacity: 0.5;
}
.security-section .field-control-skipVerifyCert .field-cert .field-check-box.disabled input {
  z-index: 0 !important;
}
.security-section .field-control-skipVerifyCert .field-cert .field-check-box label {
  color: #212121 !important;
}
.security-section .field-control-skipVerifyCert svg {
  color: #dd2c00 !important;
}
