.call .call-screen {
  background-color: #222222;
  width: 100%;
  height: 100%;
  display: flex;
  overflow-x: hidden;
}
.call .call-screen .call-area {
  position: relative;
  flex-grow: 1;
}
.call .call-screen .call-media-panel {
  width: 50%;
  z-index: 1;
}
.call .call-screen .call-header {
  position: absolute;
  width: 100%;
  top: 0;
  z-index: 1;
}
.call .call-screen .call-footer {
  position: absolute;
  width: 100%;
  bottom: 0;
}
.call .call-screen .main {
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.call .call-screen .main .remote {
  width: 100%;
  height: 100%;
}
.call .call-screen .main .user-title {
  margin-top: 64px;
}
.call .call-screen .call-video.full {
  position: absolute;
  width: 100%;
  height: 100%;
}
.call .call-screen .call-video.full .swap-calls {
  display: none;
}
.call .call-screen .call-video.preview {
  position: absolute;
  top: 79px;
  left: 16px;
  border: 2px solid #fff;
  border-radius: 4px;
  background-color: #222222;
}
.call .call-screen .call-video.preview video {
  max-width: 120px;
  z-index: 3;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}
.call .call-screen .call-zoom {
  position: absolute;
  right: 16px;
  top: 50%;
}
