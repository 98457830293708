.general-information-container {
  max-height: calc(100vh - 56px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.general-information-container .general-information-header {
  min-height: 64px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.general-information-container .general-information-title {
  font-size: 20px;
  color: #000;
  font-weight: 700;
  font-family: Roboto;
}
.general-information-container .general-information-body {
  overflow: auto;
}
.general-information-container .general-information-actions {
  display: flex;
  justify-content: flex-end;
}
.general-information-container .general-information-actions .app-builder-button {
  margin-left: 10px;
}
.general-information-container .general-information-actions .item-action-icon-button {
  width: 38px !important;
  height: 38px !important;
  padding: 0 !important;
}
.general-information-container .general-information-actions .item-action-icon-button.export svg {
  transform: rotate(-90deg);
}
.general-information-container .general-information-actions .item-action-icon-button:hover svg {
  color: #00afee !important;
}
.general-information-container .general-information-actions .item-action-icon-button svg {
  color: #736f77 !important;
}
.general-information-container .general-information-section {
  display: flex;
  margin: 8px 18px;
  font-family: Roboto, sans-serif;
}
.general-information-container .general-information-section .general-information-topic {
  line-height: 2;
  font-size: 20px;
  color: #414042;
}
.general-information-container .general-information-section .general-information-data-table {
  flex: 1;
  width: 100%;
}
.general-information-container .general-information-section .general-information-data-table .data-table-field-value {
  position: relative;
}
.general-information-container .general-information-section .general-information-data-table .last-exported {
  display: flex;
  justify-content: space-between;
}
.general-information-container .general-information-section .general-information-data-table .last-exported .download-button {
  position: absolute !important;
  top: 6px;
  right: 5px;
  color: #00aaed !important;
}
@media (max-width: 767px) {
  .general-information-container .general-information-header {
    flex-direction: column-reverse;
    align-items: initial;
  }
  .general-information-container .general-information-header .general-information-title {
    height: 30px;
    margin-left: 10px;
    margin-top: 10px;
  }
  .general-information-container .general-information-body {
    padding: 10px;
  }
  .general-information-container .general-information-actions {
    position: static !important;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-top: 10px;
  }
  .general-information-container .general-information-data-table {
    width: 100% !important;
  }
  .general-information-container .general-information-section {
    margin: 0 !important;
  }
}
@media (max-width: 479px) {
  .general-information-container .last-exported {
    flex-direction: column;
  }
  .general-information-container .last-exported .download-button {
    top: initial !important;
    right: initial !important;
    bottom: 0;
    left: 10px;
  }
}
