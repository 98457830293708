.user-image {
  position: relative;
  width: 128px;
  height: 128px;
}
.user-image.editable {
  cursor: pointer;
}
.user-image img {
  width: inherit;
  height: inherit;
  object-fit: cover;
}
.user-image .user-image-footer {
  height: 22px;
  position: absolute;
  background-color: rgba(65, 64, 66, 0.8);
  text-align: center;
  bottom: 0;
  width: 100%;
}
.user-image .user-image-footer span {
  vertical-align: middle;
  font-size: 16px;
  line-height: 18px;
  color: #fff;
}
