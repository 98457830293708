.items-picker {
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  height: 100%;
  max-width: 100%;
  position: relative;
  line-height: 48px;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.items-picker.disabled {
  pointer-events: none;
}
.items-picker .menu-picker {
  background-color: #fff;
  height: 35px !important;
  border-radius: 5px;
  margin-right: 5px;
  min-width: 95%;
}
.items-picker .menu-picker > div > button {
  top: 0px !important;
}
.items-picker .menu-picker > div > div:nth-of-type(2) {
  padding: 0 !important;
  height: 100% !important;
  display: flex;
  align-items: center;
}
.items-picker .menu-picker button {
  right: 0 !important;
  width: 30px !important;
  height: 35px !important;
  border-radius: 0 3px 3px 0;
  padding: 0px 25px 0 5px !important;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.8) 20%, white 50%) !important;
  fill: #736f77 !important;
  transition: none !important;
}
.items-picker .menu-picker .items-picker-arrow {
  height: 20px !important;
  width: 20px !important;
}
.items-picker .selected-items {
  display: flex;
  height: 35px;
}
.items-picker .selected-items .selected-item {
  margin: 3px 0px 3px 3px;
  border: 1px solid #bbbec0;
  color: #bbbec0;
  font-family: "Roboto Medium";
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0 6px 0 2px;
}
.items-picker .selected-items .selected-item svg {
  fill: #bbbec0 !important;
  margin-right: 5px;
}
.items-picker .selected-items .selected-item .details-link a {
  color: #bbbec0 !important;
}
.items-picker div.icon {
  padding: 0 28px 0 4px;
  display: flex;
  margin-left: auto;
  align-self: center;
}
.items-picker div.icon svg {
  color: #414042 !important;
}

tr.selected .items .group-select-field div {
  color: #ffffff !important;
}
tr.selected .items-picker .menu-picker {
  border-color: transparent;
  background-color: #fff !important;
}
tr.selected .items-picker .menu-picker button {
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0.8) 20%, white 50%) !important;
  fill: #414042 !important;
}
