.media-list .vertical-divider {
  display: inline-table;
  height: 24px;
  outline: 1px solid #ededed;
  margin-left: 8px;
  margin-right: 4px;
}
.media-list .overflow-icon {
  padding: 0px !important;
  width: 24px !important;
  height: 24px !important;
}
.media-list .hidden {
  display: none !important;
}
.media-list .media-list-download-icon {
  padding-top: 1px;
}
.media-list .media-list-overflow-menu {
  margin-left: 4px;
}
.media-list .media-list-overflow-menu:hover {
  padding: 2px 0;
  border-radius: 4px;
  padding-bottom: 0px;
  background-color: #ecebec;
}
.media-list th.media-header-actions {
  display: inline-cell;
  text-align: right;
  width: 30px;
  overflow: visible;
}
.media-list th.media-header-actions .table-column-container {
  display: none;
}
.media-list td.media-header-actions {
  width: 30px;
}
.media-list .action-button {
  padding: 2px !important;
}
.media-list .action-button.icon {
  margin-left: 0 4px !important;
  padding-bottom: 0px !important;
  border-radius: 4px !important;
}
.media-list .action-button.icon:hover {
  background-color: #ecebec !important;
}
.media-list .action-button.icon.skylight-icon {
  margin: 0 2px !important;
}
.media-list .select-all-checkbox {
  width: 24px !important;
  height: 24px !important;
  display: none !important;
}
.media-list .select-all-checkbox div div div svg:first-child {
  fill: #9e9e9e !important;
}
.media-list .select-all-checkbox.checked {
  display: block !important;
}

.media-snackbar div:first-child {
  background-color: #736f77 !important;
}
.media-snackbar button {
  color: #00aaed !important;
}
