.user-avatar {
  display: flex;
  flex: 1;
}
.user-avatar .user-avatar-placeholder {
  display: flex;
  flex: 1;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.user-avatar .user-image {
  width: 100%;
  height: 100%;
}
.user-avatar .user-image img {
  border-radius: 4px;
}
