.account-password .outlined-field {
  margin-top: 12px;
}
.account-password .password-requirements {
  line-height: 17px;
}
.account-password .new-password-field {
  margin-bottom: 5px;
}
.account-password .new-password-field .underline-text {
  display: none;
}
.account-password .new-password-field.generic-requirements-error {
  margin-bottom: 0;
}
.account-password .new-password-field.generic-requirements-error .underline-text {
  margin-bottom: 50px;
  display: block;
}
