.device-info .device-info-description {
  font-size: 13px;
  color: #414042;
  text-align: left;
  border: 1px #efefef solid;
  background-color: #f7f7f7;
  padding: 7px;
  word-wrap: break-word;
  max-height: 400px;
  overflow-y: auto;
}
.device-info .device-info-description span {
  display: inline-block;
  color: #848181;
}
