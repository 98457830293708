.current-user-button > button {
  padding: 0 !important;
  height: 100% !important;
  width: 100% !important;
}
.current-user-button > button:hover {
  background-color: #736f77;
}
.current-user-button:hover button {
  opacity: 0.8;
}
.current-user-button .user-avatar {
  height: 100%;
}
.current-user-button .user-avatar .user-avatar-placeholder {
  background-color: #ffffff;
  color: #00aaed;
}

.next-gen .current-user-button .user-avatar .user-avatar-placeholder {
  background-color: #00aaed;
  color: #ffffff;
}
