.pdf-editor {
  color: #000;
  width: 100%;
  height: 100%;
  display: flex;
}
.pdf-editor .pdf-page-list {
  width: 300px;
}
.pdf-editor .main {
  width: 100%;
}
