.outlined-field {
  display: flex;
}
.outlined-field fieldset {
  border: 1px solid #ededed;
  color: #736f77;
  border-radius: 4px;
  font-size: 12px;
  flex: 1;
  padding: 0 8px 0px 14px;
  height: 64px;
}
.outlined-field fieldset:hover {
  border: 1px solid #bbbec0;
}
.outlined-field legend {
  font-family: Roboto, sans-serif;
}
.outlined-field legend .required {
  margin: 3px;
  color: #dd2c00;
}
.outlined-field .outlined-field-container {
  display: flex;
  align-items: center;
  position: relative;
  flex: 1;
}
.outlined-field .outlined-field-container .outlined-field-body {
  flex: 1;
}
.outlined-field .underline-text {
  font-size: 12px;
  height: 10px;
}
.outlined-field .underline-text span {
  font-family: Roboto, sans-serif;
}
.outlined-field.focused fieldset {
  border: 1px solid #00aaed;
}
.outlined-field.focused legend {
  color: #00aaed;
}
.outlined-field.error fieldset {
  border: 1px solid #dd2c00;
}
.outlined-field.error legend {
  color: #736f77;
}
.outlined-field.error legend span {
  font-family: Roboto, sans-serif;
}
.outlined-field.error .underline-text {
  color: #dd2c00;
}
.outlined-field.disabled {
  cursor: not-allowed;
}
.outlined-field.disabled fieldset {
  background-color: #ededed;
  border: 1px solid #bbbec0;
}
.outlined-field.disabled input {
  pointer-events: none;
}
.outlined-field.readonly input {
  pointer-events: none;
}
