.pdf-editor .pdf-page-list {
  height: 100%;
  margin-right: 20px;
  margin-top: 20px;
}
.pdf-editor .pdf-page-list .list {
  outline: none;
}
.pdf-editor .pdf-page-list .pdf-page-row {
  display: flex;
}
.pdf-editor .pdf-page-list .pdf-page-row.selected {
  background: rgba(0, 0, 0, 0.1);
}
.pdf-editor .pdf-page-list .pdf-page-row .page-number {
  padding-left: 20px;
  padding-top: 5px;
}
.pdf-editor .pdf-page-list .pdf-page-row .pdf-page canvas {
  cursor: pointer;
  height: 190px;
  width: 190px;
}
.pdf-editor .pdf-page-list.loading, .pdf-editor .pdf-page-list .no-pages {
  padding: 10px;
}
