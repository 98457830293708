.snackbar-upgrade-error {
  width: fit-content;
  padding-inline: 16px;
}
.snackbar-upgrade-error .warning-icon {
  margin-right: 12px;
}
.snackbar-upgrade-error .warning-icon svg {
  color: #ffd000 !important;
}

.snackbar-upgrade-error-button {
  margin-left: 12px;
}
.snackbar-upgrade-error-button button {
  min-width: 40px !important;
}
