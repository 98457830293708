.last-application-export {
  margin: 32px 18px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-family: Roboto;
  padding: 20px 16px 12px 16px;
  border-radius: 4px;
  font-size: 14px;
}

.last-export-header {
  display: flex;
  padding-bottom: 16px;
  justify-content: space-between;
}
.last-export-header .download-button {
  border-radius: 4px !important;
}

.last-export-header-info-wrapper {
  display: flex;
}

.no-export-available {
  width: 100%;
  text-align: center;
  padding-top: 15px;
  padding-bottom: 18px;
}
.no-export-available span {
  opacity: 0.33;
  color: #000;
  font-size: 14px;
}

.export-icon-wrapper {
  width: 40px;
  height: 40px;
  background-color: rgba(65, 64, 66, 0.1);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.export-icon-wrapper .export-icon {
  transform: rotate(-90deg);
  color: #736f77 !important;
}

.last-export-header-fields {
  margin-left: 10px;
}

.exported-file span {
  font-weight: 700;
  color: rgba(0, 0, 0, 0.8);
}

.download-export {
  margin-top: 6px;
}
.download-export span {
  color: rgba(0, 0, 0, 0.66);
}

.last-application-export-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: 15px;
}

.export-metadata-field {
  margin-top: 2px;
}
.export-metadata-field span:first-child {
  color: rgba(0, 0, 0, 0.66);
}

.metadata-field-value {
  font-weight: 700;
  color: rgba(0, 0, 0, 0.8);
}

@media (max-width: 479px) {
  .last-application-export-content {
    flex-direction: column;
    align-items: flex-start !important;
  }

  .last-export-header {
    flex-direction: column;
  }
  .last-export-header .download-button {
    margin-top: 10px !important;
  }
}
