.event-list .app-dashboard-breadcrumbs {
  padding: 0 15px;
  margin-bottom: 12px;
}
@media (max-width: 767px) {
  .event-list .table-heading {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    height: unset;
  }
  .event-list .event-list-title {
    margin-bottom: 10px;
  }
  .event-list .app-dashboard-breadcrumbs {
    display: none;
  }
}
.event-list .event-list-title {
  color: #000;
  font-weight: 700;
}
.event-list .media-preview {
  margin-bottom: 56px;
}
