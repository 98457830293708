.call-notification {
  z-index: 10001;
}
.call-notification .call-notification-content {
  width: 400px !important;
}
.call-notification .call-notification-body {
  padding: 0px !important;
}
.call-notification .call-notification-title {
  background-color: #f8f9f8;
  display: flex;
  padding: 16px;
}
.call-notification .call-notification-title .user-call-title {
  flex-direction: column;
  margin-left: 16px;
  display: flex;
  justify-content: center;
}
.call-notification .call-notification-title .user-call-title .user-title {
  font-size: 24px;
  font-weight: 900;
}
.call-notification .call-toolbar {
  padding: 30px 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.call-notification .call-toolbar .call-button {
  margin: 0px 20px;
}
.call-notification .call-toolbar .reject button {
  background-color: #f32d26 !important;
}
.call-notification .call-toolbar .accept button {
  background-color: #16a843 !important;
}
