.file-upload__wrapper {
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: 62px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
.file-upload__icon-wrapper {
  padding: 5px 7px;
  width: 24px;
  height: 80%;
}
.file-upload__icon-wrapper svg {
  color: rgba(0, 0, 0, 0.5) !important;
}
.file-upload__upload-metadata {
  display: flex;
  flex-direction: column;
  height: 80%;
  flex: 2;
  justify-content: space-around;
  min-width: 0;
}
.file-upload__upload-metadata .linear-progress.error > div {
  background-color: #dd2c00 !important;
}
.file-upload__upload-metadata-filename {
  font-size: 16px;
  opacity: 0.8;
  color: black;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.file-upload__upload-status {
  display: flex;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.33);
  font-size: 12px;
}
.file-upload__cancel-upload {
  display: flex;
  align-items: center;
  padding: 8px;
}
.file-upload__cancel-upload svg {
  cursor: pointer;
  color: rgba(65, 64, 66, 0.75) !important;
}
.file-upload__cancel-upload svg:hover {
  color: #00aaed !important;
}
.file-upload__upload-failed {
  color: #dd2c00;
}
