.add-application-role {
  padding-top: 16px !important;
}
.add-application-role .add-role-content {
  max-width: 650px !important;
}
.add-application-role .dialog-title {
  background-color: #00aaed;
  color: #ffffff !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.add-application-role .dialog-title .tabs-counter {
  font-size: 13px;
  opacity: 0.6;
}
.add-application-role .add-application-role-section.role .text-fields-container {
  margin-left: 40px;
}
.add-application-role .add-application-role-section.role .text-fields-container .text-field {
  width: 100% !important;
}
.add-application-role .add-application-role-section.role .text-fields-container .text-field.error div:last-of-type {
  position: absolute !important;
  bottom: -10px !important;
}
.add-application-role .add-application-role-section.role .text-fields-container .text-field.error div:last-of-type span {
  font-family: inherit !important;
}
.add-application-role .add-application-role-section.role .text-fields-container .text-field.description {
  margin-top: 5px;
}
.add-application-role .add-application-role-section.permissions .collapse-container {
  margin-top: 15px;
}
.add-application-role .add-application-role-section.permissions .collapse-container .collapse-header .collapse-icon svg {
  color: #736f77 !important;
  transform: rotate(-90deg);
}
.add-application-role .add-application-role-section.permissions .collapse-container .collapse-header .collapse-icon.open svg {
  transform: none;
}
.add-application-role .add-application-role-section.permissions .collapse-container .collapse-header .collapse-header-text {
  font-size: 14px;
  color: #212121;
  opacity: 0.66;
  font-weight: 600;
}
.add-application-role .add-application-role-section.permissions .collapse-container .collapse-content {
  margin-left: 37px;
}
.add-application-role .add-application-role-section.permissions .collapse-container .collapse-content .application-permission {
  margin-top: 6px;
  padding-left: 3px;
}
.add-application-role .add-application-role-section.permissions .collapse-container .collapse-content .application-permission:hover {
  background-color: #F5F5F5;
}

.loading-container {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #000;
  opacity: 0.5;
  display: flex;
  z-index: 5;
  align-items: center;
  justify-content: center;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.add-application-role-section {
  margin-top: 20px;
}
.add-application-role-section .section-header {
  display: flex;
}
.add-application-role-section .section-header .section-icon svg {
  color: #00aaed !important;
}
.add-application-role-section .section-header .section-title {
  font-size: 20px;
  color: #212121;
  opacity: 0.66;
  margin-left: 15px;
}
