.application-permission {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: default;
}
.application-permission .description-text {
  font-size: 13px;
  color: #212121;
  display: flex;
  align-items: center;
}
.application-permission .application-permissions-container {
  display: flex;
}
.application-permission .application-permissions-container .top-label {
  position: absolute;
  top: 5px;
  font-size: 13px;
}
.application-permission .application-permissions-container .top-label.participants-only {
  right: 55px;
}
.application-permission .application-permissions-container .top-label.all {
  right: 5px;
}
.application-permission .application-permissions-container.multiple-permissions {
  flex-direction: row;
  justify-content: space-between;
  min-width: 130px;
}
.application-permission .sub-permission-icon {
  color: #212121 !important;
  width: 16px !important;
  height: 16px !important;
  margin-left: 20px;
  margin-right: 5px;
}
@media (max-width: 767px) {
  .application-permission .application-permissions-container.multiple-permissions {
    min-width: 85px !important;
  }
  .application-permission .application-permissions-container .top-label.participants-only {
    right: 45px;
    top: -20px;
    width: 55px;
    text-align: center;
  }
}
