.call-data .call-data-details {
  height: 100px;
}
@media (max-width: 767px) {
  .call-data .call-data-details {
    display: none;
  }
}
.call-data .call-data-details .call-details {
  flex-direction: row;
}
.call-data .call-data-details .call-details .detail-item {
  margin-right: 20px;
}
.call-data .call-data-details .call-details .detail-item .detail-item-value span {
  font-size: 16px !important;
  color: #000000;
}
