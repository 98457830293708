.version-list .version-list-title {
  color: #000;
  font-weight: 700;
}
@media (max-width: 767px) {
  .version-list .table-heading {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    height: unset;
  }
  .version-list .table-heading .actions {
    justify-content: flex-start;
    width: 100%;
  }
  .version-list .version-list-title {
    margin-bottom: 10px;
  }
}
.version-list .app-versions-table .export svg {
  transform: rotate(-90deg);
}
.version-list .app-versions-table .table-footer .footer-actions {
  display: flex;
}
.version-list .app-versions-table .table-footer .footer-actions .delete svg {
  color: #dd2c00 !important;
}
.version-list .app-versions-table .version-row.pending td, .version-list .app-versions-table .version-row.upgrade_pending td {
  color: rgba(0, 0, 0, 0.6);
}
.version-list .app-versions-table .actions {
  justify-content: flex-end;
  text-align: right;
}
.version-list .app-versions-table .actions .item-action-button {
  padding: 7px !important;
  width: 34px !important;
  height: 34px !important;
}

.table tr.errored-upgrade td {
  color: #dd2c00;
}

.table table tr:not(.selected):hover td.actions .delete svg,
.table table tr.selected:hover td.actions .delete svg {
  color: #dd2c00 !important;
}
