.call-snackbar {
  font-size: 14px;
  bottom: 0px !important;
  z-index: 2000 !important;
  position: fixed;
  min-width: 300px;
  max-width: 90%;
  height: 42px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #736F77;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  margin-bottom: -70px;
  transition: all 500ms ease;
  left: 50%;
  transform: translateX(-50%);
  padding: 0px 16px;
}
.call-snackbar.error {
  background-color: #dd2c00;
}
.call-snackbar.open {
  margin-bottom: 0px;
}
.call-snackbar:hover {
  box-shadow: 0 0 3px 0 rgba(33, 33, 33, 0.5);
}
.call-snackbar .snackbar-action {
  margin-left: 50px;
  margin-right: 30px;
}
.call-snackbar .snackbar-action button {
  color: #fff !important;
}
.call-snackbar .close {
  margin-left: auto !important;
}
