.settings-wrapper {
  display: flex;
}
.settings-wrapper .profile-settings {
  flex: 1;
  display: flex;
  margin-bottom: 70px;
  flex-direction: column;
  width: fit-content;
}
.settings-wrapper .profile-settings form {
  display: flex;
  flex-direction: column;
}
.settings-wrapper .profile-settings .server-error {
  color: #f44336;
  font-size: 16px;
  text-align: center;
  line-height: 16px;
}
.settings-wrapper .footer-action-buttons {
  position: fixed;
  width: 100%;
  bottom: 0;
  right: 0;
  background-color: #fff;
  z-index: 2;
  box-shadow: none;
  border-top: 1px solid #ededed;
}
.settings-wrapper .footer-action-buttons .footer-action-buttons-wrapper {
  margin: 0 10px;
}
