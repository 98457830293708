.user-details {
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.user-details .buttons-container {
  margin: 20px 0;
}
.user-details .form {
  display: flex;
  flex-direction: column;
}
.user-details .user-details-image-container {
  margin-bottom: 20px;
}
.user-details .outlined-field {
  max-width: 100%;
}
.user-details .outlined-field.disabled {
  cursor: default;
}
.user-details .user-image {
  width: 80px;
  height: 80px;
  border-radius: 4px;
}
.user-details .user-image img {
  border-radius: 4px;
}
.user-details .cursor-tooltip {
  display: flex;
}
.user-details .reset-password-button {
  flex: 1;
  border-radius: 4px !important;
}
.user-details .reset-password-button button {
  border-radius: 4px !important;
}
.user-details .group-membership-next-gen {
  position: absolute;
  padding: 5px 8px 0px 8px;
  right: 0;
  left: 0;
}
.user-details .group-membership-next-gen button {
  padding: 0 !important;
  width: 35px !important;
}
.user-details .group-membership-next-gen button svg.items-picker-arrow {
  width: 24px !important;
  height: 24px !important;
}
