.template-selection-container {
  padding-top: 20px;
}
.template-selection-container .create-new-app-invitation {
  color: #212121;
  opacity: 0.6;
  font-size: 16px;
  font-weight: 700;
}
.template-selection-container .start-app-development {
  font-size: 14px;
  margin-top: 10px;
  color: #212121;
  opacity: 0.6;
}
.template-selection-container .templates-wrapper {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.template-selection-container .templates-wrapper .template-item {
  display: flex !important;
  box-sizing: border-box;
  cursor: pointer;
  margin-top: 10px !important;
  height: 80px !important;
  line-height: normal !important;
}
.template-selection-container .templates-wrapper .template-item div:first-child {
  display: flex;
}
.template-selection-container .templates-wrapper .template-item div:first-child .template-item-icon-wrapper {
  width: 80px;
  height: 80px;
  background-color: rgba(65, 64, 66, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}
.template-selection-container .templates-wrapper .template-item div:first-child .template-item-icon-wrapper svg {
  width: 48px !important;
  height: 48px !important;
  color: #736f77 !important;
  z-index: 9999;
}
.template-selection-container .templates-wrapper .template-item div:first-child .template-item-info {
  margin-left: 15px;
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 168px;
  padding-right: 15px;
}
.template-selection-container .templates-wrapper .template-item div:first-child .template-item-info .template-item-name {
  font-weight: 700;
}
.template-selection-container .templates-wrapper .template-item div:first-child .template-item-info .template-item-description {
  padding-top: 2px;
  opacity: 0.33;
  text-align: left;
}
