.recover-password-request .login-large-button {
  margin-top: 30px;
}

.username-text-field {
  width: 100%;
  margin-top: 55px;
}
.username-text-field .underline-text {
  margin-top: 4px;
  margin-left: 10px;
}

.we-all-forget-things {
  font-size: 16px;
  opacity: 0.66;
}

.contact-admin-tip {
  margin-top: 24px;
  font-size: 12px;
  opacity: 0.66;
  text-align: center;
}
