.field-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.field-section > span {
  font-size: 18px;
  font-family: "Roboto Bold", sans-serif;
  color: rgba(33, 33, 33, 0.66);
}
