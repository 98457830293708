body {
  margin: 0;
  padding: 0;
}

.call {
  display: none;
}
.call.active {
  display: block;
  color: #fff;
  position: absolute;
  z-index: 9999;
  width: 100%;
  height: 100%;
}
.call .call-chat {
  position: absolute;
  z-index: 10000;
  height: 322px;
  width: 286px;
  bottom: 0;
  right: 20px;
}
.call .chat-unread {
  position: absolute;
  z-index: 10000;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 375px;
}
