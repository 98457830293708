.call-reports .call-reports-switcher {
  position: absolute;
  right: 15px;
  top: 15px;
  display: flex;
  align-items: center;
}
.call-reports .call-reports-switcher .call-reports-switcher-label {
  margin-right: 10px;
}
@media (max-width: 767px) {
  .call-reports .call-reports-switcher {
    position: static;
    margin: 20px;
  }
}
