.outlined-password-field fieldset {
  display: flex;
  justify-content: center;
  align-items: center;
}
.outlined-password-field .underline-text {
  margin-top: 4px;
  margin-left: 10px;
}
.outlined-password-field .underline-text span {
  font-family: Roboto !important;
}
.outlined-password-field .eye-icon {
  margin-bottom: 3px;
  padding-left: 5px;
}
.outlined-password-field .eye-icon:hover {
  cursor: pointer;
}
.outlined-password-field .eye-on {
  color: rgba(65, 64, 66, 0.33) !important;
}
.outlined-password-field .eye-on:hover {
  color: rgba(65, 64, 66, 0.66) !important;
}
.outlined-password-field .eye-off {
  color: rgba(65, 64, 66, 0.66) !important;
}
