.breadcrumbs {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.breadcrumbs .breadcrumbs-transition-icon {
  margin: 0 5px;
}
.breadcrumbs > .common-tooltip {
  display: inline-flex !important;
  width: initial !important;
}
.breadcrumbs > .common-tooltip > .breadcrumbs-item {
  font-size: 20px;
  color: rgba(255, 255, 255, 0.7);
}
.breadcrumbs > .common-tooltip > .breadcrumbs-item a {
  text-decoration: none;
  padding: 2px 3px;
  color: rgba(255, 255, 255, 0.7);
}
.breadcrumbs > .common-tooltip > .breadcrumbs-item:hover a {
  border-radius: 4px;
}
.breadcrumbs > .common-tooltip > div:last-child {
  top: 20px !important;
  left: -5px !important;
  width: fit-content;
}
.breadcrumbs > .common-tooltip:last-of-type .breadcrumbs-item {
  opacity: 1;
}
.breadcrumbs > .common-tooltip:last-of-type .breadcrumbs-item a, .breadcrumbs > .common-tooltip:last-of-type .breadcrumbs-item span {
  color: #000;
}
