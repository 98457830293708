@media (min-width: 1024px) {
  .privacy-anonymous-info .privacy-anonymous-info-content {
    width: 40% !important;
  }
}
.privacy-anonymous-info .privacy-anonymous-info-content .privacy-anonymous-info-title {
  display: inline-flex;
  align-items: center;
}
.privacy-anonymous-info .privacy-anonymous-info-content .privacy-anonymous-info-title > span {
  font-size: 20px;
}
.privacy-anonymous-info .privacy-anonymous-info-content .privacy-anonymous-info-title > svg {
  margin-right: 10px;
  color: #736F77 !important;
}
.privacy-anonymous-info .privacy-anonymous-info-content .privacy-anonymous-info-body > span {
  font-size: 13px;
}
