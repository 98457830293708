.temporary-password-content .temporary-password-dialog-title {
  background-color: #00aaed;
  color: #fff !important;
  display: flex;
  align-items: center;
}
.temporary-password-content .temporary-password-dialog-title .dialog-icon {
  margin-right: 10px;
}
.temporary-password-content .temporary-password-body {
  padding: 24px 60px !important;
}
.temporary-password-content .temporary-password-body .temporary-password-body-container .step-buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;
}
