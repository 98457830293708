.add-device-wrapper .media-lightbox {
  z-index: 1501;
}

.add-device-modal {
  height: 90%;
}
.add-device-modal .dialog-title {
  padding: 22px 22px 18px !important;
}
.add-device-modal .add-device-content {
  margin-top: -60px !important;
}
.add-device-modal .add-device-content .qr-code {
  margin: 30px 0;
  text-align: center;
}
.add-device-modal .add-device-content .timer-message {
  font-size: 18px;
  text-align: center;
  padding-bottom: 15px;
}

@media (max-width: 479px) {
  .add-device-modal .add-device-content {
    width: 100% !important;
  }
  .add-device-modal .add-device-content canvas {
    width: 100% !important;
    height: 100% !important;
  }
}
