.copy-area {
  background-color: #ededed;
  font-size: 16px;
  padding: 10px;
  margin: 20px 0;
  display: flex;
  align-items: center;
  border: 1px #efefef solid;
}
.copy-area .copy-text {
  text-align: left;
  word-wrap: break-word;
  overflow-y: auto;
}
.copy-area .copy-btn {
  margin-left: auto !important;
  cursor: pointer;
  padding-right: 0 !important;
}
.copy-area .copy-btn svg {
  fill: #BBBEC0 !important;
}
