.user-details {
  padding: 12px 24px;
}
.user-details > .form {
  display: flex;
  flex-direction: column;
}
.user-details > .form > div {
  width: 100% !important;
}
.user-details > .form .user-image {
  width: 128px !important;
}
.user-details .read-only-field {
  cursor: text !important;
}
.user-details .read-only-field div, .user-details .read-only-field label {
  cursor: text !important;
}
.user-details input:disabled {
  -webkit-text-fill-color: #212121 !important;
}
.user-details .type-select div > div:nth-child(1) > div:nth-child(2) {
  -webkit-text-fill-color: #212121 !important;
}
.user-details .type-select button[disabled] {
  display: none !important;
}
.user-details .details-item {
  padding-top: 15px;
}
.user-details .details-item .title {
  padding-bottom: 5px;
  display: block;
  font-size: 12px;
  color: rgba(33, 33, 33, 0.33);
}
.user-details .details-item .value {
  display: block;
  font-size: 16px;
  color: #212121;
  line-height: 18px;
  overflow: hidden;
}
.user-details .details-item .value .group-membership > .menu-picker {
  width: calc(100% - 44px) !important;
}
.user-details .details-item .value .group-membership > .menu-picker > div > button {
  right: 2px !important;
}
.user-details .details-item .value .group-membership > .menu-picker > div > button + div {
  margin: 0 !important;
  border-top: 1px solid #e0e0e0 !important;
  bottom: 5px !important;
}
.user-details .reset-pwd {
  display: flex;
  flex-direction: column;
}
.user-details .reset-password {
  width: 100%;
  text-align: left;
  margin: 25px 0;
  justify-content: flex-start !important;
}
.user-details .autocomplete {
  width: 0px;
  height: 0px;
  position: absolute;
  z-index: -1;
}
