.next-gen-application-list .table-heading {
  box-shadow: none;
  top: 0;
  background-color: #ffffff;
}
.next-gen-application-list .item-action-button > div > div {
  pointer-events: none;
}
.next-gen-application-list .item-action-button:hover {
  color: #00aaed !important;
}
.next-gen-application-list .item-action-button:hover .material-icon {
  color: #00aaed !important;
}
.next-gen-application-list .grid-footer {
  z-index: 5;
}
.next-gen-application-list .loading-wrapper {
  height: calc(100vh - 56px);
  background-color: #f6f6f6;
}
.next-gen-application-list .action-button {
  padding: 2px !important;
}
.next-gen-application-list .action-button.icon {
  margin-left: 0 4px !important;
  padding-bottom: 0px !important;
  border-radius: 4px !important;
}
.next-gen-application-list .action-button.icon:hover {
  background-color: #ecebec !important;
}
.next-gen-application-list .action-button.icon.skylight-icon {
  margin: 0 2px !important;
}
.next-gen-application-list .select-all-checkbox {
  width: 24px !important;
  height: 24px !important;
  display: none !important;
}
.next-gen-application-list .select-all-checkbox div div div svg:first-child {
  fill: #9e9e9e !important;
}
.next-gen-application-list .select-all-checkbox.checked {
  display: block !important;
}
.next-gen-application-list .actions .action-button.icon.skylight-icon {
  margin: 0 12px !important;
}
.next-gen-application-list .empty-list-view .application-icon {
  color: #bbbec0 !important;
}
@media (max-width: 767px) {
  .next-gen-application-list .table-heading {
    flex-direction: column-reverse;
    height: initial;
  }
  .next-gen-application-list .table-heading .actions {
    flex-direction: row-reverse;
    width: 100%;
    align-items: center;
    margin: 5px 0;
  }
  .next-gen-application-list .table-heading .actions .search-field {
    padding: 9px 14px 9px 38px;
  }
}
