.password-reset-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-family: "Roboto", sans-serif;
}

.password-reset-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 310px;
}
.password-reset-content .skylight-logo {
  max-width: 100%;
  max-height: 100%;
  width: 140px;
}
.password-reset-content .divider {
  width: 85%;
  margin: 12px 0 12px 0 !important;
  background-color: rgba(65, 64, 66, 0.05) !important;
}
.password-reset-content .captcha-badge {
  font-size: 12px;
  opacity: 0.66;
  margin-top: 24px;
}

.password-reset-for {
  font-size: 20px;
  font-family: Roboto Bold;
  opacity: 0.8;
  margin-top: 24px;
  text-align: center;
}
.password-reset-for span {
  word-break: break-word;
}

.password-reset-field {
  margin-top: 14px;
  width: 100%;
}
.password-reset-field.password-field .underline-text span {
  display: none;
}
.password-reset-field.generic-requirements-error .underline-text {
  margin-top: 4px;
  margin-left: 10px;
}
.password-reset-field.generic-requirements-error .underline-text span {
  display: block;
}
.password-reset-field.password-confirmation-field .underline-text {
  margin-top: 4px;
  margin-left: 10px;
}

.password-requirements {
  margin-left: 15px;
}
.password-requirements span {
  display: block;
  opacity: 0.66;
  font-size: 12px;
}

.change-password-btn {
  margin-top: 24px;
}
.change-password-btn .login-large-button-label span {
  text-transform: uppercase;
  font-size: 14px;
}

.link-expired {
  opacity: 0.66;
  text-align: center;
}

.send-new-link-button {
  margin-top: 34px;
}

.need-additional-help, .contact-your-admin {
  display: block;
  margin-top: 24px;
  color: rgba(0, 0, 0, 0.66);
  font-size: 12px;
  text-align: center;
}

.contact-your-admin {
  margin-top: 8px;
  font-family: Roboto Bold;
}
