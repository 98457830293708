.add-to-existing-group-dialog .dialog-content-wrapper > div {
  width: 680px !important;
  background-color: #ffffff;
  box-shadow: 0 0 13px 0 rgba(33, 33, 33, 0.5);
  border-radius: 2px;
}
.add-to-existing-group-dialog .dialog-body-wrapper {
  padding: 0 !important;
  height: calc(100% - 56px);
  overflow-y: initial !important;
}
.add-to-existing-group-dialog .add-to-existing-group-dialog-title-wrapper {
  width: 100%;
  height: 56px;
  display: flex;
  background-color: #00aaed;
  justify-content: space-between;
  align-items: center;
}
.add-to-existing-group-dialog .add-to-existing-group-dialog-title-wrapper .add-to-existing-group-dialog-title {
  display: flex;
  margin-left: 24px;
}
.add-to-existing-group-dialog .add-to-existing-group-dialog-title-wrapper .add-to-existing-group-dialog-title p {
  font-size: 20px;
  color: #ffffff;
  line-height: 22px;
  font-weight: 500;
}
.add-to-existing-group-dialog .add-to-existing-group-dialog-title-wrapper .dialog-search-filter-wrapper {
  margin-right: 36px;
}
.add-to-existing-group-dialog .add-to-existing-group-dialog-title-wrapper .dialog-search-filter-wrapper .text-filter svg,
.add-to-existing-group-dialog .add-to-existing-group-dialog-title-wrapper .dialog-search-filter-wrapper .text-filter path {
  color: #bbbec0 !important;
}
.add-to-existing-group-dialog .add-to-existing-group-dialog-title-wrapper .dialog-search-filter-wrapper .text-filter .label {
  height: 32px;
  width: 288px;
}
.add-to-existing-group-dialog .add-to-existing-group-dialog-title-wrapper .dialog-search-filter-wrapper .text-filter .label .search-field {
  border-radius: 5px;
}
.add-to-existing-group-dialog .selected-users-wrapper {
  display: flex;
  padding: 28px 0 0 24px;
}
.add-to-existing-group-dialog .user-select-line {
  position: absolute;
  left: 36px;
  bottom: 64px;
  height: calc(100% - 180px);
  width: 1px;
  background-color: #ededed;
}
.add-to-existing-group-dialog .group-select-line {
  position: absolute;
  left: 36px;
  top: 118px;
  height: 20px;
  width: 1px;
  background: #ededed;
}
.add-to-existing-group-dialog .footer-wrapper {
  display: flex;
  padding-left: 24px;
  padding-bottom: 30px;
}
.add-to-existing-group-dialog .footer-wrapper .footer-content {
  display: flex;
}
.add-to-existing-group-dialog .picker-wrapper {
  margin: 5px 36px 0 57px;
  max-height: 175px;
}
.add-to-existing-group-dialog .picker-wrapper .chip-container {
  max-height: 100px;
  overflow: auto;
  margin-bottom: 10px;
}
.add-to-existing-group-dialog .picker-wrapper .chip-container .chip.user {
  height: 32px !important;
  padding-bottom: 5px 0;
}
.add-to-existing-group-dialog .picker-wrapper .people-picker {
  min-height: 52px;
  width: 100% !important;
}
.add-to-existing-group-dialog .dialog-users-action-wrapper {
  display: flex;
  justify-content: flex-end;
  margin: 23.5px 0 10px 0;
}
.add-to-existing-group-dialog .dialog-users-action-wrapper .cancel-button {
  text-transform: uppercase;
  font-family: "Roboto Medium", sans-serif !important;
  font-size: 14px !important;
  color: rgba(33, 33, 33, 0.66) !important;
  padding: 0 15px !important;
  font-weight: 500 !important;
  margin-right: 8px !important;
}
.add-to-existing-group-dialog .dialog-users-action-wrapper .continue-button {
  text-transform: uppercase;
  font-family: "Roboto Medium", sans-serif !important;
  font-size: 14px !important;
  color: #ffffff !important;
  background-color: #00aaed !important;
  border-radius: 5px !important;
  padding: 0 15px !important;
}
.add-to-existing-group-dialog .dialog-users-action-wrapper .continue-button.disabled {
  opacity: 0.5;
  pointer-events: none;
  background-color: #e5e5e5 !important;
  color: #b6b6b6 !important;
}
.add-to-existing-group-dialog .dialog-users-action-wrapper button.continue-button:hover {
  background-color: #71cdf4 !important;
}
.add-to-existing-group-dialog .dialog-group-action-wrapper {
  display: flex;
  justify-content: flex-end;
  margin: 24px 47px 0 0;
}
.add-to-existing-group-dialog .dialog-group-action-wrapper .cancel-button {
  text-transform: uppercase;
  font-family: "Roboto Medium", sans-serif !important;
  font-size: 14px !important;
  color: rgba(33, 33, 33, 0.66) !important;
  padding: 0 15px !important;
  font-weight: 500 !important;
  margin-right: 8px !important;
}
.add-to-existing-group-dialog .dialog-group-action-wrapper .continue-button {
  text-transform: uppercase;
  font-family: "Roboto Medium", sans-serif !important;
  font-size: 14px !important;
  color: #ffffff !important;
  background-color: #00aaed !important;
  border-radius: 5px !important;
  padding: 0 15px !important;
}
.add-to-existing-group-dialog .dialog-group-action-wrapper .continue-button.disabled {
  opacity: 0.5;
  pointer-events: none;
  background-color: #e5e5e5 !important;
  color: #b6b6b6 !important;
}
.add-to-existing-group-dialog .dialog-group-action-wrapper button.continue-button:hover {
  background-color: #71cdf4 !important;
}
.add-to-existing-group-dialog .pick-groups-wrapper .selected-groups-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 18px 0 5px 24px;
}
.add-to-existing-group-dialog .pick-groups-wrapper .selected-groups-wrapper .selected-item {
  display: flex;
  padding: 15px 0;
}
.add-to-existing-group-dialog .pick-groups-wrapper .selected-groups-wrapper .selected-item .circle-wrapper {
  display: flex;
}
.add-to-existing-group-dialog .pick-groups-wrapper .selected-groups-wrapper .selected-item .circle-wrapper .circle-number-blue {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  line-height: 24px;
  background-color: #00aaed;
  color: #ffffff;
  text-align: center;
  font-size: 18px;
}
.add-to-existing-group-dialog .pick-groups-wrapper .selected-groups-wrapper .selected-item .circle-wrapper .circle-number-grey {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  line-height: 24px;
  background-color: #bbbec0;
  color: #ffffff;
  text-align: center;
  font-size: 18px;
}
.add-to-existing-group-dialog .pick-groups-wrapper .selected-groups-wrapper .selected-item .circle-number-text {
  margin-left: 8px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #212121;
  text-align: left;
}
.add-to-existing-group-dialog .pick-groups-wrapper .table-wrapper {
  border: 1px solid #ededed;
  width: 584px;
  margin: 0 auto;
}
.add-to-existing-group-dialog .pick-groups-wrapper .table-wrapper .table tbody {
  max-height: 300px;
  padding-bottom: 0px;
}
.add-to-existing-group-dialog .pick-groups-wrapper .table-wrapper .table .empty-label-container {
  display: flex;
  margin: 20px;
  justify-content: center;
}
.add-to-existing-group-dialog .pick-groups-wrapper .table-wrapper th,
.add-to-existing-group-dialog .pick-groups-wrapper .table-wrapper thead {
  display: none;
}
.add-to-existing-group-dialog .pick-users-wrapper .selected-users-wrapper .circle-wrapper {
  display: flex;
}
.add-to-existing-group-dialog .pick-users-wrapper .selected-users-wrapper .circle-wrapper .circle-number-blue {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  line-height: 24px;
  background-color: #00aaed;
  color: #ffffff;
  text-align: center;
  font-size: 18px;
}
.add-to-existing-group-dialog .pick-users-wrapper .selected-users-wrapper .circle-wrapper .circle-number-grey {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  line-height: 24px;
  background-color: #bbbec0;
  color: #ffffff;
  text-align: center;
  font-size: 18px;
}
.add-to-existing-group-dialog .pick-users-wrapper .selected-users-wrapper .circle-number-text {
  margin-left: 8px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #212121;
  text-align: left;
}
.add-to-existing-group-dialog .pick-users-wrapper .footer-wrapper .footer-content .circle-wrapper {
  display: flex;
}
.add-to-existing-group-dialog .pick-users-wrapper .footer-wrapper .footer-content .circle-wrapper .circle-number-blue {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  line-height: 24px;
  background-color: #00aaed;
  color: #ffffff;
  text-align: center;
  font-size: 18px;
}
.add-to-existing-group-dialog .pick-users-wrapper .footer-wrapper .footer-content .circle-wrapper .circle-number-grey {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  line-height: 24px;
  background-color: #bbbec0;
  color: #ffffff;
  text-align: center;
  font-size: 18px;
}
.add-to-existing-group-dialog .pick-users-wrapper .footer-wrapper .footer-content .circle-number-text {
  margin-left: 8px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: rgba(33, 33, 33, 0.33);
  text-align: left;
}

.error.snackbar-common-success > div {
  background-color: #00b355 !important;
}
.error.snackbar-common-success > div > div span {
  color: #ffffff !important;
}
