.server-section {
  padding-bottom: 60px;
}
.server-section .config-radio-group-container {
  display: flex;
  flex-direction: row;
}
.server-section .config-radio-group-container .config-radio-group {
  display: flex;
  flex-direction: column;
  flex: auto;
}
.server-section .config-radio-group-container .config-radio-group > div > div > label > span {
  color: #212121;
}
.server-section .config-radio-group-container .add-server-container {
  display: flex;
  align-items: flex-end;
  padding-bottom: 20px;
}
.server-section .config-radio-group-container .add-server-container .add-server-button path,
.server-section .config-radio-group-container .add-server-container .add-server-button svg {
  color: #00a0ea !important;
}
.server-section .config-radio-group-container .add-server-container .add-server-button path:hover,
.server-section .config-radio-group-container .add-server-container .add-server-button svg:hover {
  color: #66c6f2 !important;
}
