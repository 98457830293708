.version-stage {
  max-width: 75px;
  text-transform: capitalize;
}
.version-stage.published {
  background-color: #DD48C0;
  color: #fff;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  padding: 5px;
  border-radius: 3px;
  text-transform: uppercase;
  font-size: 11px;
  text-align: center;
}
