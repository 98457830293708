.setup-wifi-dialog .setup-wifi-content {
  margin-top: -50px !important;
}
.setup-wifi-dialog .setup-wifi-content .setup-wifi-body {
  height: 80vh;
}
.setup-wifi-dialog .setup-wifi-content .setup-wifi-body.qr-body {
  max-height: none !important;
  height: initial;
}
.setup-wifi-dialog .setup-wifi-footer {
  position: relative;
  z-index: 1;
  box-shadow: 0 -1px 4px -1px rgba(0, 0, 0, 0.75);
}
.setup-wifi-dialog .dialog-title {
  background-color: #00aaed;
  padding: 16px 32px !important;
  line-height: 1 !important;
}
.setup-wifi-dialog .dialog-title > span {
  color: #fff;
  font-size: 16px;
}
.setup-wifi-dialog .setup-wifi-editor {
  padding: 24px 8px;
}
.setup-wifi-dialog .setup-wifi-editor .setup-wifi-editor-title > span {
  display: block;
}
.setup-wifi-dialog .setup-wifi-editor .setup-wifi-editor-title > span:first-child {
  font-size: 20px;
  color: #212121;
  line-height: 22px;
  margin-bottom: 8px;
}
.setup-wifi-dialog .setup-wifi-editor .setup-wifi-editor-title > span:nth-child(2) {
  font-size: 14px;
  color: rgba(33, 33, 33, 0.66);
}
.setup-wifi-dialog .setup-wifi-editor .setup-wifi-editor-body {
  display: flex;
  flex-direction: column;
  padding: 0 12px;
}
.setup-wifi-dialog .setup-wifi-editor .setup-wifi-editor-body > .add-select-wrapper {
  width: 60%;
  display: flex;
  flex-direction: column;
}
.setup-wifi-dialog .setup-wifi-editor .setup-wifi-editor-body > .add-select-wrapper > .setup-wifi-editor-field {
  min-width: 100%;
}
.setup-wifi-dialog .setup-wifi-editor .setup-wifi-editor-body > .add-select-wrapper > .setup-wifi-editor-field label {
  color: rgba(0, 0, 0, 0.66) !important;
}
.setup-wifi-dialog .setup-wifi-editor .setup-wifi-editor-body > .add-select-wrapper > .setup-wifi-editor-field button svg {
  fill: rgba(0, 0, 0, 0.66) !important;
}
.setup-wifi-dialog .setup-wifi-editor .setup-wifi-editor-body > .add-select-wrapper > .add-network-button {
  max-width: 120px !important;
  margin-top: 24px;
  border-radius: 4px !important;
}
.setup-wifi-dialog .setup-wifi-editor .setup-wifi-editor-body > .add-select-wrapper > .add-network-button > button {
  border-radius: 4px !important;
}
.setup-wifi-dialog .setup-wifi-editor .setup-wifi-editor-body > .add-select-wrapper > .add-network-button span {
  padding: 0 !important;
}
.setup-wifi-dialog .setup-wifi-editor .setup-wifi-editor-body > .setup-wifi-editor-field {
  min-width: 60%;
}
.setup-wifi-dialog .setup-wifi-editor .setup-wifi-editor-body .setup-wifi-editor-expandable-container {
  box-shadow: none !important;
  min-width: 60%;
  max-width: 256px;
  margin-top: 12px;
}
.setup-wifi-dialog .setup-wifi-editor .setup-wifi-editor-body .setup-wifi-editor-expandable-container .setup-wifi-editor-expandable-header {
  padding: 16px 0 !important;
}
.setup-wifi-dialog .setup-wifi-editor .setup-wifi-editor-body .setup-wifi-editor-expandable-container .setup-wifi-editor-expandable-header > button {
  margin: 0 !important;
  padding: 12px 0 !important;
  right: 0 !important;
}
.setup-wifi-dialog .setup-wifi-editor .setup-wifi-editor-body .setup-wifi-editor-expandable-container .setup-wifi-editor-expandable-header > button svg {
  color: #736f77 !important;
  position: relative;
  top: 3px;
}
.setup-wifi-dialog .setup-wifi-editor .setup-wifi-editor-body .setup-wifi-editor-expandable-container .setup-wifi-editor-expandable-header span {
  font-size: 16px;
}
.setup-wifi-dialog .setup-wifi-editor .setup-wifi-editor-body .setup-wifi-editor-expandable-container .setup-wifi-editor-expandable-content {
  display: flex;
  flex-direction: column;
  padding: 0 0 0 12px !important;
}
.setup-wifi-dialog .setup-wifi-editor .setup-wifi-editor-body .setup-wifi-editor-expandable-container .setup-wifi-editor-expandable-content > .setup-wifi-editor-field {
  min-width: 100%;
}
.setup-wifi-dialog .setup-wifi-editor .setup-wifi-editor-body .setup-wifi-editor-expandable-container .setup-wifi-editor-expandable-content > .setup-wifi-editor-field.checkbox {
  margin: 8px 0;
}
.setup-wifi-dialog .setup-wifi-editor .setup-wifi-editor-body .setup-wifi-editor-expandable-container .setup-wifi-editor-expandable-content > .setup-wifi-editor-field.checkbox label {
  font-size: 16px;
}
.setup-wifi-dialog .setup-wifi-editor .setup-wifi-editor-body .setup-wifi-editor-expandable-container .setup-wifi-editor-expandable-content > .setup-wifi-editor-field.checkbox svg:first-child {
  fill: #bbbec0 !important;
}
.setup-wifi-dialog .setup-wifi-editor .setup-wifi-editor-body .setup-wifi-editor-expandable-container .setup-wifi-editor-expandable-content > .setup-wifi-editor-field.checkbox svg:last-child {
  fill: #24c6fc !important;
}
.setup-wifi-dialog .setup-wifi-qr-code {
  margin-top: 24px;
  text-align: center;
}

.setup-wifi-editor-menu-item {
  font-size: 13px !important;
}
.setup-wifi-editor-menu-item .wifi-editor-delete-network-button {
  padding: 0 4px !important;
}
.setup-wifi-editor-menu-item .wifi-editor-delete-network-button > div:first-child > div {
  top: 6px !important;
  right: 20px !important;
  border-radius: 4px !important;
  pointer-events: none;
}
.setup-wifi-editor-menu-item .wifi-editor-delete-network-button + div {
  overflow: hidden;
  text-overflow: ellipsis;
}
.setup-wifi-editor-menu-item .setup-wifi-editor-delete-icon {
  display: none !important;
  fill: #fff !important;
  background-color: #bbbec0;
  border-radius: 12px;
  width: 20px !important;
  height: 20px !important;
}
.setup-wifi-editor-menu-item.add-new {
  box-shadow: 0px -1px 0px 0px #e5e5e5;
}
.setup-wifi-editor-menu-item:hover {
  background-color: #f5f5f5 !important;
}
.setup-wifi-editor-menu-item:hover .setup-wifi-editor-delete-icon {
  display: block !important;
}
