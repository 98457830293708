.admin-privacy {
  padding: 20px;
}
.admin-privacy .admin-privacy-button {
  display: flex !important;
  width: 220px;
  height: 50px;
}
.admin-privacy .admin-radio-group.privacy-radio-group .privacy-radio-button > div > div {
  display: none !important;
}
.admin-privacy .admin-radio-group.privacy-radio-group .privacy-radio-button:nth-child(2) .privacy-button-title {
  margin-bottom: 15px;
}
.admin-privacy .admin-radio-group.privacy-radio-group .admin-privacy-button {
  box-shadow: none !important;
  margin-bottom: 5px;
  margin-top: 5px;
}
.admin-privacy .admin-radio-group.privacy-radio-group .admin-privacy-button .admin-privacy-button-body {
  border: 1px #dfdfdf solid;
  border-radius: 3px;
  display: flex;
  height: 50px;
  justify-content: space-around;
  align-items: center;
  font-size: 14px;
}
.admin-privacy .admin-radio-group.privacy-radio-group .admin-privacy-button .admin-privacy-button-body span {
  color: #de2d01 !important;
}
.admin-privacy .admin-radio-group.privacy-radio-group .admin-privacy-button .admin-privacy-button-body:hover {
  border: 2px #dfdfdf solid;
}
.admin-privacy .admin-radio-group.privacy-radio-group .admin-privacy-button .admin-privacy-button-body:hover span {
  color: #C02319 !important;
}
.admin-privacy .admin-radio-group.privacy-radio-group .admin-privacy-button .admin-privacy-button-body:hover svg.delete-icon {
  fill: #C02319 !important;
}
.admin-privacy .admin-radio-group.privacy-radio-group .admin-privacy-button div {
  background-color: #fff !important;
}
.admin-privacy .admin-radio-group.privacy-radio-group input {
  z-index: 1 !important;
}
.admin-privacy .admin-radio-group.privacy-radio-group > div > div {
  padding: 0 36px;
  align-items: center;
}
.admin-privacy .admin-radio-group.privacy-radio-group > div > div label {
  padding: 12px 0;
}
.admin-privacy .admin-radio-group.privacy-radio-group > div > div label .privacy-button-label {
  display: flex;
  align-items: center;
  align-items: flex-start;
  flex-direction: column;
}
.admin-privacy .admin-radio-group.privacy-radio-group > div > div label .privacy-button-label .privacy-button-body-container {
  margin-left: 40px;
}
.admin-privacy .admin-radio-group.privacy-radio-group > div > div label .privacy-button-label .privacy-button-title-container {
  display: flex;
  align-items: center;
}
.admin-privacy .admin-radio-group.privacy-radio-group > div > div label .privacy-button-label .privacy-button-title-container .privacy-button-title > span:first-child {
  font-weight: 600;
  color: initial;
  display: block;
}
.admin-privacy .admin-radio-group.privacy-radio-group > div > div label .privacy-button-label .privacy-button-title-container .privacy-learn-more {
  display: flex;
  position: absolute;
  margin-left: -5px;
  z-index: 2;
  white-space: nowrap;
}
.admin-privacy .admin-radio-group.privacy-radio-group > div > div label .privacy-button-label .privacy-button-title-container .privacy-learn-more > span {
  color: #00aaed;
  padding-left: 5px;
  cursor: pointer;
}
.admin-privacy .admin-radio-group.privacy-radio-group > div > div label .privacy-button-label .privacy-button-radio-icon {
  margin-right: 16px;
  color: rgba(33, 33, 33, 0.33) !important;
  width: 24px !important;
  height: 24px !important;
}
.admin-privacy .admin-radio-group.privacy-radio-group > div > div label .privacy-button-label .privacy-button-radio-icon.checked {
  color: #00aaed !important;
}
