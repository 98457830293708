.admin-language {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
}
.admin-language .error {
  color: #d61414;
}

[role$=menu] .language-item > div > div div:last-child {
  display: none;
}
