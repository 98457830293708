.assignment-item-details {
  position: relative;
}
.assignment-item-details .assignment-attachment-icon {
  position: absolute !important;
  right: 15px;
  top: 15px;
}
.assignment-item-details .details-item {
  padding-top: 24px;
}
.assignment-item-details .details-item .title {
  padding-bottom: 5px;
  display: block;
  font-size: 14px;
  color: rgba(33, 33, 33, 0.33);
}
.assignment-item-details .details-item .value {
  display: block;
  font-size: 16px;
  color: #212121;
  line-height: 18px;
  overflow: hidden;
}
.assignment-item-details .error-details .item-prop {
  background-color: #f6f6f6;
  box-shadow: inset 0 -1px 0 0 #DD2C00;
  margin-bottom: 10px;
  padding: 12px 12px;
}
.assignment-item-details .error-details .item-prop .title {
  color: #ce2400;
}
.assignment-item-details .error-details .item-prop .value {
  font-size: 13px;
}
