.token-info .token-info-content {
  width: 40% !important;
}
.token-info .token-info-content .token-info-title {
  display: inline-flex;
  align-items: center;
}
.token-info .token-info-content .token-info-title > span {
  font-size: 20px;
}
.token-info .token-info-content .token-info-title > svg {
  margin-right: 10px;
  color: #736F77 !important;
}
.token-info .token-info-content .token-info-body > span {
  font-size: 13px;
}
